import { acc_form } from './acc_form';
import { acc_list } from "./acc_list";
import { acc_start } from "./acc_start";
import { admin } from "./admin";
import { faq } from "./faq";
import { fue_exp } from "./fue_exp";
import { fue_exp_list } from "./fue_exp_list";
import { fue_exp_start } from "./fue_exp_start";
import { home } from "./home";
import { home_loading } from "./home_loading";
import { login } from "./login";
import { logout } from "./logout";
import { mai } from "./mai";
import { mai_list } from "./mai_list";
import { mai_list_other } from "./mai_list_other";
import { mai_setup } from "./mai_setup";
import { mai_start } from "./mai_start";
import { mai_success } from "./mai_success";
import { msg } from "./msg";
import { msg_list } from "./msg_list";
import { photo } from "./photo";
import { pin_first } from "./pin_first";
import { pin_forgot } from "./pin_forgot";
import { pin_reset } from "./pin_reset";
import { rep } from "./rep";
import { rep_ans } from "./rep_ans";
import { rep_list } from "./rep_list";
import { rep_list_other } from "./rep_list_other";
import { rep_redirect } from "./rep_redirect";
import { rep_setup } from "./rep_setup";
import { rep_start } from "./rep_start";
import { rep_success } from "./rep_success";
import { timeout } from "./timeout";
import { veh_def_add } from "./veh_def_add";
import { veh_def_form } from "./veh_def_form";
import { veh_def_list } from "./veh_def_list";
import { veh_form } from "./veh_form";
import { tco_drv_let_list } from "./tco_drv_let_list";
import { tco_drv_let } from "./tco_drv_let";
import { LiveVehicleDefects } from "./live_defects";
import { VehicleDefectsList } from "./live_defects_list";
import { DefectView } from "./defect_view";
import {DefectEdit} from "./defect_edit";

export const VIEWS = {
    acc_form,
    acc_list,
    acc_start,
    admin,
    faq,
    fue_exp,
    fue_exp_list,
    fue_exp_start,
    home,
    home_loading,
    login,
    logout,
    mai,
    mai_list,
    mai_list_other,
    mai_setup,
    mai_start,
    mai_success,
    msg,
    msg_list,
    photo,
    pin_first,
    pin_forgot,
    pin_reset,
    rep,
    rep_ans,
    rep_list,
    rep_list_other,
    rep_redirect,
    rep_setup,
    rep_start,
    rep_success,
    timeout,
    veh_def_add,
    veh_def_form,
    veh_def_list,
    veh_form,
    tco_drv_let_list,
    tco_drv_let,
    defect_view: new DefectView(),
    defect_edit: new DefectEdit(),
    live_defects: new LiveVehicleDefects(),
    live_defects_list: new VehicleDefectsList(),
};