export let rep_start = {

	DOM: {},

	init: async () => {
		rep_start.TYPES = ( app.URI[1] === 'trailer' ) ? ['serial'] : ['reg', 'serial'];

		// reset reference
		for(let i = 0; i < rep_start.TYPES.length; i++){
			rep_start['awesomplete_'+rep_start.TYPES[i]] = false;
		}

		// DOM
		rep_start.setup_dom();

		// EVENTS
		rep_start.setup_events();

		app.GEO.start(app.HASH);
	},

	setup_dom: function()
	{
		rep_start.DOM.form  = app.DOM.content_load.find('#form');
		rep_start.DOM.bottom_container = app.DOM.content_load.find('#bottom-container');

		// autocomplete
		rep_start.DOM.autocomplete_input = app.DOM.content_load.find('.autocomplete-input');
		rep_start.DOM.autocomplete = app.DOM.content_load.find('.autocomplete-list');

		// buttons
		rep_start.DOM.btn_next = app.DOM.content_load.find('#btn-next');
	},

	setup_events: function()
	{
		// SETUP FOOTER
		app.setup_footer([
			{btn:'left', txt: 'Back', href: 'home'}
		]);

		app.setup_autocomplete();

		// prevent form submission
		rep_start.DOM.form.on('submit', function(e){
			e.preventDefault();
		});

		// clear focus
		rep_start.DOM.autocomplete_input.on('blur', function(e){
			$(e.currentTarget).val('');
		});

		rep_start.setup_focus();
	},

	setup_focus: function()
	{
		rep_start.DOM.autocomplete_input.on('focus', function(){
			app.VIEW[app.HASH].DOM.bottom_container.hide();
		});

		rep_start.DOM.autocomplete_input.on('blur', function(){
			app.VIEW[app.HASH].DOM.bottom_container.show();
		});
	},

	selectAutocomplete: function(e)
	{
		if( app.HASH !== 'rep_start' ) {
			return;
		}

		var ts = false,
			vehicle_id = ( $.isNumeric(e) ) ? e : $(e.srcElement).val();

		// reset input
		if( $.isNumeric(e) === false ) {
			$(e.srcElement).val('');
		}

		// get vehicle record to check for suspension
		var veh = app.TPL.get_row('veh', vehicle_id);

		ts = app.REPORT.add(vehicle_id);

		// only redirect if vehicle status OK
		if( ts ) {

			// setup redirect
			var redirect = ( app.URI[1] === 'trailer' ) ? 'rep_setup/'+app.URI[2]+'/'+ts : 'rep_setup/'+ts;

			// console.log(redirect);
			
			// redirect
			app.redirect(redirect);
		}
	}
};