import * as Handlebars from 'handlebars';

export let fue_exp_list = {

	DOM: {},

	SCROLL: false,

	init: function()
	{
		if( !app.FUEL_EXPENSES.check_priv()) {
			app.redirect('home');
			return;
		}

		// DOM
		this.setup_dom();
		
		// SETUP FOOTER
		app.setup_footer([
			{btn:'left', txt: 'Back', href: 'home'},
			{btn:'right', txt: 'Add', href: 'fue_exp_start'}
		]);

		this.ajax();
	},

	setup_dom: function()
	{
		this.DOM.table = app.DOM.content.find('table').addClass('paging');
	},

	/**
	 * Get daily checks from api
	 */
	ajax: function(pageSelected)
	{
		let pageIsSet = ( pageSelected ) ? true : false;
		
		// set defaults
		pageSelected = pageSelected || 1;

        var ajax = $.ajax({
            url: app.get_api_url('fue_exp/get'),
            data: app.get_api_data({page: pageSelected}),
            method: 'POST'
        });

		// change loading state
		_('table').addClass('loading');

        ajax.done(function(r){

            // success
            if( r && r.status === 'success' && app.HASH === 'fue_exp_list' && r.data.fue_exp ) {

				app.AJAX.paging('fue_exp_list', r.data.paging, pageSelected, pageIsSet);
				
                app.VIEW.fue_exp_list.ajax_success(r.data.fue_exp);
            } else {
            	app.AJAX.paging('fue_exp_list', false, 1);
            }
        });

        ajax.fail(function(){
        	app.AJAX.paging('fue_exp_list', false, 1);
        });
	},
	
	ajax_success: function(inspections)
	{
		let html = '';
		
		$.each(inspections, (k,v) => {

			const vehicle = ( v.vehicle_id ) ? Handlebars.helpers.regSerial(v, true, 'fue_exp/'+v.id) : `<a href="#">One off expense</a>`;
			const fuel = ( v.fuel ) ? v.fuel : '-';
			const adblue = ( v.adblue ) ? v.adblue : '-';
			const cost = ( v.cost ) ? `£${v.cost}` : '-';

			html += `<tr data="${k}">
				<td>${vehicle}</td>
				<td>${fuel}</td>
				<td>${adblue}</td>
				<td>${cost}</td>
				<td>${Handlebars.helpers.date(v.date_added, 'datetime')}</td>
			</tr>`;
		});

		_('table').find('tr.paging').last().after(html);

		_('table').find('[href]').removeAttr('href').on('click', function(){
			const index = $(this).closest('tr').attr('data');
			const r = inspections[index];
			app.CACHE.FUE_EXP.push(r);
			app.cache_save('fue_exp', false, false, function(){
				app.redirect('fue_exp/'+r.id);
			});
		});
	}
};