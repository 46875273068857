import moment from 'moment';

export let DATE = {

    config: {
        date: 'YYYY-MM-DD',
        datetime: 'YYYY-MM-DD HH:mm:ss'
    },

    /**
    * Get todays date and time
    *
    * @return string
    */
    format: function(type, dateObj = new Date())
    {
        // 2017-03-22 16:00:00
        if( type === 'datetime' ) {
            return moment().format(this.config.datetime);
        }
        
        var d;

        // decide which timestamp to take from
        if( dateObj === undefined ) {
            d = new Date();
        } else {
            d = dateObj;
        }

        var day = (d.getDate() < 10) ? '0' + d.getDate() : d.getDate(),
            month = (d.getMonth() + 1 < 10) ? '0' + (d.getMonth() + 1) : d.getMonth() + 1,
            year = d.getFullYear().toString(),
            dmy = day + '-' + month + '-' + year;

        // db date: 2015-01-25
        if( type === 'db' ) {
            return dmy.split('-').reverse().join('-');
        }

        // dd-mm-yyyy
        return dmy;
    },

    /**
     * Get the date ordinal
     */
    get_ordinal: function(n)
    {
        // convert from 01 to 1
        n = parseInt(n, 10);

        var ords = [,'st','nd','rd'],
            m = n%100;

        return n + ((m > 10 && m < 14)? 'th' : ords[m%10] || 'th');
    },

    /**
     * Get the hour meridiem
     * @param {string} time [14:00]
     */
    get_meridiem: function(time)
    {
       return parseInt(time.substr(0,2), 10) >= 12 ? 'pm' : 'am';
    },

    convert: function(date, seperator)
    {
        if( date.length !== 10 ) {
            return '';
        }

        if( seperator === undefined ) {
            seperator = '-';
        }

        // clean date
        date = date.replace( '/', '-', date).replace( '.', '-', date);

        // split date
        return date.split('-').reverse().join('-');
    },

    /**
    * Get unix timestamp
    */
    timestamp: function(date_str, return_string)
    {
        if( date_str ) {
            return moment(date_str).unix();
        }

        if( return_string ) {
            return moment().unix().toString();
        }

        return moment().unix();
    },

    diffInMinutes: function(a,b)
    {
        if( !a || !b || a.length !== 19 && b.length !== 19 ){
            return -999;
        }

        var $a = moment(a),
            difference =  $a.diff(moment(b), 'minutes');

        return difference;
    }
};