export let msg_list = {

	DOM: {},


	/**
	 * @url msg_list
	 * @url msg_list/unacknowledge [pre clicks Show unacknowledged button]
	 */
	init: function()
	{
		// DOM
		this.setup_dom();

		// EVENTS
		this.setup_events();
	},

	setup_dom: function()
	{
		_('btns', app.DOM.content_load.find('a.button'));
		_('tr', app.DOM.content_load.find('table tr'));
	},

	setup_events: function()
	{
		// redirect
		_('tr').on('click', function(){
			app.redirect($(this).attr('data-href'));
		});

		_('btns').on('click', this.clickBtn);

		// attempt sync
		if( app.SYNC.check_time('date_synced_get_msg', true) ){
			app.SYNC.start('get_msg', true);
		}

		if( app.URI[1] === 'unacknowledge' ) {
			_('btns').eq(0).trigger('click');
		}
	},

	clickBtn: function()
	{
		var type = $(this).attr('id').replace('btn-','');

		// do nothing
		if( $(this).hasClass('selected') ) {
			return;
		}

		// change buttons state
		_('btns').removeClass('selected');

		// change clicked button state
		$(this).addClass('selected');

		// show all rows by default
		_('tr').show();

		// hide specifc rows
		if( type === 'unread' ) {
			_('tr').not('.row-unread').hide();
		} else if( type === 'unaccept' ) {
			_('tr').not('.row-unaccept').hide();
		}
	},

	sync_win: function(data)
	{
		// if data refresh page
		if( data.msg_usr && data.msg_usr.length > 0 && data.msg_usr.length !== _('tr').length ) {
			app.redirect();
		}
	}
};