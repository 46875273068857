export let MAINTENANCE = {

	_get_veh_typ: function(vehicle_id)
	{
		var fields = false,
			veh = app.TPL.get_row('veh', vehicle_id);
        
        // check & get vehicle exists
        if( veh ) {
	        return app.TPL.get_row('veh_typ', veh.vehicle_type_id);
	    }

		return fields;
	},

	add: function(vehicle_id, callback)
	{
		// clear search
		var data = {'vehicle_id': vehicle_id, 'user_id': app.CACHE.USR.id};

		// check for any coordinates
		var geo = app.GEO.has_expired();
		if( geo ) {
			data.lat = geo.lat;
			data.lng = geo.lng;
            data.date_geo = geo.date_added;
		}

		// add new maintenance
		return app.FORM.save_offline('mai', 'add', data, true);
	},

    start: function(vehicle_type_id, maintenance_checklist_id, maintenance_id)
    {
        var data = {
            'date_start': app.DATE.format('datetime'),
            'maintenance_checklist_id': maintenance_checklist_id
        };

        // date started
        app.FORM.save_offline('mai', 'edit', data, true, maintenance_id);
        
        // add relevant questions
        // were questions added succesfully?
        var questions = this.add_questions(maintenance_checklist_id, maintenance_id);

        // true|false
        return questions;
    },

    /**
     * Delete maintenance
     * Delete maintenance questions
     * Delete related maintenance
     * Delete related maintenance questions
     */
    delete: function(maintenance_id, mai)
    {
        // delete maintenance
        app.FORM.save_offline('mai', 'delete', {}, true, maintenance_id);

        // delete answers
        var answers = false;

        // LOOP ANSWERS to check
        $.each(app.CACHE.MAI_ANS, function(k,v){
            if( v.maintenance_id === maintenance_id ) {
                answers = true;
                return false;
            }
        });

        // only update if answers need removing
        if( answers ) {
            app.CACHE.MAI_ANS = app.CACHE.MAI_ANS.filter(function(r){
                if( r.maintenance_id !== maintenance_id ) {
                    return r;
                }
            });

            app.cache_save('mai_ans');
        }
    },

    is_safety_fail: function(maintenance_id)
    {
        var isFail = false,
            mai_ans = app.TPL.get_mai_ans(false, maintenance_id);

        // no answers
        if( mai_ans.length === 0 ) {
            return true;
        }

        // LOOP answers
        $.each(mai_ans, function(key,row){
            // check question is a safety fail
            if( row.health && row.health === app.CACHE.MAI_ANS_HEALTH.safety.id && row.is_resolved !== '1' ) {
                isFail = true;
                return false;
            }
        });

        return isFail;
    },

    /**
     * Add blank answers for new checklist
     * 
     */
    add_questions: function(maintenance_checklist_id, maintenance_id)
    {
        // dont proceed without checklist
        if( !maintenance_checklist_id ) {
            return false;
        }

        // increment
        var i = 0,
            date_added = app.DATE.format('datetime'),
            mai_ans = 0;

        // get relevant questions
        app.CACHE.MAI_QUE.sort(function(a,b){
            return parseInt(a.order_rank, 10) - parseInt(b.order_rank, 10);
        }).filter(function(r){

            if( r.maintenance_checklist_id === maintenance_checklist_id && r.is_deleted !== '1' ) {

                // update count
                mai_ans++;

                var row = {
                    'date_added': date_added,
                    'maintenance_id': maintenance_id,
                    'maintenance_question_id': r.id,
                    'user_id': app.CACHE.USR.id,
                    'ts': maintenance_id + '_' + i++,
                    'sync': false,
                    'type': r.type // used on api side to detect answer cleaning
                };

                // automatically complete heading
                if( r.type === app.CACHE.MAI_QUE_TYP.heading ) {
                    row.is_completed = '1';
                }

                if( app.WEBAPP ) {
                    row.is_webapp = '1';
                }

                // add answers
                app.CACHE.MAI_ANS.push(row);
            }
        });
        
        // save to cache
        app.cache_save('mai_ans');

        // no questions added
        if( mai_ans === 0 ) {

            app.show_alert('There are no questions assigned to this checklist');

            return false;
        }

        // all criteria OK
        return true;
    },

    /**
     * Debug purposes ONLY
     */
    complete: function(maintenance_id, completed, health, maintenance_id_new)
    {
        // prefil data if not set in params
        if( maintenance_id === undefined ){
            maintenance_id = app.URI[1];
        }
        if( completed === undefined ) {
            completed = '1';
        }
        if( health === undefined ) {
            health = '0';
        }
        
        var tbl = 'mai';

        // update answers
        $.each(app.CACHE.MAI_ANS, function(k,v){
            if( v.maintenance_id === maintenance_id ) {

                var que = app.TPL.get_row('mai_que', v.maintenance_question_id),
                    data = {'is_completed' : completed};

                if( que.is_health === '1' ) {
                    data.health = health;
                }

                if( maintenance_id_new ) {
                    v.maintenance_id = maintenance_id_new;
                }

                app.CACHE.MAI_ANS[k] = $.extend({}, v, data);
            }
        });

        app.cache_save('mai_ans');

        // UPDATE MAINTENANCE ID
        if( maintenance_id_new ) {
            var index = app.cache_get_index('mai', maintenance_id);

            if( index !== false ) {
                                             
                delete app.CACHE.MAI[index].id;
                app.CACHE.MAI[index].ts = maintenance_id_new;
                app.CACHE.MAI[index].sync = false;
                app.cache_save('mai');
            }
        }

        // update maintenance
        var mai = app.TPL.get_row(tbl, maintenance_id);

        if( mai ) {

            // save maintenance
            app.FORM.save_offline(tbl, 'edit', mai, true, maintenance_id, false, function(){
                app.redirect();
            });
        } else {
           app.redirect('mai_list');
        }

    },

    getBrakeQuestionChoices: function(type)
    {
        if( type === 'test' ) {
            return [
                {'id': '1', 'name': 'Temp'},
                {'id': '2', 'name': 'Roller Brake'},
                //{'id': '3', 'name': 'Road test'},
                {'id': '4', 'name': 'Decelerometer'},
                {'id': '0', 'name': 'None'}
            ];
        } else if( type === 'lock' || type === 'laden' ) {
            return [
                {'id': '1', 'name': 'Yes'},
                {'id': '0', 'name': 'No'},
            ];
        }

        return [];
    },
};