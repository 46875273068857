export let pin_first = {

	DOM: {},

	init: function()
	{
		// start the form
		app.FORM.start({
			pin: true,
			rules: {
				pin: { required: true, notEqualTo: app.CACHE.USR.pin },
			    pin_confirm: {
			      equalTo: '#pin'
			    }}
		});
	},

	form_online: function(json, post)
	{
		if( json.data && json.data.usr ) {

			// update user with new pin
			delete app.CACHE.USR.pin;
			delete app.CACHE.USR.pin_reset_force;
			delete app.CACHE.USR.logins_app;
			
			app.CACHE.USR.pin = json.data.usr.pin;
			app.CACHE.USR.pin_token = json.data.usr.pin_token;
			app.cache_save('usr');
			
			// redirect user
			app.redirect('home_loading');
		}
	}
};