export let TIMEOUT = {

    /**
     * Check if timeout applies to this users company
     */
    check_timeout_enabled: function()
    {
        if(
            app.CACHE.USR.settings &&
            app.CACHE.USR.settings.logged_in &&
            app.HASH !== 'logout' &&
            app.HASH.indexOf('pin_') < 0 &&
            app.CACHE.USR.opr_priv_app_timeout &&
            app.CACHE.USR.opr_priv_app_timeout !== '0'
        ) {
            return true;
        }

        return false;
    },

    check: function()
    {
        // check if company is valid for timeout updates
        if( !this.check_timeout_enabled() ) {
            return;
        }

        // console.log('current', app.DATE.format('datetime'));
        // console.log('last', app.CACHE.USR.settings.date_timeout);

        if(
            app.DATE.format('datetime') >= app.CACHE.USR.settings.date_timeout &&
            app.SYNC.count_unsynced('rep') === 0 &&
            app.SYNC.count_unsynced('mai') === 0
        ) {
            app.CACHE.USR.settings.timeout = true;
        } else {
            this.update();
        }
    },

    /**
     * Update app.CACHE.USR.settings.date_timeout
     */
    update: function()
    {
        var mins = parseInt(app.CACHE.USR.opr_priv_app_timeout, 10);

        app.CACHE.USR.settings.date_timeout = moment().add(mins, 'minutes').format(app.DATE.config.datetime);
        app.cache_save('usr');
    },
    
    reset: function()
    {
        delete app.CACHE.USR.settings.date_timeout;
        delete app.CACHE.USR.settings.timeout;

        // redirect after save
        app.cache_save('usr');
        app.redirect();
    }
};