import {Capacitor} from "@capacitor/core";
import {CapacitorFlash} from "@capgo/capacitor-flash";
import {Device} from "@capacitor/device";

/**
 * Ability to toggle flashlight
 */
export let FLASHLIGHT = {

    // css class that change detects if flashlight is ON or OFF
    onState: 'on',

    /**
     * Initial events that checks flashlight state on app launch
     */
    init: async () => {
        const ipad = FLASHLIGHT.isIpad();
        if(!app.PHONEGAP || !Capacitor.isPluginAvailable('CapacitorFlash') || ipad) {
            app.DOM.header_btn_flashlight.hide();
            return;
        }

        CapacitorFlash.isAvailable().then((value) => {
            if(!value.value) {
                return;
            }

            // click flashlight
            app.DOM.header_btn_flashlight.on('click', async (e) => {
                const isOn = await CapacitorFlash.isSwitchedOn();

                if(isOn.value) {
                    await CapacitorFlash.switchOff();

                } else {
                    await CapacitorFlash.switchOn({
                        intensity: 1
                    });
                }

                app.DOM.header_btn_flashlight.toggleClass(app.FLASHLIGHT.onState);
            });
        });
    },

    /**
     * Checks to see if flashlight state been changed
     * outside of app when the app is then resumed
     */
    resume: async () => {
        const ipad = await FLASHLIGHT.isIpad();
        if(!app.PHONEGAP || !Capacitor.isPluginAvailable('CapacitorFlash') || ipad) {
            app.DOM.header_btn_flashlight.hide();
            return;
        }
        
        CapacitorFlash.isAvailable().then((hasTorch) => {
            if(hasTorch.value !== true) {
                return;
            }

            // do nothing flashlight not enabled
            // or
            // flashlight already off
            if(
                !app.DOM.header_btn_flashlight.is(':visible') ||
                !app.DOM.header_btn_flashlight.hasClass(app.FLASHLIGHT.onState)
            ){
                return;
            }

            // change flashlight state
            CapacitorFlash.isSwitchedOn().then((isOn) => {
                if(!isOn.value) {
                    app.DOM.header_btn_flashlight.removeClass(app.FLASHLIGHT.onState);
                }
            }).catch(() => console.warn('Something when wrong when checking flashlight state'));
        }).catch((err) => {
            console.error('Flashlight panic: ', err);
        });
    },

    off: async () => {
        const ipad = await FLASHLIGHT.isIpad();
        if(!app.PHONEGAP || !Capacitor.isPluginAvailable('CapacitorFlash') || ipad) {
            app.DOM.header_btn_flashlight.hide();
            return;
        }
        
        CapacitorFlash.isAvailable().then((hasTorch) => {
            if(hasTorch.value !== true) {
                return;
            }

            // flashlight not available
            if( !app.DOM.header_btn_flashlight.is(':visible') ) {
                return;
            }

            CapacitorFlash.switchOff().catch(() => console.warn('Something went wrong when disabling flashlight.'));
        }).catch((err) => {
            console.error('Flashlight panic: ', err);
        });
    },
    
    async isIpad() {
        const uaCheck = /iPad/i.test(navigator.userAgent) || /iPhone OS 3_1_2/i.test(navigator.userAgent) || /iPhone OS 3_2_2/i.test(navigator.userAgent);
        const info = await Device.getInfo();
        const modelCheck = info.model.includes('iPad');
        return uaCheck || modelCheck;
    }
};