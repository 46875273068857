import {AppLauncher} from "@capacitor/app-launcher";

export let faq = {

	AC: false,

	DOM: {},

	init: function()
	{
		$('#webapp-btn').on('click', async () => {
			await AppLauncher.openUrl({
				url: 'https://webapp.assetgo.co.uk/'
			});
		});

		// DOM
		//this.setup_dom();

		// EVENTS
		//this.setup_events();
	},

	setup_dom: function()
	{
		this.DOM.h3 = app.DOM.content_load.find('h3');

		// autocomplete
		this.DOM.form  = app.DOM.content_load.find('#filter');
		this.DOM.autocomplete_input = app.DOM.content_load.find('.autocomplete-input');
		this.DOM.autocomplete = app.DOM.content_load.find('.autocomplete-list');		 
	},

	setup_events: function()
	{
		this.setup_autocomplete();

		// prevent form submission
		this.DOM.form.on('submit', function(e){
			e.preventDefault();
		});
	},

	/**
	 * Enable autocomplete to attach portfolios to contact
	 */
	setup_autocomplete: function()
	{
		// get 3 different types of results for dropdowns
		var data = [];

		$.each(this.DOM.h3, function(k,v){
			data.push({
				'label': $(v).text(),
				'value': 'faq-'+(k+1)
			});
		});

		this.AC = new Awesomplete(this.DOM.autocomplete_input[0], {
			list: data,
			minChars: 1,
			maxItems: 8
		});

		// remove any previous listeners
		window.removeEventListener("awesomplete-selectcomplete", this.selectAutocomplete, false);

		// SELECT
		window.addEventListener("awesomplete-selectcomplete", this.selectAutocomplete, false);
	},

	selectAutocomplete: function(e)
	{

	}
};