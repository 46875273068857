
export class LiveVehicleDefects {
	DOM = {};
	
	async init() {
		if(app.CACHE.USR.opr_priv_app_vehicle_defects === '0' || app.CACHE.USR.priv_app_vehicle_defects === '0') {
			app.redirect('home');
			return;
		}
		
		app.CACHE.VEHICLE_DEFECT = {};
		app.cache_save('vehicle_defect');
		
		this.TYPES = ( app.URI[1] === 'trailer' ) ? ['serial'] : ['reg', 'serial'];
		
		// reset reference
		for(let i = 0; i < this.TYPES.length; i++) {
			this['awesomplete_' + this.TYPES[i]] = false;
		}
	
		// DOM
		this.setup_dom();
		
		// EVENTS
		this.setup_events();
	}
	
	setup_dom() {
		this.DOM.form  = app.DOM.content_load.find('#form');
		this.DOM.bottom_container = app.DOM.content_load.find('#bottom-container');
		
		// autocomplete
		this.DOM.autocomplete_input = app.DOM.content_load.find('.autocomplete-input');
		this.DOM.autocomplete = app.DOM.content_load.find('.autocomplete-list');
		
		// buttons
		this.DOM.btn_next = app.DOM.content_load.find('#btn-next');
	}
	
	setup_events() {
		// SETUP FOOTER
		app.setup_footer([
			{ btn:'left', txt: 'Back', href: 'home' }
		]);
		
		app.setup_autocomplete();
		
		// prevent form submission
		this.DOM.form.on('submit', function(e){
			e.preventDefault();
		});
		
		// clear focus
		this.DOM.autocomplete_input.on('blur', function(e){
			$(e.currentTarget).val('');
		});
		
		this.setup_focus();
	}
	
	setup_focus() {
		this.DOM.autocomplete_input.on('focus', function(){
			app.VIEW[app.HASH].DOM.bottom_container.hide();
		});
		
		this.DOM.autocomplete_input.on('blur', function(){
			app.VIEW[app.HASH].DOM.bottom_container.show();
		});
	}
	
	selectAutocomplete(e) {
		if(app.HASH !== 'live_defects') {
			return;
		}
		
		var ts = false,
			vehicle_id = ( $.isNumeric(e) ) ? e : $(e.srcElement).val();
		
		// reset input
		if( $.isNumeric(e) === false ) {
			$(e.srcElement).val('');
		}
		
		// setup redirect
		const redirect = ( app.URI[1] === 'trailer' ) ? `live_defects_list/${app.URI[2]}/${vehicle_id}` : `live_defects_list/${vehicle_id}`;
		// redirect
		app.redirect(redirect);
	}
}