export let REPORT = {



	add: function(vehicle_id)
	{
		// clear search
		var data = { 
			'vehicle_id': vehicle_id,
			'user_id': app.CACHE.USR.id,
			'is_trailer': ( app.URI[1] === 'trailer' ) ? '1' : '0',
			'is_webapp': ( app.WEBAPP ) ? '1' : '0'
		};

		// trailer
		if( app.URI[1] === 'trailer' ) {
			data.report_id_related = app.URI[2];
		}

		// veh_typ.*records*
		var veh_typ = app.TPL.get_vehicle_type_from_vehicle_id(data.vehicle_id);

		if( veh_typ ) {
            let reCacheQuestionTypes = false;
            Object.keys(app.CACHE.DEFAULT_QUESTIONS).forEach((que_typ) => {
                if(!que_typ.report_question_type_id) {
                    reCacheQuestionTypes = true;
                }
            });
            if((!app.CACHE.DEFAULT_QUESTIONS && app.CACHE.QUE_TYP) || reCacheQuestionTypes) {
                app.CACHE.DEFAULT_QUESTIONS = app.getDefaultQuestions(app.CACHE.QUE_TYP);
                app.cache_save('default_questions');
            }
            
            // loop through fake questions and add to report if needed
            $.each(app.CACHE.DEFAULT_QUESTIONS, function(k,v){

                var key = 'is_'+k;

    			if( veh_typ[key] === '1' ) {

    				data[k] = '';

                    // check position of fake question
                    if( veh_typ[key+'_position'] === '1' ) {
                        data[k+'_position'] = '1';
                    }
    			}
            });
		}

		// check for any coordinates
		var geo = app.GEO.has_expired();

		if( geo ) {
			data.lat = geo.lat;
			data.lng = geo.lng;
			data.date_geo = geo.date_added;
		}

		// add new report
		return app.FORM.save_offline('rep', 'add', data, true);
	},

    start: function(vehicle_type_id, report_id, trailer)
    {
        let reCacheQuestionTypes = false;
        Object.keys(app.CACHE.DEFAULT_QUESTIONS).forEach((que_typ) => {
            if(!que_typ.report_question_type_id) {
                reCacheQuestionTypes = true;
            }
        });
        if((!app.CACHE.DEFAULT_QUESTIONS && app.CACHE.QUE_TYP) || reCacheQuestionTypes) {
            app.CACHE.DEFAULT_QUESTIONS = app.getDefaultQuestions(app.CACHE.QUE_TYP);
            app.cache_save('default_questions');
        }
        
        var data = {'date_start': app.DATE.format('datetime')};

        // date started
        app.FORM.save_offline('rep', 'edit', data, true, report_id);
        
        // update parent report_id_related
        if( trailer ) {
            app.FORM.save_offline('rep', 'edit', {'report_id_related': report_id}, true, trailer);
        }

        // add relevant questions
        // were questions added succesfully?
        var questions = this.add_questions(vehicle_type_id, report_id);

        // true|false
        return questions;
    },

    /**
     * Delete report
     * Delete report questions
     * Delete related report
     * Delete related report questions
     */
    delete: function(report_id, rep)
    {
        // delete report
        app.FORM.save_offline('rep', 'delete', {}, true, report_id);

        // delete answers
        var answers = false;

        // LOOP ANSWERS to check
        $.each(app.CACHE.REP_ANS, function(k,v){
            if( v.report_id === report_id ) {
                answers = true;
                return false;
            }
        });

        // only update if answers need removing
        if( answers ) {
            app.CACHE.REP_ANS = app.CACHE.REP_ANS.filter(function(r){
                if( r.report_id !== report_id ) {
                    return r;
                }
            });

            app.cache_save('rep_ans');
        }

        // delete related report
        if( rep && rep.hasOwnProperty('report_id_related') ) {

            // delete related report (Trailer)
            if( rep.is_trailer === '0' ) {

                this.delete(rep.report_id_related);

            } else if( rep.is_trailer === '1' ) {

                // get index of parent
                var index = app.cache_get_index('rep', rep.report_id_related);

                // update parent so no relation to other reports exists
                if( index !== false ) {
                    delete app.CACHE.REP[index].report_id_related;
                    app.cache_save('rep');
                }
            }
        }
    },

    is_safety_fail: function(report_id)
    {
        var isFail = false,
            rep_ans = app.TPL.get_rep_ans(false, report_id);

        // no answers
        if( rep_ans.length === 0 ) {
            return true;
        }

        // LOOP answers
        $.each(rep_ans, function(key,row){

            // check question is a safety fail
            if( row.is_fail === '1' && row.hasOwnProperty('report_question_id') && app.cache_get_prop('rep_que', row.report_question_id, 'is_safety') === '1' ) {
                isFail = true;
                return false;
            }
        });

        return isFail;
    },

    /**
     * Get which checklist to get questions from?
     */
    _get_add_questions_checklist_id: function(vehicle_type_id)
    {
        var id = false,
            assigned = false;

        // see if there is an assigned vehicle_type
        // which is operator specific
        app.CACHE.REP_CHE.filter(function(r){
            if( r.vehicle_type_id === vehicle_type_id && r.hasOwnProperty('is_assigned') && r.is_assigned === '1' ){
                assigned = r;
            }
        });

        if( assigned ) {
            return assigned.id;
        }

        // get the default checklist
        var default_rep_che = false;

        app.CACHE.REP_CHE.filter(function(r){
            if( r.vehicle_type_id === vehicle_type_id ){
                default_rep_che = r;
            }
        });

        // see if checklist found
        if( default_rep_che ) {
            return default_rep_che.id;
        }

        // error
        app.show_alert('There has been problem finding a checklist for this asset type, please select another asset.', 'There was a problem');

        return false;
    },

    /**
     * Add blank answers for new checklist
     * 
     */
    add_questions: function(vehicle_type_id, report_id)
    {
        var report_checklist_id = this._get_add_questions_checklist_id(vehicle_type_id);

        // dont proceed without checklist
        if( report_checklist_id === false ) {
            return false;
        }

        // increment
        var i = 0,
            date_added = app.DATE.format('datetime'),
            rep_ans = 0;

        // get relevant questions
        app.CACHE.REP_QUE.sort(function(a,b){
            return parseInt(a.order_rank, 10) - parseInt(b.order_rank, 10);
        }).filter(function(r){

            if( r.report_checklist_id === report_checklist_id && r.is_deleted !== '1' ) {

                // update count
                rep_ans++;

                var row = {
                    'date_added': date_added,
                    'report_id': report_id,
                    'report_question_id': r.id,
                    'user_id': app.CACHE.USR.id,
                    'ts': report_id + '_' + i++,
                    'sync': false,
                    'is_valid': false,
                };

                if( app.WEBAPP ) {
                    row.is_webapp = '1';
                }

                // add answers
                app.CACHE.REP_ANS.push(row);
            }
        });
        app.cache_save('rep_ans');

        // no questions added
        if( rep_ans === 0 ) {

            var rep_che = app.TPL.get_row('rep_che', report_checklist_id),
                name = ( rep_che && rep_che.name ) ? ' ('+rep_che.name+')' : '';

            app.show_alert('There are no questions assigned to this checklist'+name+', please contact your Depot Manager');

            return false;
        }

        // all criteria OK
        return true;
    },

    /**
     * Debug purposes ONLY
     */
    complete: function(report_id, completed, fail, report_id_new)
    {
        // prefil data if not set in params
        if( report_id === undefined ){
            report_id = app.URI[1];
        }
        if( completed === undefined ) {
            completed = '1';
        }
        if( fail === undefined ) {
            fail = '0';
        }
        
        var data = {'is_completed' : completed, 'is_fail': fail},
            tbl = 'rep';

        // update answers
        $.each(app.CACHE.REP_ANS, function(k,v){
            if( v.report_id === report_id ) {

                if( report_id_new ) {
                    v.report_id = report_id_new;
                }

                app.CACHE.REP_ANS[k] = $.extend({}, v, data);
            }
        });

        app.cache_save('rep_ans');

        // UPDATE REPORT ID
        if( report_id_new ) {
            var index = app.cache_get_index('rep', report_id);

            if( index !== false ) {
                                             
                delete app.CACHE.REP[index].id;
                app.CACHE.REP[index].ts = report_id_new;
                app.CACHE.REP[index].sync = false;
                app.cache_save('rep');

                // UPDATE REPORT ID RELATED
                if( app.CACHE.REP[index].report_id_related ) {

                    // related index
                    var indexR = app.cache_get_index('rep', app.CACHE.REP[index].report_id_related);

                    if( indexR !== false ) {
                        app.CACHE.REP[indexR].report_id_related = report_id_new;
                        app.cache_save('rep');
                    }                     
                }
            }
        }

        // update report
        var rep = app.TPL.get_row(tbl, report_id);

        if( rep ) {

            if( rep.hasOwnProperty('shared') && rep.shared === '' ) {
                rep.shared = '12345678';
            }
            
            if( rep.hasOwnProperty('fuel') && rep.fuel === '' ) {
                rep.fuel = 75;
            }

            if( rep.hasOwnProperty('mileage') && rep.mileage === '' ) {
                rep.mileage = '15000';
            }

            if( rep.hasOwnProperty('hours') && rep.hours === '' ) {
                rep.hours = '999';
            }

            if( rep.hasOwnProperty('adblue') && rep.adblue === '' ) {
                rep.adblue = 50;
            }

            if( rep.hasOwnProperty('height') && rep.height === '' ) {
                rep.height = '2.10';
            }

            // save report
            if(
                rep.hasOwnProperty('shared') ||
                rep.hasOwnProperty('fuel') ||
                rep.hasOwnProperty('mileage') ||
                rep.hasOwnProperty('hours') ||
                rep.hasOwnProperty('adblue') ||
                rep.hasOwnProperty('height')
            ) {
                app.FORM.save_offline(tbl, 'edit', rep, true, report_id, false, function(){
                    app.redirect();
                });
            }
        }

        app.redirect('rep_list');
    }
};