export let acc_form = {
	DOM: {},
	WITNESSES: new Map(),

	init: function () {
		var form = app.DATA.RESULT.hasOwnProperty("id") || app.DATA.RESULT.date_end ? false : true;

		// check form exists
		if (form || (app.DATA.RESULT.is_completed === '1' && app.DATA.RESULT.hasOwnProperty('id') === false)) {
			app.FORM.start({ hideConfirmation: true, changes: false, data: app.DATA.RESULT });
		}

		// DOM
		this.setup_dom(form);

		// EVENTS
		this.evt_btn_complete();
		
		const data = app.DATA.RESULT;
		if(form && data.witnesses && data.witnesses.length > 0) {
			data.witnesses.forEach((wit) => {
				acc_form.WITNESSES.set(wit.witnessId, wit);
				$(`.witness-delete`).off('click').on('click', acc_form.delete_witness_btn);
				$('.witness-edit').off('click').on('click', acc_form.display_witness_edit_btn);
				$('.save-witness-edit').off('click').on('click', acc_form.witness_edit_btn);
				$('.witness-cancel-edit').on('click', acc_form.close_witness_edit_btn);
			});
			const el = $('#witness-form');
			if(el) {
				el.attr('data-witness-id', acc_form.WITNESSES.size + 1);
			}
		}
		
		this.setup_events(form);
		
		if (form || (app.DATA.RESULT.is_completed === '1' && app.DATA.RESULT.hasOwnProperty('id') === false)) {
			acc_form.validate_sections();
			acc_form.checkFiles(app.DATA.RESULT);
		}
	},

	setup_dom: function (form) {
		this.DOM.form = app.DOM.content_load.find("#form");
		this.DOM.police_involved = this.DOM.form.find("#police_involved_no, #police_involved_yes");
		this.DOM.police_involved_yes = this.DOM.form.find("#police_involved_yes");
		this.DOM.police_involved_inputs = this.DOM.form.find(":input");
		this.DOM.police_involved_container = this.DOM.form.find("#police-involved-container");
		this.DOM.section_headers = app.DOM.content.find('.section-header');
		this.DOM.form_btn_complete = app.DOM.content_load.find('#btn-complete');
		this.DOM.form_btn_submit = app.DOM.content_load.find('#submit');
		this.DOM.force_photo_message = $("#opr_force_photo");
		$('.hide-all').hide();
		
		if(!form) {
			this.DOM.force_photo_message.hide();
			this.toggleSectionValidity('hide_all');
		}

		if (form) {
			this.DOM.form_inputs = this.DOM.form.find(":input");
			this.DOM.notes = this.DOM.form_inputs.filter("#notes");
			this.DOM.notes_third = this.DOM.form_inputs.filter("#notes_third");
			this.DOM.user_third = this.DOM.form_inputs.filter("#user_third");
			this.DOM.vehicle_third = this.DOM.form_inputs.filter("#vehicle_third");
			this.DOM.contact_third = this.DOM.form_inputs.filter("#contact_third");
			this.DOM.policy_number_third = this.DOM.form_inputs.filter(
				"#policy_number_third",
			);
			this.DOM.witness_form = app.DOM.content.find('#witness-form');
			this.DOM.witness_name_input = this.DOM.witness_form.find(`.witness_name`);
			this.DOM.witness_contact_number_input = this.DOM.witness_form.find(`.witness_contact`);
			this.DOM.witness_email_input = this.DOM.witness_form.find(`.witness_email`);
			this.DOM.witness_address_input = this.DOM.witness_form.find(`.witness_address`);
			this.DOM.witness_list_seperator = app.DOM.content.find('#witness-list-separator');
			this.DOM.add_witness = app.DOM.content.find('#btn-add-witness');
			this.DOM.witness_list_container = app.DOM.content.find('#witness-list-container');
		}
	},

	setup_events: function (form) {
		if (app.URI[2] === "success") {
			// UPLOAD
			this.setup_photos_upload();
		} else if (form) {
			// FORM CHANGES
			this.setup_form_change();
			
			this.DOM.add_witness.on('click', () => {
				let witnessId = 1;
				const name = acc_form.DOM.witness_name_input.val();
				const number = acc_form.DOM.witness_contact_number_input.val();
				const email = acc_form.DOM.witness_email_input.val();
				const address = acc_form.DOM.witness_address_input.val();
				
				if(!name || name.length === 0) {
					$('.witness_name').addClass('error');
					return;
				}
				
				if(!number || number.length === 0) {
					$('.witness_contact').addClass('error');
					return;
				}
				
				$('.witness_name, .witness_contact').removeClass('error');
				
				while(acc_form.WITNESSES.has(witnessId)) {
					witnessId++;
				}
				const data = { name: name, contact: number, email: email ?? '', address: address ?? '', witnessId: witnessId };
				const el = document.createElement('div');
				el.classList.add('witness');
				el.setAttribute('data-witness-id', `${witnessId}`);
				el.innerHTML = Handlebars.helpers.accWitness(data);
				$(el).find('.witness-delete').on('click', acc_form.delete_witness_btn);
				$(el).find('.witness-edit').on('click', acc_form.display_witness_edit_btn);
				$(el).find('.save-witness-edit').on('click', acc_form.witness_edit_btn);
				$(el).find('.witness-cancel-edit').on('click', acc_form.close_witness_edit_btn);
				acc_form.DOM.witness_list_container.get(0).insertBefore(el, acc_form.DOM.witness_list_seperator.get(0));
				acc_form.WITNESSES.set(witnessId, data);
				acc_form.DOM.witness_name_input.val('');
				acc_form.DOM.witness_contact_number_input.val('');
				acc_form.DOM.witness_email_input.val('');
				acc_form.DOM.witness_address_input.val('');
				witnessId++;
				acc_form.DOM.witness_form.attr('data-witness-id', witnessId);
				acc_form.save_witness_object();
			});

			// CAMERA
			app.CAMERA.setup(app.DATA.RESULT, 'date_end', 'acc');
		} else {
			// force items to be uneditable
			app.DOM.content_load
				.find(":input").not('#submit')
				.addClass("disabled")
				.prop("disabled", true);
			app.DOM.content_load.find("label").removeAttr("for");
			app.DOM.content_load.find("#btn-photo").hide();

			// check pics exist
			this.check_pics_exist();
		}
		
		if (this.DOM.police_involved_yes.is(":checked")) {
			this.DOM.police_involved_container.show();
		}
		
		$.each(this.DOM.section_headers, (v, el) => {
			$(el).on('click', (e) => {
				const el = $(e.currentTarget);
				const section = $(`#${el.attr('data-section')}`);
				if(section.attr('data-show') === 'false') {
					section.attr('data-show', 'true');
				} else {
					section.attr('data-show', 'false');
				}
			});
		});
		
		$('.show-all').on('click', () => {
			$('.section').attr('data-show', 'true');
			$('.hide-all').show();
			$('.show-all').hide();
		});
		
		$('.hide-all').on('click', () => {
			$('.section').attr('data-show', 'false');
			$('.show-all').show();
			$('.hide-all').hide();
		});
		
		// SETUP FOOTER
		var opts = [
			{
				btn: "left",
				txt: "Back",
				href: "acc_list",
			}
		];
		
		if(app.DATA.RESULT && !app.DATA.RESULT.id) {
			opts.push({
				'btn': 'right',
				'txt': ( app.DATA.PROGRESS.submit ) ? 'Submit' : 'Complete',
				'click': ( app.DATA.PROGRESS.submit ) ? 'form_btn_submit' : 'form_btn_complete'
			});
		}
		
		app.setup_footer(opts);
	},
	
	evt_btn_complete: function()
	{
		acc_form.DOM.form_btn_complete.on('click', () => {
			let valid = acc_form.validate_sections();
			if(!valid) {
				return;
			}
			
			const acc = app.TPL.get_row('acc', app.URI[1]);
			if(!acc) {
				return;
			}
			
			valid = acc_form.checkFiles(acc);
			if(!valid) {
				return;
			}
			
			// SAVE: date_end + refresh
			app.show_confirm("You will not be able to change any information for this accident after marking as completed", "Please remember", function(response){
				
				if( response === true ) {
					var data = {
						'date_end': app.DATE.format('datetime'),
						'is_completed': '1',
					};
					
					app.FORM.save_offline('acc', 'edit', data, true, app.URI[1], false, function(){
						app.redirect();
					});
				}
			});
		});
	},
	
	save_witness_object: () => {
		let witnessData = { witnesses: [] };
		
		acc_form.WITNESSES.forEach((witness) => {
			witnessData.witnesses.push(witness);
		});
		app.FORM.save_offline("acc", "edit", witnessData, true, app.URI[1], false, false, true);
		app.CACHE.ACC = app.CACHE.ACC.map((acc) => {
			if(acc.id !== app.URI[1] && acc.ts !== app.URI[1]) {
				return acc;
			}
			
			acc.witnesses = witnessData.witnesses;
			return acc;
		});
		app.FORM.DATA.witnesses = witnessData.witnesses;
		if(acc_form.WITNESSES.size > 0) {
			acc_form.toggleSectionValidity('complete', 'witness');
		} else {
			acc_form.toggleSectionValidity('not-required', 'witness');
		}
		app.cache_save('acc');
	},
	
	delete_witness_btn: (e) => {
		e.preventDefault();
		e.stopPropagation();
		app.show_confirm('Are you sure you would like to remove this witness?', 'Delete Witness', (result) => {
			if(result === true) {
				const witnessId = $(e.currentTarget).attr('data-witness-id');
				if(isNaN(parseInt(witnessId))) {
					return;
				}
				
				acc_form.WITNESSES.delete(parseInt(witnessId));
				acc_form.DOM.witness_list_container.find(`.witness[data-witness-id="${witnessId}"]`).remove();
				acc_form.save_witness_object();
			}
		}, {}, ['Delete Witness', 'Cancel']);
	},
	
	close_witness_edit_btn: (e) => {
		e.preventDefault();
		e.stopPropagation();
		const el = $(e.currentTarget);
		const parentEl = $(`.witness[data-witness-id="${el.attr('data-witness-id')}"]`);
		const form = parentEl.find('.edit-witness-form');
		form.attr('data-shown', "false");
		parentEl.find('.details').show();
		parentEl.find('.editing').hide();
		parentEl.find('.enable-edit').show();
		form.hide();
		acc_form.DOM.witness_form.show();
	},
	
	display_witness_edit_btn: (e) => {
		e.preventDefault();
		e.stopPropagation();
		const el = $(e.currentTarget);
		const parentEl = $(`.witness[data-witness-id="${el.attr('data-witness-id')}"]`);
		const form = parentEl.find('.edit-witness-form');
		if(form.attr('data-shown') === 'true') {
			form.attr('data-shown', "false");
			parentEl.find('.details').show();
			parentEl.find('.editing').hide();
			parentEl.find('.enable-edit').show();
			form.hide();
			acc_form.DOM.witness_form.show();
			return;
		}
		
		form.attr('data-shown', "true");
		parentEl.find('.details').hide();
		parentEl.find('.editing').show();
		parentEl.find('.enable-edit').hide();
		form.show();
		acc_form.DOM.witness_form.hide();
	},
	
	witness_edit_btn: (e) => {
		const witnessId = $(e.currentTarget).attr('data-witness-id');
		if(!acc_form.WITNESSES.has(parseInt(witnessId))) {
			return;
		}
		let witnessData = acc_form.WITNESSES.get(parseInt(witnessId));
		const parentEl = $(`.witness[data-witness-id="${witnessId}"]`);
		const name = parentEl.find(`.witness_name`).val();
		const number = parentEl.find(`.witness_contact`).val();
		const email = parentEl.find(`.witness_email`).val();
		const address = parentEl.find(`.witness_address`).val();
		
		if(!name || name.length === 0) {
			$('.witness_name').addClass('error');
			return;
		}
		
		if(!number || number.length === 0) {
			$('.witness_contact').addClass('error');
			return;
		}
		
		$('.witness_name, .witness_contact').removeClass('error');
		witnessData = { ...witnessData, ...{ name: name, contact: number, email: email ?? '', address: address ?? '' } };
		acc_form.WITNESSES.set(parseInt(witnessId), witnessData);
		acc_form.save_witness_object();
		parentEl.find('.edit-witness-form').hide();
		parentEl.find('.edit-witness-form').attr('data-shown', "false");
		parentEl.find('.witness-edit i').removeClass('fa-xmark').addClass('fa-edit');
		parentEl.html(Handlebars.helpers.accWitness(witnessData));
		parentEl.find('.witness-delete').off('click').on('click', acc_form.delete_witness_btn);
		parentEl.find('.witness-edit').off('click').on('click', acc_form.display_witness_edit_btn);
		parentEl.find('.save-witness-edit').off('click').on('click', acc_form.witness_edit_btn);
		acc_form.DOM.witness_form.show();
	},

	check_pics_exist: function () {
		// redirect to full picture
		app.VIEW[app.HASH].DOM.form
			.find("img.img-photo")
			.not(".img-blank")
			.on("click", function () {
				app.redirect("photo/acc/" + app.URI[1] + "/" + $(this).attr("data"));
			});
	},

	setup_photos_upload: function () {
		// no phonegap OR offline
		if (
			app.PHONEGAP === false ||
			app.DATA.RESULT.hasOwnProperty("id") === false
		) {
			return;
		}

		// get photos to upload
		var data_photos = app.SYNC.send_data_photos_tbl("acc", "id", app.URI[1]);

		console.log("photos to upload", data_photos);

		// upload if photos exist
		if (data_photos.length > 0) {
			app.SYNC.send_photos(data_photos);
		}
	},

	setup_form_change: function () {
		app.VIEW[app.HASH].DOM.form_inputs.on("change", (e) => {
			// data container
			const el = $(e.currentTarget);
			const id = el.attr("id");

			let data = {
				saved: true,
			};

			// save data
			if(
				id === 'veh_pos' ||
				id === 'third_veh_reg' ||
				id === 'damage_to_veh' ||
				id === 'third_veh_load_dmg' ||
				id === 'damage_to_load' ||
				id === 'surrounding_area' ||
				id === 'damage_to_third_veh' ||
				id === 'other'
			) {
				const acc = app.TPL.get_row('acc', app.URI[1], 'ts');
				if(acc) {
					data.photo_types = acc.photo_types;
					data.photo_types[id] = el.is(':checked') ? "1" : "0";
					app.FORM.DATA.photo_types = data.photo_types;
				}
			} else if(
				id === 'damage_not_applicable' ||
				id === 'damage_none' ||
				id === 'damage_minor' ||
				id === 'damage_major'
			) {
				data[el.attr('name')] = el.val();
			} else if (el.attr('type') === 'radio') {
				data[el.attr('name')] = el.val() === 'yes' || el.val() === '1' ? "1" : "0";
			} else {
				data[id] = $(`#${id}`).val();
			}

			app.FORM.save_offline("acc", "edit", data, true, app.URI[1], false, () => {
				acc_form.validate_sections();
				const acc = app.TPL.get_row('acc', app.URI[1], 'ts');
				acc_form.checkFiles(acc);
			}, true);
		});

		app.DOM.content_load
			.find("#btn-delete")
			.on("click", app.VIEW.acc_form.delete);
	},

	form_online: function (json, post) {
		if (json.data && json.data.acc) {
			json.data.acc.saved = true;

			// add on locked date
			// APP only
			json.data.acc.date_end = app.DATE.format("datetime");

			// save
			app.FORM.save("acc", "edit", json.data.acc, post, false, app.URI[1], false, false, false, false, function(){
				
				// LOOP through each answer and add id
				$.each(json.data.acc, function(k,v){
					
					if(v && v.ts && v.id ) {
						var i = app.cache_get_index('mai_ans', v.ts);
						
						if( i >= 0 && app.CACHE.ACC[i] ) {
							delete app.CACHE.ACC[i].sync;
							delete app.CACHE.ACC[i].ts;
							app.CACHE.ACC[i].id = v.id.toString();
						}
					}
				});
				
				// redirect on save callback
				app.cache_save('acc', false, false, function(){
					app.redirect(app.HASH + "/" + json.data.acc.id + "/success");
				});
			});
		}
	},

	form_offline: function (json, post, tbl) {
		var data = { saved: true };

		// redirect as no data needs to be saved
		app.FORM.save_offline(
			"acc",
			"edit",
			data,
			false,
			app.URI[1],
			false,
			function () {
				app.redirect(app.HASH + "/" + app.URI[1] + "/success");
			},
		);
	},
	
	onCameraSave: (data, $img, settings) => {
		if (settings.identifier !== app.URI[1]) {
			return;
		}

		const acc = app.TPL.get_row("acc", settings.identifier, "ts");
		if (!acc) {
			return;
		}

		acc_form.checkFiles(acc);
	},

	onCameraDelete: (img, settings) => {
		if (settings.identifier !== app.URI[1]) {
			return;
		}

		const acc = app.TPL.get_row("acc", settings.identifier, "ts");
		if (!acc) {
			return;
		}

		acc_form.checkFiles(acc);
	},

	checkFiles: (data) => {
		const submitBtn = $("#button-right");
		
		if (
			app.CACHE.USR.hasOwnProperty("opr_priv_accident_force_photo") === false ||
			app.CACHE.USR.opr_priv_accident_force_photo !== "1"
		) {
			acc_form.DOM.force_photo_message.hide().css("display", "none");
			submitBtn.addClass("button-primary").removeClass("button-grey");
			return true;
		}
		
		if(data.date_end) {
			acc_form.DOM.force_photo_message.hide().css("display", "none");
			submitBtn.addClass("button-primary").removeClass("button-grey");
			return true;
		}

		let hasImage = false;
		Object.keys(data).forEach((key) => {
			let shouldAdd;
			if (app.WEBAPP) {
				shouldAdd = acc_form.checkWebappFiles(key, data);
			} else {
				shouldAdd = acc_form.checkAppFiles(key, data);
			}

			if (!shouldAdd) {
				return;
			}

			hasImage = true;
		});
		
		if (!hasImage) {
			acc_form.DOM.force_photo_message.hide().css("display", "block");
			submitBtn.addClass("button-grey").removeClass("button-primary");
			return false;
		}

		acc_form.DOM.force_photo_message.hide().css("display", "none");
		submitBtn.addClass("button-primary").removeClass("button-grey");
		return true;
	},

	checkWebappFiles: (key, ans) => {
		if (
			!key.startsWith("file") ||
			(key.startsWith("file") && key.endsWith("_needs_uploading"))
		) {
			return false;
		}

		if (!ans[key] || ans[key].length === 0) {
			return false;
		}

		return true;
	},

	checkAppFiles: (key, acc) => {
		if (!key.startsWith("file") || !key.endsWith("_local")) {
			return false;
		}

		if (!acc[key] || acc[key].length === 0) {
			return false;
		}

		return true;
	},

	delete: function () {
		const $el = $(this);

		// SAVE: date_end + refresh
		app.show_confirm(
			"Do you want to delete this entry?",
			"Confirm deletion",
			function (response) {
				if (response !== true) {
					return;
				}

				$el
					.hide()
					.after(
						'<img class="loading" src="img/loading-black.svg" width="20">',
					);

				// delete
				app.FORM.save_offline(
					"acc",
					"delete",
					{},
					true,
					app.URI[1],
					false,
					() => {
						app.redirect("home");
					},
					true,
				);
			},
		);
	},
	
	validate_sections: () => {
		const acc = app.TPL.get_row('acc', app.URI[1], 'ts');
		if(!acc) {
			return false;
		}
		
		let sections = {
			location: { exists: false, fields: [] },
			third_party_contact: acc_form.validate_text_only_section('third_party_contact'),
			third_party_vehicle: acc_form.validate_vehicle_section(acc),
			police: acc_form.validate_text_only_section('police_details'),
			accompanying: acc_form.validate_accompanying_section(acc),
			passenger: acc_form.validate_passenger_section(acc),
			load: acc_form.validate_load_section(acc)
		}
		
		if(acc_form.WITNESSES.size > 0) {
			acc_form.toggleSectionValidity('complete', 'witness');
		} else {
			acc_form.toggleSectionValidity('not-required','witness');
		}
		
		if(acc.road_type || acc.direction_of_travel || acc.road) {
			sections.location.exists = true;
		}
		
		// Check if we have any sections i.e. sections partially filled out
		const sectionKeys = Object.keys(sections);
		sectionKeys.forEach((key) => {
			if(sections[key].exists) {
				let sectionId = '';
				switch (key) {
					case 'location':
						sectionId = 'incident_details';
						break;
					case 'third_party_contact':
						sectionId = 'third_party_contact';
						break;
					case 'third_party_vehicle':
						sectionId = 'third_party_veh';
						break;
					case 'police':
						sectionId = 'police_details';
						break;
					case 'accompanying':
						sectionId = 'accompanying_details';
						break;
					case 'passenger':
						sectionId = 'passenger_details';
						break;
					case 'load':
						sectionId = 'load_details';
						break;
				}
				
				acc_form.toggleSectionValidity('complete', sectionId);
				return;
			}
			
			let sectionId = '';
			switch (key) {
				case 'location':
					sectionId = 'incident_details';
					break;
				case 'third_party_contact':
					sectionId = 'third_party_contact';
					break;
				case 'third_party_vehicle':
					sectionId = 'third_party_veh';
					break;
				case 'police':
					sectionId = 'police_details';
					break;
				case 'accompanying':
					sectionId = 'accompanying_details';
					break;
				case 'passenger':
					sectionId = 'passenger_details';
					break;
				case 'load':
					sectionId = 'load_details';
					break;
			}
			
			const sectionEl = $(`#${sectionId}`);
			sectionEl.find('.error').removeClass('error');
			sectionEl.find('.required').removeClass('required');
			acc_form.toggleSectionValidity('not-required', sectionId);
		});
		
		let data = {};
		if(!app.FORM.DATA) {
			app.FORM.DATA = {};
		}
		
		sectionKeys.forEach((key) => {
			if(sections[key].exists === true) {
				switch(key) {
					case 'third_party_contact':
					case 'third_party_vehicle':
						data.is_third = '1';
						app.FORM.DATA.is_third = '1';
						break;
					case 'police':
						data.section_police = '1';
						app.FORM.DATA.section_police = '1';
						break;
					case 'accompanying':
						data.section_accompanying = '1';
						app.FORM.DATA.section_accompanying = '1';
						break;
					case 'passenger':
						data.section_passenger = '1';
						app.FORM.DATA.section_passenger = '1';
						break;
					case 'load':
						data.section_load = '1';
						app.FORM.DATA.section_load = '1';
						break;
				}
			}
		});
		
		app.FORM.save_offline("acc", "edit", data, true, app.URI[1], false, false, true);
		if(!acc.notes) {
			app.DOM.footer_btn_right.addClass('button-grey').removeClass('button-primary').addClass('button-grey');
			return false;
		}
		
		app.DOM.footer_btn_right.addClass('button-primary').removeClass('button-grey');
		return true;
	},
	
	validate_text_only_section: (id) => {
		let exists = false;
		let fields = [];
		let beforeExists = [];
		
		$.each(acc_form.DOM.form.find(`#${id} input`), (i, el) => {
			el = $(el);
			const val = el.val();
			if(val || val.length > 0) {
				exists = true;
				return;
			}
			
			if(exists) {
				fields.push(el);
			} else {
				beforeExists.push(el);
			}
		});
		
		if(!exists) {
			return { exists, fields };
		}
		
		beforeExists.forEach((el) => {
			if(!el.val() || el.val().length === 0) {
				fields.push(el);
			}
		});
		
		return { exists, fields };
	},
	
	validate_vehicle_section: (acc) => {
		let exists = false;
		let fields = [];
		let beforeExists = [];
		
		$.each(acc_form.DOM.form.find('#third_party_veh input').not('[name="damage_third"]'), (i, el) => {
			el = $(el);
			const val = el.val();
			if(val || val.length > 0) {
				exists = true;
				return;
			}
			
			if(exists) {
				fields.push(el);
			} else {
				beforeExists.push(el);
			}
		});
		
		if(!acc.damage_third && exists) {
			fields.push($('#third_party_veh input[name="damage_third"]'));
		} else if(!exists && acc.damage_third) {
			exists = true;
		}
		
		if(!exists) {
			return { exists, fields };
		}
		
		beforeExists.forEach((el) => {
			if(!el.val() || el.val().length === 0) {
				fields.push(el);
			}
		});
		
		return { exists, fields };
	},
	
	validate_accompanying_section: (acc) => {
		let exists = false;
		let fields = [];
		
		const el = $('#number_of_people_in_cab');
		if(el.val() > 0) {
			exists = true;
		}
		
		if(!acc.accompanying_authorized && exists) {
			fields.push($('#accompanying_details input[name="accompanying_authorized"]'));
		} else if(!exists && acc.accompanying_authorized) {
			exists = true;
		}
		
		if(!acc.accompanying_accounted_for && exists) {
			fields.push($('#accompanying_details input[name="accompanying_accounted_for"]'));
		} else if(!exists && acc.accompanying_accounted_for) {
			exists = true;
		}
		
		if(!acc.accompanying_movement && exists) {
			fields.push($('#accompanying_details input[name="accompanying_movement"]'));
		} else if(!exists && acc.accompanying_movement) {
			exists = true;
		}
		
		if(el.val() <= 0 && exists) {
			fields.push(el);
		}
		
		return { exists, fields };
	},
	
	validate_passenger_section: (acc) => {
		let exists = false;
		let fields = [];
		
		const el = $('#number_of_passengers');
		if(el.val() > 0) {
			exists = true;
		}
		
		if(!acc.passenger_accounted_for && exists) {
			fields.push($('#passenger_details input[name="passenger_accounted_for"]'));
		} else if(!exists && acc.passenger_accounted_for) {
			exists = true;
		}
		
		if(!acc.passenger_movement && exists) {
			fields.push($('#passenger_details input[name="passenger_movement"]'));
		} else if(!exists && acc.passenger_movement) {
			exists = true;
		}
		
		if(el.val() <= 0 && exists) {
			fields.push(el);
		}
		
		return { exists, fields };
	},
	
	validate_load_section: (acc) => {
		let exists = false;
		let fields = [];
		
		const el = $('#load_carried');
		if(el.val() > 0) {
			exists = true;
		}
		
		if(!acc.load_accounted_for && exists) {
			fields.push($('#load_details input[name="load_accounted_for"]'));
		} else if(!exists && acc.load_accounted_for) {
			exists = true;
		}
		
		if(!acc.load_movement && exists) {
			fields.push($('#load_details input[name="load_movement"]'));
		} else if(!exists && acc.load_movement) {
			exists = true;
		}
		
		if(el.val() <= 0 && exists) {
			fields.push(el);
		}
		
		return { exists, fields };
	},
	
	toggleSectionValidity: (state, sectionId) => {
		if(sectionId) {
			const baseEl = $(`#${sectionId}`);
			switch (state) {
				case 'complete':
					baseEl.find('.completed').show();
					baseEl.find('.incomplete, .not-required').hide();
					return;
				case 'incomplete':
					baseEl.find('.incomplete').show();
					baseEl.find('.not-required, .completed').hide();
					return;
				case 'not-required':
					baseEl.find('.not-required').show();
					baseEl.find('.incomplete, .completed').hide();
					return;
				case 'hide_all':
					baseEl.find('.incomplete, .completed, .not-required').hide();
					return;
			}
		}
		
		const sections = $('.section');
		switch (state) {
			case 'complete':
				sections.find('.completed').show();
				sections.find('.incomplete, .not-required').hide();
				return;
			case 'incomplete':
				sections.find('.incomplete').show();
				sections.find('.not-required, .completed').hide();
				return;
			case 'not-required':
				sections.find('.not-required').show();
				sections.find('.incomplete, .completed').hide();
				return;
			case 'hide_all':
				sections.find('.incomplete, .completed, .not-required').hide();
				return;
		}
	}
};
