export let rep_redirect = {

	DOM: {},

	init: function()
	{
		// figure out where user needs to be redirected to
		var rep = app.TPL.get_row('rep', app.URI[1]),
			// set default redirect if no report found
			redirect = 'home';

		if( rep ) {

			// default redirect unless we can find unanswered question
			redirect = 'rep/'+app.URI[1];
			// check if first questions are first
			let redirect_fake = this._check_fake_redirect(rep);

			if( redirect_fake) {

				redirect = redirect_fake;

			} else {

				var rep_ans_id = this._get_rep_ans_id();

				if( rep_ans_id ) {
					redirect = 'rep_ans/'+rep.ts+'/'+rep_ans_id;
				} else {

					// check if fake questions are last
					redirect_fake = this._check_fake_redirect(rep,'1');

					if( redirect_fake ) {
						redirect = redirect_fake;
					}
				}
			}
		}
		 
		setTimeout(function(){
			app.redirect(redirect);
		}, 200);
	},

	/**
	 * Check fake questions are eligible to be redirected too
	 */
	_check_fake_redirect: function(rep, pos)
	{
		var redirect = false;

		$.each(app.CACHE.DEFAULT_QUESTIONS, function(k,v){

			if(
				!redirect &&
				rep.hasOwnProperty(k) && rep[k] === '' && (
					(rep.hasOwnProperty(k+'_position') === false && !pos) ||
                    (rep.hasOwnProperty(k+'_position') && pos === '1')
				)
			) {
				redirect = 'rep_ans/'+rep.ts+'/'+k;
			}
		});

		return redirect;
	},

	/**
	 * Get first unanswered answer
	 */
	_get_rep_ans_id: function()
	{
		var rep_ans_id = false;

		// get answers from report
		$.each(app.CACHE.REP_ANS, function(k,v){
			if( v.report_id === app.URI[1] && v.hasOwnProperty('is_completed') === false ) {
				rep_ans_id = v.ts;
				return false;
			}
		});

		return rep_ans_id;
	}
};