export let veh_def_add = {

	TYPES: ['reg', 'serial'],

	DOM: {},

	init: function()
	{
		// reset reference
		for(let i = 0; i < this.TYPES.length; i++){
			this['awesomplete_'+this.TYPES[i]] = false;
		}

		// DOM
		this.setup_dom();

		// EVENTS
		this.setup_events();
	},

	setup_dom: function()
	{
		if( app.URI[1] === 'rep' ) {
			this.DOM.table_tr_new = app.DOM.content.find('table#veh-def-new tbody tr.needsclick');
		} else {
			// autocomplete
			this.DOM.autocomplete_input = app.DOM.content_load.find('.autocomplete-input');
			this.DOM.autocomplete = app.DOM.content_load.find('.autocomplete-list');
		}
	},

	setup_events: function()
	{
		if( app.URI[1] === 'rep' ) {
			// click report
			this.evt_click_report();
		} else {
			// autocomplete
			app.setup_autocomplete();
		}
	},

	evt_click_report: function()
	{
		// Allow user to select recent vehicle
		this.DOM.table_tr_new.on('click', function(){

			var vehicle_id = $(this).attr('data-vehicle-id'),
				report_id = $(this).attr('data-report-id');

			app.VIEW.veh_def_add.add(vehicle_id, report_id);
		});
	},

	add: function(vehicle_id, report_id)
	{
		// get data for add
		var data = {
			'is_resolved': '0',
			'vehicle_id': vehicle_id,
			'user_id': app.CACHE.USR.id,
			'is_webapp': app.WEBAPP ? '1' : '0',
		};

		if( $.isNumeric(report_id) ){
			data.report_id = report_id;
		}

		// add veh_def
		var ts = app.FORM.save_offline('veh_def', 'add', data, true, false, false, function(){
			// redirect user to new defect
			app.redirect('veh_def_form/'+ts);
		});
	},

	selectAutocomplete: function(e)
	{
		if( app.HASH !== 'veh_def_add' ) {
			return;
		}

		let vehicle_id = ( $.isNumeric(e) ) ? e : $(e.srcElement).val();

		// reset input
		if( $.isNumeric(e) === false ) {
			$(e.srcElement).val('');
		}

		app.VIEW.veh_def_add.add(vehicle_id);
	}
};