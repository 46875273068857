
/**
 * @url #mai/:ts
 * @url #mai/:ts/:ts_answer [for opening specific question]
 * @url #mai/:id
 */
export let mai = {

	DOM: {},
	TYPES: [],
	TOUCH: 'click',
	
	init: function()
	{
		mai.TOUCH = ( app.PHONEGAP && navigator.userAgent.match(/(iPod|iPad)/) ) ? 'touchstart' : 'click';
		for(let i = 0; i < this.TYPES.length; i++){
			this['awesomplete_'+this.TYPES[i]] = false;
		}
		
		// DOM
		this.setup_dom();

		// EVENTS
		this.setup_events();
	},

	setup_dom: function()
	{
		this.DOM.form = app.DOM.content_load.find('#mai-ans');
		this.DOM.bottom_container = app.DOM.content_load.find('#bottom-container');
		this.DOM.btn_open = app.DOM.content_load.find('#btn-open');
		this.DOM.label = app.DOM.content_load.find('h3.imref');
		this.DOM.btn_complete = app.DOM.content_load.find('#btn-complete');
		this.DOM.btn_submit = app.DOM.content_load.find('#submit');
		this.DOM.btn_delete = app.DOM.content_load.find('#btn-delete');
		this.DOM.btn_unanswered = app.DOM.content_load.find('#btn-unanswered');
		this.DOM.btn_unanswered_count = this.DOM.btn_unanswered.find('#btn-unanswered-count');
		this.DOM.range = this.DOM.form.find('input.range');
		this.DOM.bottom_container = app.DOM.content_load.find('#bottom-container');
		this.DOM.autocomplete_input = app.DOM.content_load.find('.autocomplete-input');
		this.DOM.autocomplete = app.DOM.content_load.find('.autocomplete-list');

		// answers
		if( app.DATA.PROGRESS.status !== 'Ready to Submit' && app.DATA.PROGRESS.status !== 'Completed' ) {

			this.DOM.btn_answer = app.DOM.content_load.find('#mai-ans a.button').not('.button-tyre');
			this.DOM.input_answer = app.DOM.content_load.find('#mai-ans :input');
			this.DOM.axles = app.DOM.content_load.find('#mai-ans .axle-container');
			this.DOM.signatures = app.DOM.content_load.find('#mai-ans .signature-container canvas');

			// axles
			if( this.DOM.axles.length > 0 ) {
				this.DOM.axle_add = this.DOM.axles.find('.button-axle-add');
				this.DOM.axle_delete = this.DOM.axles.find('.button-axle-delete');
			}
		}
	},

	setup_events: function()
	{
		app.browser_open();

		app.setup_autocomplete();
		
		this.DOM.autocomplete_input.on('focus', (e) => {
			const acKey = $(e.currentTarget).attr('data-autocomplete');
			const ul = $(e.currentTarget).parent().find('ul');
			if(ul.find('li').length === 0) {
				const suggestions = [];
				let html = ``;
				for(let i = 0; i < this[acKey].options.list.length; i++) {
					html += `<li role="option" aria-selected="false" id="${ul.attr('id')}_item_${i}">${this[acKey].options.list[i].label}</li>`;
					suggestions.push(this[acKey].options.list[i]);
				}
				ul.html(html);
				this[acKey].suggestions = suggestions;
			}
			this[acKey].open();
		});
		
		this.DOM.autocomplete_input.on('awesomplete-close', (e) => {
			const el = $(e.currentTarget);
			if(!el.attr('data-mai-que')) {
				return;
			}

			if(!el || !el.val() || el.val().trim().length === 0) {
				el.val('');
				el.closest('.mai-ans').find(`.reset-selection`).trigger('click');
				return;
			}

			$(el.closest('.mai-ans')).find(`.hidden-radios a[data="${el.val().trim()}"]`).trigger('click');
		});
		
		$('.reset-selection').on('click', (e) => {
			const el = $(e.currentTarget);
			if(!el.attr('data-mai-que')) {
				return;
			}
			
			app.CACHE.MAI_ANS = app.CACHE.MAI_ANS.map((ans) => {
				if(ans.ts === el.attr('data-mai-que')) {
					delete ans.answer;
					delete ans.is_completed;
				}
				
				return ans;
			});
			app.cache_save('mai_ans');
			
			const parent = el.closest('.mai-que');
			parent.find(`input[data-mai-que="${el.attr('data-mai-que')}"]`).val('');
			parent.find('.hidden-radios').removeClass('selected');
			parent.find('h3').removeClass('is_completed');
			parent.find('.mai-ans').removeClass('has-radio-custom').addClass('needs-radio-custom');
			parent.removeClass('health-ok').removeClass('health-safety').removeClass('health-repair');
			parent.find('.health.fa-question-circle').show();
			
		});
		
		// OPEN
		if( this.DOM.btn_open.length > 0 ) {
			this.evt_btn_open();
		}
		
		// COMPLETED
		this.evt_btn_complete();
		
		// ANSWERS
		if( app.DATA.PROGRESS.status !== 'Ready to Submit' && app.DATA.PROGRESS.status !== 'Completed' ) {

			this.evt_answers_setup();
			// CHECK VEHICLE DEFECTS
			app.AJAX.veh_defects(app.DATA.MAI.vehicle_id);
		}

		// SUBMIT
		if( this.DOM.btn_submit.length > 0 ) {
			this.evt_submit_setup();
		}

		// DELETE
		if( this.DOM.btn_delete.length > 0 ) {
			this.evt_btn_delete();
		}

		// left button
		var opts = [
			{ btn: 'left', 'href':  ( app.DATA.MAI.user_id === app.CACHE.USR.id ) ? 'mai_list' : 'mai_list/other', txt: 'Back' }
		];

		// right button
		if( app.DATA.PROGRESS.status !== 'Completed' ) {
			opts.push({
				'btn': 'right',
				'txt': ( app.DATA.PROGRESS.submit ) ? 'Submit' : 'Complete',
				'click': ( app.DATA.PROGRESS.submit ) ? 'btn_submit' : 'btn_complete'
			});
		}

		app.setup_footer(opts);

		// hide submit when not ready
		if( app.DATA.PROGRESS.status !== 'Completed' && app.DATA.PROGRESS.status === 'Incomplete' ) {
			app.DOM.footer_btn_right.hide();
		}

		app.UPCOMING.view_cached();

		if( app.DATA.MAI_ANS_AJAX ){
			this.check_mai_ans();
		}
	},
	
	setup_focus: function()
	{
		this.DOM.autocomplete_input.on('focus', function(){
			app.VIEW[app.HASH].DOM.bottom_container.hide();
		});
		
		this.DOM.autocomplete_input.on('blur', function(){
			app.VIEW[app.HASH].DOM.bottom_container.show();
		});
	},
	
	selectAutocomplete: (e) => {
		if (app.HASH !== 'mai') {
			return;
		}
		
		const el = $(e.srcElement);
		if(!el.attr('data-mai-que')) {
			return;
		}
		
		$(el.closest('.mai-ans')).find(`.hidden-radios a[data=${e.text.value}]`).trigger('click');
	},
	
	/**
	 * Do we need to download report answers?
	 */
	check_mai_ans: function()
	{
		// do nothing, dont look up own answers
		if( !app.DATA.MAI_ANS_AJAX ) {
			return;
		}

		var ajax = $.ajax({
			url: app.get_api_url('mai_ans_other/'+app.URI[1]),
			data: app.get_api_data(),
			method: 'POST'
		});

		ajax.done(function(r){
			if( r && r.status === 'success' && r.data.mai_ans && r.data.mai_ans.length > 0 ) {
				app.VIEW.mai.check_mai_ans_win(r.data.mai_ans);
			}
		});
	},

	check_mai_ans_win: function(json)
	{
		$.each(json, function(k,v){
			app.CACHE.MAI_ANS.push(v);
		});

		app.cache_save('mai_ans');

		// reload page
		if( app.HASH === 'mai' ) {
			app.redirect();
		}
	},

	onCameraSave: function(data, $img)
	{
		console.log('onCameraSave');

		// mark answer as having photo
		var $parent = $img.closest('.mai-ans').addClass('has-photo');

		// trigger change on comments to check answer state
		$parent.find('textarea').first().trigger('change');

		// change answer state
		return this.get_answer_info($img);
	},

	/**
	 * Trigger required fields rechecking
	 */
	onCameraDelete: function($img)
	{
		console.log('onCamearaDelete');

		// mark answer as having photo
		// trigger change on comments to check answer state
		var $parent = $img.closest('.mai-ans');

		// check if any other photos exist
		if( $parent.find('.img-photo.img-blank').length === app.PHOTOS ) {
			$parent.removeClass('has-photo');
		}
		
		$parent.find('textarea').first().trigger('change');

		// change answer state
		return this.get_answer_info($img);
	},

	getUnansweredCount: function()
	{
		app.VIEW.mai.DOM.btn_unanswered_count.text(app.VIEW.mai.DOM.label.length - app.VIEW.mai.DOM.label.filter('.is_completed').length);
	},

	getCountAnswered: function()
	{
		var count = app.VIEW.mai.DOM.label.length,
			countAns = app.VIEW.mai.DOM.label.filter('.is_completed').length;

		// setup footer
		app.DOM.footer_centre.text(countAns+'/'+count);
	},

	evt_answers_setup: function()
	{
		this.getCountAnswered();
		this.getUnansweredCount();

		// button: click
		this.DOM.btn_answer.on('click', function(){
			// radio
			if( $(this).hasClass('button-radio') ) {
				app.VIEW[app.HASH].evt_radio_change($(this));
			} else if( $(this).hasClass('button-checkbox') ) {
				app.VIEW[app.HASH].evt_checkbox_change($(this));
			}
		});

		// NUMBER INPUTS
		var allowed = (
			app.CACHE.DEVICE.platform === 'ios' ||
			(!app.CACHE.DEVICE.platform === 'desktop' && app.CACHE.DEVICE.version.indexOf('Macintosh') >= 0)
		) ? ['ArrowLeft','ArrowRight','Backspace','.'] : [8,9,16,17,37,38,39,40,46,48,49,50,51,52,53,54,55,56,57,65,76,96,97,98,99,100,101,102,103,104,105,110,190];

		// input: number
		app._('input_answer').filter('[type="number"], .number').unbind().on('keydown', function(e){

			if(
				( app.CACHE.DEVICE.platform === 'ios' && (allowed.indexOf(e.originalEvent.key) >= 0 || $.isNumeric(e.originalEvent.key)) ) ||
				( app.CACHE.DEVICE.platform !== 'ios' && allowed.indexOf(e.keyCode) >= 0 )
			) {
			    return true;
			}

			return false;
		});

		// maxlength keyup
		this.DOM.input_answer.filter('[type="number"]').on('keyup', function(){

			var max = parseInt($(this).attr('max')),
				maxlength = parseInt($(this).attr('maxlength'));

			// maxlength
			if( !isNaN(maxlength) && this.value.length > maxlength ) {
				$(this).val(this.value.slice(0, maxlength));
			}

			// max
			if( !isNaN(max) && this.value && parseInt(this.value) > max ) {
				$(this).val(max);
			}
		});

		// input: change
		this.DOM.input_answer.change(this.evt_input_change);

		// range slider
		if( this.DOM.range.length ) {
			app.FORM.setup_range();
		}

		// axle setup
		if( this.DOM.axles.length ) {
			this.evt_setup_axles();
		}
		
		// signature
		if( this.DOM.signatures.length ) {
			this.evt_setup_signatures();
		}

		// photo
		app.CAMERA.setup(app.DATA.MAI, 'date_end', 'mai_ans');
	},

	/**
	 * Hide/show submit button dependant on form state
	 */
	check_submit_button: function()
	{
		// answer
		this.getCountAnswered();
		// unanswered
		this.getUnansweredCount();

		if( app.VIEW.mai.DOM.label.length === app.VIEW.mai.DOM.label.filter('.is_completed').length ) {
			app.DOM.footer_btn_right.show();
		} else {
			app.DOM.footer_btn_right.hide();
		}
	},

	answer_change: function()
	{
        // get report data
        var data  = {
        	'MAI': app.TPL.get_row('mai', app.URI[1])
        };

        // get answers for report
        data.MAI_ANS = app.TPL.get_mai_ans(data.MAI, app.URI[1], true);
         
        // status of report
        data.PROGRESS = app.TPL._get_mai_progress(data, app.URI[1]);
	},

	get_answer_info: function($el)
	{
		var info = {
			$el: $el,
			$parent: $el.closest('.mai-ans'),
			field: $el.attr('data-field'),
			// get checkbox later after $btns cache
			value: ( $el.is(':input') ) ? $el.val() : $el.attr('data'),
		};

		// dom: label
		info.$h3 = info.$parent.prev();
		// dom: health-container
		info.$health = info.$parent.find('.health-container');

		// dom: all buttons
		if( $el.filter('a').length > 0 ){

			info.$btns = info.$parent.find('a.button[data-field="'+$el.attr('data-field')+'"]');

			if( info.field === 'health' ) {

				info.$parent.addClass('has-'+info.field);

			} else if( info.field === 'extra.test' ) {

				var brakeTypeRequiringLadenUnladen = ['1','2','3'];
				
				// check if laden/unladen selected
				if(
					brakeTypeRequiringLadenUnladen.indexOf(info.value) < 0 ||
					info.$parent.find('.extra-laden-container .selected').length > 0
				) {
					info.$parent.addClass('has-brake');
				}


			} else if( $el.hasClass('button-radio-custom') ) {
				
				info.$parent.addClass('has-radio-custom');
			}

			// get checkbox value
			if( $el.hasClass('button-checkbox') ) {
				info = this.get_answer_info_checkbox(info);

				if( info.value ) {
					info.$parent.addClass('has-checkbox');
				} else {
					info.$parent.removeClass('has-checkbox');
				}
			}

		} else if( $el.hasClass('brake') ) {

			// specific brake info
			info = this.get_answer_info_brake(info);

			if( info.value ) {
				info.$parent.addClass('has-brake');
			} else {
				info.$parent.removeClass('has-brake');
			}

		} else if( $el.hasClass('signature') ) {

			if( info.value ) {
				info.$parent.addClass('has-signature');
			} else {
				info.$parent.removeClass('has-signature');
			}


		} else if( $el.hasClass('date') ) {

			if( info.value ) {
				info.$parent.addClass('has-date');
			} else {
				info.$parent.removeClass('has-date');
			}

		} else if( $el.hasClass('tyre') ) {

			// specific tyre info
			info = this.get_answer_info_tyre(info);

			if( info.value ) {
				info.$parent.addClass('has-tyre');
			} else {
				info.$parent.removeClass('has-tyre');
			}

		} else if( $el.attr('type') === 'number' || $el.attr('type') === 'range' ) {

			if( info.value ) {
				info.$parent.addClass('has-'+$el.attr('type'));
			} else {
				info.$parent.removeClass('has-'+$el.attr('type'));
			}

		} else if( info.field === 'notes' ) {

			if( info.value ) {
				info.$parent.addClass('has-'+info.field);
			} else {
				info.$parent.removeClass('has-'+info.field);
			}
		}

		// answer_id
		info.answer_id = info.$parent.attr('data');

		// default data
		info.data = {};
		info.data[info.field] = info.value;

		return info;
	},

	// recheck dom because we might not be here because of radio change (could be input, slider etc)
	check_health_is_na: function(info)
	{
		var $btn = info.$parent.find('a.selected[data-field="health"]'),
			na = false;

		// no health match
		if( $btn.length === 0 ) {
			return na;
		}

		// check if value is true
		if( $btn.attr('data') === app.CACHE.MAI_ANS_HEALTH.na.id ) {
			na = true;
		}

		console.log(na);
	
		return na;
	},
	
	get_answer_info_checkbox: function(info)
	{
		var value = [];

		info.$btns.each(function(){
			if( $(this).hasClass('selected') ) {
				value.push($(this).attr('data'));
			}
		});

		info.value = value.join(',');

		return info;
	},

	clean_data: function(data)
	{
		// loop through k
		$.each(data, function(k,v){
			
			// loop through kk
			$.each(v, function(kk,vv){

				// remove unwanted data
				if( !vv ) {
					delete data[k][kk];
				}

			});
		});

		return data;
	},

	get_answer_info_brake: function(info)
	{
		info.$trs = info.$el.closest('table').find('tr.show');
		info.$tr = info.$el.closest('tr');

		var answer = [],
			save = false;

		// loop through each visible axle to build answer
		$.each(info.$trs.filter('.show'), function(k,v){

			var $inputs = $(this).find(':input');

			$inputs.each(function(){
				if( 
					($(this).attr('type') !== 'checkbox' && $.isNumeric($(this).val()) ) ||
					$(this).is(':checked')
				) {
					save = true;
					return false;
				}
			});

			// add axle to array
			var obj = $inputs.serializeObject();

			// make sure empty locked values get saved
			if( !obj['lo[l]'] ) {
				obj['lo[l]'] = '';
			}
			// make sure empty locked values get saved
			if( !obj['ro[l]'] ) {
				obj['ro[l]'] = '';
			}

			var data = {
				lo: { f: obj['lo[f]'], t: obj['lo[t]'], l: obj['lo[l]'], d: obj['lo[d]'], p: obj['lo[p]']},
				ro: { f: obj['ro[f]'], t: obj['ro[t]'], l: obj['ro[l]'], d: obj['ro[d]'], p: obj['ro[p]']}
			};

			// add answer and clean
			answer.push(app.VIEW[app.HASH].clean_data(data));
		});

		if( save ) {
			info.value = JSON.stringify(answer);
		} else {
			info.value = '';
		}
		return info;
	},

	get_answer_info_tyre: function(info)
	{
		info.$trs = info.$el.closest('table').find('tr.show');
		info.$tr = info.$el.closest('tr');

		var answer = [],
			save = false,
			tyres = ['li','lo','ri','ro'];

		// loop through each visible axle to build answer
		$.each(info.$trs.filter('.show'), function(k,v){

			var $inputs = $(this).find(':input');

			// loop through inputs to see if we have any data to save
			$inputs.each(function(){
				if( $.isNumeric($(this).val()) ) {
					save = true;
					return false;
				}
			});

			// add axle to array
			var obj = $inputs.serializeObject(),
				data = {};

			// loop through tyres and add data
			$.each(tyres, function(kk,vv){

				// d attritube
				data[vv] = {
					d: obj[vv+'[d]']
				};

				// p attribute
				if( info.$el.attr('data-type') === 'tyre' ) {
					data[vv].p = obj[vv+'[p]'];
				}
			});

			// add answer and clean
			answer.push(app.VIEW[app.HASH].clean_data(data));
		});

		if( save ) {
			info.value = JSON.stringify(answer);
		} else {
			info.value = '';
		}

		return info;
	},

	check_completed: function(info)
	{
		// complete status
		// any health
		// or
		// answer w/o health
		if(
			app.VIEW.mai.check_health_is_na(info) || (
				(info.$parent.hasClass('needs-photo') === false || info.$parent.hasClass('has-photo')) &&
				(info.$parent.hasClass('needs-notes') === false || info.$parent.hasClass('has-notes')) &&
				(info.$parent.hasClass('needs-health') === false || info.$parent.hasClass('has-health')) &&
				(info.$parent.hasClass('needs-tyre') === false || info.$parent.hasClass('has-tyre')) &&
				(info.$parent.hasClass('needs-brake') === false || info.$parent.hasClass('has-brake')) &&
				(info.$parent.hasClass('needs-number') === false || info.$parent.hasClass('has-number')) &&
				(info.$parent.hasClass('needs-range') === false || info.$parent.hasClass('has-range')) &&
				(info.$parent.hasClass('needs-checkbox') === false || info.$parent.hasClass('has-checkbox')) &&
				(info.$parent.hasClass('needs-radio-custom') === false || info.$parent.hasClass('has-radio-custom')) &&
				(info.$parent.hasClass('needs-signature') === false || info.$parent.hasClass('has-signature')) &&
				(info.$parent.hasClass('needs-date') === false || info.$parent.hasClass('has-date'))
			)
		) {
			info.data.is_completed = '1';
			info.$h3.addClass('is_completed');
		} else {
			info.data.is_completed = '0';
			info.$h3.removeClass('is_completed');
		}

		// check submit state
		this.check_submit_button();

		return info;
	},

	evt_radio_change: function($btn)
	{
		// radio: already selected (do nothing)
		if( $btn.hasClass('selected') ) {
			return;
		}

		var info = this.get_answer_info($btn);

		// change buttons state
		info.$btns.removeClass('selected');

		// change button state
		$btn.addClass('selected');

		// fix header health
		if( info.field === 'health' ) {

			info.$h3.removeClass('health-ok health-safety health-repair health-na');

			if( info.value === app.CACHE.MAI_ANS_HEALTH.repair.id ) {
				info.$h3.addClass('health-repair');
			} else if( info.value === app.CACHE.MAI_ANS_HEALTH.safety.id ) {
				info.$h3.addClass('health-safety');
			} else {
				info.$h3.addClass('health-ok').removeClass('is_resolved');
				info.data.is_resolved = '0';
			}

			// n/a value must be seperate if statement
			if( info.value === app.CACHE.MAI_ANS_HEALTH.na.id ) {
				info.$h3.addClass('health-na');
			} 

			// monitoring
			info.data.is_monitoring = ( info.data.health === app.CACHE.MAI_ANS_HEALTH.monitoring.id ) ? '1' : '0';

		} else if( info.field === 'is_resolved' ) {
			if( info.value === '1' ) {
				info.$h3.addClass(info.field);
			} else {
				info.$h3.removeClass(info.field);
			}
		} else if( info.field.indexOf('.') >= 0 ) {

			// saving object parameter
			var r = app.TPL.get_row('mai_ans', info.answer_id),
				field = info.field.split('.'),
				data = ( r[field[0]] ) ? JSON.parse(r[field[0]]) : {};

			// save new data
			data[field[1]] = info.value;

			// merge new data
			info.data = {'is_completed': '1'};
			info.data[field[0]] = JSON.stringify(data);
		}

		if( info.field === 'extra.test' ) {
			this.evt_brake_test_change(info);
		}
		
		if(info.value && info.value.trim().length === 0) {
			delete info.data[info.field];
			delete info.data.is_completed;
		}

		// do a final check on completed?
		info = this.check_completed(info);

		// save data
		app.FORM.save_offline('mai_ans', 'edit', info.data, true, info.answer_id, false, app.VIEW[app.HASH].answer_change);
	},

	evt_brake_test_change: function(info)
	{
		// change table state
		info.$parent.find('.axle-container').removeClass('axle-container-brake-0 axle-container-brake-1 axle-container-brake-2 axle-container-brake-3 axle-container-brake-4').addClass('axle-container-brake-'+info.value);

		// change all image state
		info.$parent.find('img').addClass('disabled');

		// do nothing further
		if( info.value === '0' || info.value === '4' ) {
			return;
		}

		// find correct inputs
		var css = ( info.value === '1' ) ? 'temp' : 'kgf';

		info.$parent.find('tr.show input.brake-'+css).each(function(k,v){

			// left or right?
			var tyre = ( $(this).hasClass('tyre-lo') ) ? 'to' : 'ti',
				$img = $(this).closest('tr').find('td.'+tyre+' img');
			
			if( this.value ) {
				$img.removeClass('disabled');
			} else {
				$img.addClass('disabled');
			}
		});
	},

	evt_checkbox_change: function($btn)
	{
		// change button state
		if( $btn.hasClass('selected') ) {
			$btn.removeClass('selected');
		} else {
			$btn.addClass('selected');
		}

		var info = this.get_answer_info($btn);

		// do a final check on completed?
		info = this.check_completed(info);

		// save data
		app.FORM.save_offline('mai_ans', 'edit', info.data, true, info.answer_id, false, app.VIEW[app.HASH].answer_change);
	},

	evt_input_change: function()
	{
		var info = app.VIEW.mai.get_answer_info($(this));

		// serialised data
		if( info.field.indexOf('.') >= 0 ) {

			// saving object parameter
			var r = app.TPL.get_row('mai_ans', info.answer_id),
				field = info.field.split('.'),
				data = ( r[field[0]] ) ? JSON.parse(r[field[0]]) : {};

			// save new data
			data[field[1]] = info.value;

			// merge new data
			info.data = {};
			info.data[field[0]] = JSON.stringify(data);
		}

		// tyre events
		if( $(this).hasClass('brake') ) {
			app.VIEW.mai.evt_input_change_brake(info);
		} else if( $(this).hasClass('tyre') ) {
			app.VIEW.mai.evt_input_change_tyre(info);
		}

		// do a final check on completed?
		info = app.VIEW.mai.check_completed(info);

		// save data
		app.FORM.save_offline('mai_ans', 'edit', info.data, true, info.answer_id, false, app.VIEW[app.HASH].answer_change);
	},

	evt_input_change_brake: function(info)
	{
		var css = ( info.$el.hasClass('tyre-lo') ) ? 'to' : 'ti';

		var func = 'addClass';

		// find any values with data and remove disabled
		$.each(info.$el.closest('td').find('input'), function(){
			if(
				func === 'addClass' &&
				$(this).val() &&
				($(this).is(':checked') || $(this).attr('type') !== 'checkbox')
			) {
				func = 'removeClass';
			}
		});

		info.$el.closest('tr').find('td.'+css + ' img')[func]('disabled');
	},

	evt_input_change_tyre: function(info)
	{
		var tyre = '.'+info.$el.attr('class').replace('tyre ','').replace('brake','').replace(' ',''),
			$tyres = info.$tr.find(tyre);

		// loop each input for value
		var val = '';
		$tyres.filter(':input').each(function(){
			val += $(this).val();
		});

		if( val ) {
			$tyres.filter('img').removeClass('disabled');
		} else {
			$tyres.filter('img').addClass('disabled');
		}
	},

	evt_btn_open: function()
	{
		// toggle all answers
		this.DOM.btn_open.on('click', function(e){

			if( $(e.currentTarget).hasClass('open') ){
				app.VIEW[app.HASH].DOM.label.removeClass('open');
				$(e.currentTarget).removeClass('open');
				return;
			}

			app.VIEW[app.HASH].DOM.label.addClass('open');
			$(e.currentTarget).addClass('open');
		});

		// toggle invidiual answers
		this.DOM.label.on('click', function(e){

			if( $(e.currentTarget).hasClass('open') ){
				$(e.currentTarget).removeClass('open');
				return;
			}

			$(e.currentTarget).addClass('open');
		});

		// toggle all answers
		if( this.DOM.btn_unanswered.length > 0 ) {
			this.DOM.btn_unanswered.on('click', function(){

				// open unawered
				app.VIEW[app.HASH].DOM.label.removeClass('open').not('.is_completed').addClass('open');

				// reset view
				app.DOM.content.animate({scrollTop: 0}, 0);

				var $incomplete = app.VIEW[app.HASH].DOM.label.not('.is_completed').eq(0);

				//	tell user all anwers completed
				if( $incomplete.length === 0 ) {
					app.show_alert('All the required answers are completed, please submit the Checklist', 'Inspection Completed')
					return;
				}

				// scroll to Question
				app.DOM.content.animate({
					scrollTop: $incomplete.position().top - 50
				});
			});
		}

		// specific timestamp for app open question and scroll into view
		// primarily used for low memory android phones
		if( app.URI[2] ){
			var $label = app._('label').filter('#heading-'+app.URI[2]);

			// open
			$label.trigger('click');

			// scroll to
			app.DOM.content.animate({
				scrollTop: $label.position().top - 50
			});
		}
	},

	axle_info: function($el)
	{
		var info = {
			$table: $el.closest('table')
		};

		info.$trs = info.$table.find('tr').not('.controls'),
		info.count = info.$trs.filter('.show').length;

		return info;
	},

	axle_add: function($el)
	{
		var info = app.VIEW[app.HASH].axle_info($el);

		// stop from adding more than max_axles
		if( (app.MAX_AXLES - info.count) === 1 ) {
			$el.removeClass('button-green').addClass('button-grey');
			info.$trs.last().addClass('show');
		} else {
			info.$trs.filter('.show').last().next().addClass('show');
		}

		// enable delete axle button
		$el.next().addClass('button-red').removeClass('button-grey');

		// TRIGGER INPUT CHANGE (ONLY 1)
		$el.closest('tr').prev().find('input').eq(0).trigger('change');
	},

	evt_setup_axles: function()
	{
		// BRAKE: URL
		if( app._('axles').filter('.axle-container-brake').length > 0 ) {
			app.browser_open();
		}

		// ADD
		this.DOM.axle_add.on('click', function(){

			// do nothing max axles
			if( $(this).hasClass('button-grey') ) {
				return;
			}

			var $el = $(this),
				axle =  $el.closest('table').attr('data');

			if( axle && axle !== '0' ) {
				app.show_confirm("Are you sure you want to add an Axle?\n\nAsset should have: "+axle+" Axles", "Confirm Action", function(r){

				if( r === false ) {
					return;
				}

				app.VIEW[app.HASH].axle_add($el);
			});

			} else {
				app.VIEW[app.HASH].axle_add($el);
			}
		});

		// DELETE
		this.DOM.axle_delete.on('click', function(){

			// do nothing max axles
			if( $(this).hasClass('button-grey') ) {
				return;
			}

			var $el = $(this),
				info = app.VIEW[app.HASH].axle_info($el),
				axle =  $el.closest('table').attr('data'),
				msg = ( axle && axle !== '0' ) ? "\n\nThis Asset should have: "+axle : '';

			// remove last axle
			app.show_confirm("Are you sure you want to remove the last Axle?"+msg, "Confirm Action", function(r){

				if( r === false ) {
					return;
				}

				// disable delete
				if( info.count === (app.MIN_AXLES + 1) ) {
					$el.removeClass('button-red').addClass('button-grey');
				}

				var $tr = info.$trs.filter('.show').last().removeClass('show');

				// add disabled to images
				$tr.find('img').addClass('disabled');

				// clear inputs
				$tr.find(':input').val('').last().trigger('change');
			});
		});
	},

	/**
	 * Setup canvas for signatures
	 */
	evt_setup_signatures: function()
	{
		var opts = {
				minWidth: 1,
				maxWidth: 2,
				backgroundColor: 'rgb(255, 255, 255)',
				throttle: 5,
			},
			type = "image/jpeg";

		function setupSignatures()
		{
			var signaturePad = new SignaturePad(this, opts),
				$parent = $(this).closest('.signature-container'),
				$input = $parent.find('input'),
				$btn = $parent.find('a.button');

			function disableSignature()
			{
				// disable signature
				signaturePad.off();
	
				// enable edit
				$btn.filter('.btn-edit').show();
				$btn.filter('.btn-save').hide();
				$btn.filter('.btn-clear').hide();
			}

			// enable buttons
			signaturePad.addEventListener('endStroke', function(){
				$btn.addClass('selected').removeClass('button-grey');

				$btn.filter('.btn-save').show();
				$btn.filter('.btn-clear').show();
				$btn.filter('.btn-edit').removeClass('selected').hide();
			});

			$btn.on('click', function(){

				// do nothing
				if( $(this).hasClass('button-grey') ) {
					return;
				}

				var val = '',
					action = '';

				if( $(this).hasClass('btn-clear') ) {

					action = 'clear';

					// CLEAR
					signaturePad.clear();

				} else if( $(this).hasClass('btn-edit') ) {

					action = 'edit';

					// EDIT
					signaturePad.on();
					$btn.filter('.btn-edit').hide();
					$btn.filter('.btn-save').show();
					$btn.filter('.btn-clear').show();

				} else {

					// SAVE
					action = 'save';

					// update value
					val = signaturePad.toDataURL(type).split(';base64,')[1];

					// disable re-use of signature
					disableSignature();
				}
				
				// save changes
				if( action !== 'edit' ){
					$input.val(val).trigger('change');
				}

				// change button states
				$btn.not('.btn-edit').removeClass('selected').addClass('button-grey')
			});

			// paint canvas
			if( $input.attr('value') ) {

				// paint canvas
				signaturePad.fromDataURL(`data:${type};base64,`+$input.val());

				disableSignature();
			}
		}

		app._('signatures').each(setupSignatures);
	},	

	evt_btn_complete: function()
	{
		this.DOM.btn_complete.on('click', function(){

			// SAVE: date_end + refresh
			app.show_confirm("You will not be able to change any answers for this inspection after marking as completed", "Please remember", function(response){

				if( response === true ) {
					var data = {'date_end': app.DATE.format('datetime')};

					app.FORM.save_offline('mai', 'edit', data, true, app.URI[1], false, function(){
						// refresh page on callback
						app.redirect();
					});
				}
			});
		});
	},

	evt_btn_delete: function()
	{
		this.DOM.btn_delete.on('click', function(){

			// SAVE: date_end + refresh
			app.show_confirm("Do you want to delete this inspection?", "Delete Inspection", function(response){
				
				if( response === true ) {
					app.show_confirm("Please confirm you wish to delete this Inspection as all data relating to this Inspection will be lost?", "Confirm Deletion", function(r){
						if( r === true ) {

							app.VIEW[app.HASH].DOM.btn_delete.hide().after('<img class="loading" src="img/loading.svg">');

							// delete current inspection
							app.MAINTENANCE.delete(app.URI[1], app.DATA.MAI);
							
							// redirect to inspection list
							setTimeout(function(){
								app.redirect('mai_list');
							}, 600 );
						}
					});
				}
			});
		});
	},

	evt_submit_setup: function()
	{
		var data = $.extend({'mai_ans': []}, app.DATA.MAI);

		app.CACHE.MAI_ANS.filter(function(r){
			if( r.maintenance_id === app.URI[1] ) {
				data.mai_ans.push($.extend({}, r));
		    }
		});

		app.FORM.start({'changes': false, 'data': data});
	},

	form_online: function(json, post)
	{
		if( json.data && json.data.mai ){

			var data = {'id': json.data.mai.id};

			// update inspection with id
			app.FORM.save('mai', 'edit', data, post, true, app.URI[1], false, false, false, false, function(){

				// LOOP through each answer and add id
				$.each(json.data.mai_ans, function(k,v){

					if( v.ts && v.id ) {
						var i = app.cache_get_index('mai_ans', v.ts);

						if( i >= 0 && app.CACHE.MAI_ANS[i] ) {
							delete app.CACHE.MAI_ANS[i].sync;
							delete app.CACHE.MAI_ANS[i].ts;
							app.CACHE.MAI_ANS[i].id = v.id.toString();
						}
					}
				});

				// redirect on save callback
				app.cache_save('mai_ans', false, false, function(){
					app.redirect('mai_success/'+json.data.mai.id);
				});
			});
		}
	},

	form_offline: function()
	{
		console.warn('form_offline');

		// redirect on save callback
		app.redirect('mai_success/'+app.URI[1]);
	}
};