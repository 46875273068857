import {ActionSheet} from '@capacitor/action-sheet';
import {Capacitor} from "@capacitor/core";

export class ModalCore {
    modalOptionEvent = new Event('action-sheet-option', {
        cancelable: true,
        composed: false,
        bubbles: true
    });
    modalCloseEvent = new Event('action-sheet-closed', {
        cancelable: false,
        composed: false,
        bubbles: true
    });
    open = true;

    async create(title, message, closeButton, parentElement)
    {
        if(Capacitor.getPlatform() === 'web') {
            return new Promise((resolve, reject) => {
                if(!parentElement) {
                    reject('Parent element is required to display an Modal on Web.');
                    return;
                }

                const parentEl = $(parentElement).get(0);

                if(!parentEl) {
                    reject('Failed to find parent element to use for modal.');
                }

                parentEl.addEventListener('modal-option', (e) => {
                    this.close(parentElement, true);
                    resolve();
                });

                parentEl.addEventListener('action-sheet-closed', (e) => {
                    resolve();
                });

                this.insertDOM(title, message, closeButton, parentElement);
                this.setupEvents(parentElement, parentEl);
            });
        }

        const result = await ActionSheet.showActions({
            title: title,
            message: message,
            options: options,
        });

        return result.index;
    }

    insertDOM(header, message, closeButton, parentElement) {
        let html = `
        <div class="outer-wrapper">
            <div class="wrapper${this.open ? ' open' : ''}">
                <div class="modal-content">
                    <div class="title" style="font-size:1.5rem;">${header}</div>
                    <div class="message" style="font-size:1rem;">${message}</div>
                    <div class="modal-option" style="display:flex;flex-direction:row-reverse;padding-left:16px;padding-right:16px;margin-bottom:20px;">
                        <div class="modal-button" style="padding-top:0.5rem;padding-bottom:0.5rem;width:10%;text-align:center;">${closeButton}</div>
                    </div>
                </div>
            </div>
        </div>
        `;

        $(parentElement).html(html);
    }

    setupEvents(parentElement, parentEl) {
        $(`${parentElement} .modal-option`).on('click', (e) => {
            this.close(parentElement);
        });

        $(`${parentElement} .outer-wrapper`).on('click', (e) => {
            if($(e.target).hasClass('outer-wrapper') || $(e.target).hasClass('wrapper')) {
                this.close(parentElement, true);
            }
        });
    }

    close(parentElement, success) {
        console.log('Closing Modal');
        $(`${parentElement} .modal-option`).off('click');
        $(parentElement).off('modal-option');
        $(parentElement).html('');
        if(!success && $(parentElement).get(0)) {
            $(parentElement).get(0).dispatchEvent(this.modalCloseEvent);
        }
    }
}
