export let acc_list = {

	DOM: {},

	init: function()
	{
		// DOM
		this.setup_dom();

		// EVENTS
		this.setup_events();

		// DELETE UNWANTED ACC
		this.check_unwanted_acc();
	},

	setup_dom: function()
	{
		this.DOM.table_tr_existing = app.DOM.content_load.find('table#acc-existing tbody tr.needsclick');		
	},

	setup_events: function()
	{
		// View existing defect
		this.DOM.table_tr_existing.on('click', function(){
			app.redirect('acc_form/'+$(this).attr('data-href'));
		});
	},

	check_unwanted_acc: function()
	{
		var now = moment().unix(),
			wait = 600; // 10 mins

		// remove unwanted defect
		$.each(app.CACHE.ACC, function(k,v){

			if( v.hasOwnProperty('saved') === false && v.hasOwnProperty('id') === false ) {
				app.FORM.save('acc', 'delete', {}, {}, true, v.ts);
			}
 		});
	}
};