import { Capacitor } from "@capacitor/core";
import { AppUpdate as Updater, AppUpdateAvailability } from '@capawesome/capacitor-app-update';

export class AppUpdate {
	constructor() {
		if(Capacitor.getPlatform() === 'web') {
			return;
		}
		
		if(!app.ONLINE && Capacitor.getPlatform() === 'android') {
			return;
		}
		
		this.start().catch((err) => console.error(err));
	}
	
	async start() {
		const isOutdated = await this.isOutdated();
		if(isOutdated) {
			let message;
			
			if(Capacitor.getPlatform() === 'ios') {
				message = 'Would you like to go to the app store to get the new version?';
			} else {
				message = 'Would you like to continue the update?';
			}
			
			await app.show_confirm(message, 'An update is availible', async (result) => {
				if(result === false) {
					return;
				}
				
				if(Capacitor.getPlatform() === 'ios') {
					await this.openAppStore();
					return;
				}
				
				try {
					await this.performImmediateUpdate();
				} catch(err) {
					await this.openAppStore();
				}
			}, ['Update', 'Not Now']);
		}
		/*app.show_alert([
			`Current Version: ${await this.getCurrentAppVersion()}`,
			`App Store Version: ${await this.getAvailableAppVersion()}`,
			`Outdated: ${await this.isOutdated()}`,
			`Response Data: ${JSON.stringify(await Updater.getAppUpdateInfo(), null, 4)}`
		].join('\n'), 'App Version');*/
	}
	
	async isOutdated() {
		const currentVersion = await this.getCurrentAppVersion();
		const availableVersion = await this.getAvailableAppVersion();
		
		if(Capacitor.getPlatform() !== 'ios') {
			return parseInt(currentVersion) < parseInt(availableVersion);
		}
		
		return parseInt(currentVersion.replaceAll('.', '')) < parseInt(availableVersion.replaceAll('.', ''));
	}
	
	async getCurrentAppVersion() {
		const result = await Updater.getAppUpdateInfo();
		if (Capacitor.getPlatform() === 'android') {
			return result.currentVersionCode;
		} else {
			return result.currentVersionName;
		}
	};
	
	async getAvailableAppVersion() {
		const result = await Updater.getAppUpdateInfo();
		if (Capacitor.getPlatform() === 'android') {
			return result.availableVersionCode;
		} else {
			return result.availableVersionName;
		}
	};
	
	async openAppStore() {
		await Updater.openAppStore();
	};
	
	async performImmediateUpdate() {
		const result = await Updater.getAppUpdateInfo();
		if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
			return;
		}
		if (result.immediateUpdateAllowed) {
			await Updater.performImmediateUpdate();
		}
	};
}