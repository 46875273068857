export let pin_forgot = {

	DOM: {},

	init: function()
	{
		// start the form
		app.FORM.start({save_show: true});

		// setup dom
		this.setup_dom();
	},

	setup_dom: function()
	{
		this.DOM.email = this.DOM.form.find('#email');
	},

	form_online: function(json, post)
	{
		console.log('form_online', json);

		if( json.status === 'success' ) {

			// remember email
			app.CACHE.USR.settings.forgot_email = post.email;
			app.cache_save('usr');

			// redirect
			app.redirect('pin_reset');
		}
	},

	form_online_error: function(json, post)
	{
		console.log('form_online_error');
		console.log(json);
		console.log(post);
	}
};