import {Camera, CameraDirection, CameraResultType, CameraSource} from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import {ActionSheetButtonStyle} from "@capacitor/action-sheet";

export let CAMERA = {
    HAS_PERMISSION: false,

    /**
     * The initialization method to be used before trying to access the Camera
     * @return {Promise<void>}
     */
    startPerms: async () => {
        if(!Capacitor.isPluginAvailable('Camera') || !Capacitor.isNativePlatform()) {
            return;
        }

        const hasPermission = await CAMERA.checkPermissions();

        if(!hasPermission) {
            const newPerms = await Camera.requestPermissions({ permissions: ['camera'] });
            CAMERA.HAS_PERMISSION = newPerms.camera === 'granted';
            return;
        }

        CAMERA.HAS_PERMISSION = true;
    },

    /**
     * Checks we have access to the devices Camera
     * @return {Promise<boolean>}
     */
    checkPermissions: async () => {
        if(!Capacitor.isPluginAvailable('Camera')) {
            return false;
        }

        const hasPerms = await Camera.checkPermissions();
        return hasPerms.camera === 'granted';
    },

    start: async (settings) => {
        let opts = CAMERA.getOptions(settings);
        await CAMERA.startPerms();

        // get dir from tbl and fix trailing slash
        settings.dir = app.CACHE.URL_FILES[settings.tbl].replace('/','');
        // remember what page user was on if android os kills app when camera opens
        settings.page = app.URI.join('/');

        // for inspections open specific question
        if( app.HASH === 'mai' ) {
            settings.page += '/'+ settings.identifier;
        }

        if( settings.$img.length ){
            settings.field = settings.$img.attr('data');
        }

        // save camera settings to localstorage to access after low memory android phone kills app
        if( app.CACHE.DEVICE.platform === 'android' ) {
            localStorage.setItem('cameraState', JSON.stringify(settings));
        }

        try {
            await app.FLASHLIGHT.off();

            await app.FILE.start();
            const photo = await Camera.getPhoto(opts);
            await CAMERA.win(photo, settings);
        } catch(err) {
            CAMERA.fail(err.message, opts);
        }
    },

    start_webapp: function(settings)
    {
        // check if currently uploading
        if( app.VIEW[app.HASH].DOM.form_webapp.hasClass('upload') ) {
            app.show_alert('The previous image is still uploading.\n\nThis may take a while depending on the size of the image.', 'Please Wait');
            return;
        } else if( app.VIEW[app.HASH].DOM.form_webapp.hasClass('delete') ) {
            app.show_alert('Currently deleting another image.', 'Please Wait');
            return;
        }

        // reset file input
        app.VIEW[app.HASH].DOM.form_webapp_file_input.val('').click();

        // unhighlight all other imgs
        app.VIEW[app.HASH].DOM.photos_container_img.removeClass('selected');

        // highlight img chosen
        settings.$img.addClass('selected');
    },

    getOptions: (settings) => {
        var sourceType = ( settings.$btn && settings.$btn.hasClass('btn-gallery') ) ? CameraSource.Photos : CameraSource.Camera;

        return {
            width: 720,
            height: 1280,
            quality: 65,
            source: sourceType,
            correctOrientation: true,
            direction: CameraDirection.Rear,
            resultType: CameraResultType.Uri
        };
    },

    /**
     * This will replace setup_multi
     *
     * @param {object} data
     * @param {string} stopField
     * @param {string} ajaxWebapp Table name to save data too
     */
    setup: function(data, stopField, ajaxWebapp)
    {
        if( app.WEBAPP && ajaxWebapp ) {
            CAMERA._insert_dom_webapp(ajaxWebapp);
        }

        // DOM
        CAMERA._setup_dom(ajaxWebapp);

        // ADD
        if( !stopField || data.hasOwnProperty(stopField) === false ) {
            CAMERA._setup_btn_click(data, stopField, ajaxWebapp);
        }

        // // CLICK: ADD
        CAMERA._setup_img_click(data, stopField);

        // // WEBAPP READ IMAGE
        if( app.VIEW[app.HASH].DOM.hasOwnProperty('form_webapp_file_input') ) {
            CAMERA._setup_webapp(ajaxWebapp);
        }
    },

    get_settings: function($btn)
    {
        var $parent = $btn.closest('.photos-container');

        return {
            '$img': $parent.find('img.img-blank').eq(0),
            '$btn': $btn,
            '$parent': $parent,
            'tbl': $parent.attr('data-tbl'),
            'identifier': $parent.attr('data')
        };
    },

    /**
     * cache dom items
     */
    _setup_dom: function(ajaxWebapp)
    {
        app.VIEW[app.HASH].DOM.photos_container = app.VIEW[app.HASH].DOM.form.find('.photos-container');
        app.VIEW[app.HASH].DOM.btn_photo = app.VIEW[app.HASH].DOM.photos_container.find('.btn-photo,.btn-gallery');
        app.VIEW[app.HASH].DOM.img_photo = app.VIEW[app.HASH].DOM.photos_container.find('img');
        app.VIEW[app.HASH].DOM.input_photo = app.VIEW[app.HASH].DOM.photos_container.find('input');

        // initial button colour
        app.VIEW[app.HASH].DOM.photos_container.each(function(){
            if( !$(this).find('.img-blank').length ) {
                $(this).find('.button').addClass('button-grey').removeClass('button-black');
            }
        });

        // webapp: dom elements
        if( ajaxWebapp && app.WEBAPP ) {
            app._('photos_container_img', app._('photos_container').find('img'));
            app._('form_webapp', app.DOM.content_load.find('#form-webapp'));
            app._('form_webapp_row_ts', app._('form_webapp').find('#row_ts'));
            app._('form_webapp_field', app._('form_webapp').find('#field'));
            app._('form_webapp_file_input', app.DOM.content_load.find('#file_input'));
            app._('form_webapp_file', app._('form_webapp').find('#file'));
        }
    },

    /**
     * Insert webapp photo form into dom
     */
    _insert_dom_webapp: function(tbl)
    {
        var ts = app.URI[1];

        var html = `
		<input type="file" name="file_input" id="file_input" accept="image/gif, image/jpeg, image/png" style="display:none">
		<form action="" id="form-webapp">
			<!-- values dont change -->
			<input type="hidden" name="parent_ts" id="parent_ts" value="${ts}">
			<input type="hidden" name="tbl_ref" id="tbl_ref" value="${tbl}">
			<!-- [END] values dont change -->

			<!-- reset by js -->
			<input type="hidden" name="field" id="field" value="">
			<input type="hidden" name="row_ts" id="row_ts" value="">
			<input type="hidden" name="file" id="file" value="">
			<!-- [END] reset by js -->
		</form>`;

        app.DOM.content_load.append(html);
    },

    _setup_btn_click: function(data, stopField, ajaxWebapp)
    {
        app.VIEW[app.HASH].DOM.btn_photo.on('click', async (e) => {

            // get one that is empty
            var settings = CAMERA.get_settings($(e.currentTarget));

            // do nothing
            if( settings.$img.length === 0 ) {
                app.show_alert('Maximum number of photos reached','Max Photos');
                return;
            }

            if( app.PHONEGAP ) {
                await CAMERA.start(settings);
            } else if( ajaxWebapp && app.WEBAPP && app.VIEW[app.HASH].DOM.form_webapp_file_input.length > 0 ) {
                CAMERA.start_webapp(settings);
            } else {
                settings.filename = settings.$img.attr('data')+'.jpg';
                app.CAMERA.save(settings);
            }
        });
    },

    _setup_img_click: function(data, stopField)
    {
        const options = [
            {
                title: 'View',
                style: ActionSheetButtonStyle.Default
            },
            {
                title: 'Retake',
                style: ActionSheetButtonStyle.Default
            },
            {
                title: 'Delete',
                style: ActionSheetButtonStyle.Destructive
            },
            {
                title: 'Cancel',
                style: ActionSheetButtonStyle.Cancel
            }
        ];

        app.VIEW[app.HASH].DOM.img_photo.on('click', async (e) => {
            var $img = $(e.currentTarget),
                $btn = $(e.currentTarget).closest('.photos-container').find('a.button').eq(0),
                settings = CAMERA.get_settings($btn);

            // trigger photo button which handles event logic
            if( $(e.currentTarget).hasClass('img-blank') ) {
                $btn.trigger('click');
                return;
            }

            // redirect path
            settings.redirect = 'photo/'+settings.tbl+'/'+settings.identifier +'/'+$(e.currentTarget).attr('data');

            // VIEW IMAGE
            if( (app.PHONEGAP === false && !app.WEBAPP ) || data.hasOwnProperty(stopField) ) {
                app.redirect(settings.redirect);
                return;
            }
            
            try {
                const selectedAction = await app.ACTION_SHEET.create(
                    'Photo options',
                    'What would you like to do with this photo?',
                    options,
                    '#action-sheet-container'
                );
                // overwrite img already set as it chooses first .img-blank
                settings.$img = $img;

                switch (selectedAction) {
                    case 0: // View
                        app.redirect(settings.redirect);
                        break;
                    case 1: // Re-take
                        if( app.PHONEGAP ) {
                            await CAMERA.start(settings);
                        } else if( app.WEBAPP && app.VIEW[app.HASH].DOM.form_webapp_file_input.length > 0 ) {
                            CAMERA.start_webapp(settings);
                        } else {
                            settings.filename = settings.$img.attr('data')+'.jpg';
                            app.CAMERA.save(settings);
                        }
                        break;
                    case 2: // Delete
                        CAMERA.delete_multi(settings);
                        break;
                }
            } catch(err) {
                console.error(`Error displaying ActionSheet: ${err.message}`);
            }
        });
    },

    delete_multi: function(settings)
    {
        // get latest version of record
        // to check for any previous photo deletions
        var index = app.cache_get_index(settings.tbl, settings.identifier);

        if( index === false ) {
            console.log('no data found, cannot delete photo');
            return;
        }

        var f = settings.$img.attr('data'),
            fields = [f, f +'_local', f+'_needs_uploading'];

        // remove associated fields
        $.each(fields, function(k,v){
            delete app.CACHE[settings.tbl.toUpperCase()][index][v];
        });

        // save updated data
        app.cache_save(settings.tbl);

        // update dom
        settings.$img.addClass('img-blank').attr('src', 'img/unknown_photo.png');
        settings.$img.next().val('');

        // enable add photo
        app.VIEW[app.HASH].DOM.btn_photo.removeClass('button-grey').addClass('button-black');

        // trigger required check
        if( app.VIEW[app.HASH].onCameraDelete ) {
            app.VIEW[app.HASH].onCameraDelete(settings.$img, settings);
        }
    },

    _setup_webapp: function(tbl)
    {
        // CHANGE FILE
        app.VIEW[app.HASH].DOM.form_webapp_file_input.on('change', function(){
            // get clicked image
            var $img = app.VIEW[app.HASH].DOM.photos_container_img.filter('.selected');
            $img.addClass('upload').removeClass('img-blank').attr('src', 'img/loading-black.svg');
            
            var reader = new FileReader();
            
            // change state if nothing is selected
            $img.removeClass('selected');

            reader.onload = function(e) {

                var image = new Image();
                image.src = e.target.result;

                image.onload = function() {

                    // start ajax
                    CAMERA.ajax_webapp_prepare(tbl, $img, this.src);
                };
            };

            reader.readAsDataURL(this.files[0]);
        });
    },

    _setup_img_click_webapp: function(r, settings)
    {
        if( r === 1 ) {

            // view
            app.redirect(settings.redirect);

        } else if( r === 2 ){

            // delete

            var data = {
                field: settings.$img.attr('data'),
                tbl_ref: settings.tbl,
                parent_ts: app.URI[1],
                row_ts: settings.identifier,
            };

            var row = app.TPL.get_row(settings.tbl, settings.identifier);

            if( !row ) {
                app.show_alert('Could not find photo to delete, please try again', 'Problem');
                return;
            }

            data.file = row[data.field];

            // go ajax!
            CAMERA._setup_img_delete_webapp(settings, data);
        }
    },

    ajax_webapp_prepare: function(tbl, $img, src)
    {
        var $btn = $img.closest('.photos-container').find('a.button').eq(0),
            settings = app.CAMERA.get_settings($btn);

        // change form state
        app.VIEW[app.HASH].DOM.form_webapp.addClass('upload');
        app.VIEW[app.HASH].DOM.form_webapp_field.val($img.attr('data'));
        app.VIEW[app.HASH].DOM.form_webapp_row_ts.val(settings.identifier);
        app.VIEW[app.HASH].DOM.form_webapp_file.val(src);
        
        // submit
        var data = app.get_api_data(app.VIEW[app.HASH].DOM.form_webapp.serializeObject());

        // page before ajax
        var hash = app.HASH;

        var ajax = $.ajax({
            url: app.get_api_url('web_pho'),
            type: 'POST',
            timeout: app.TIMEOUT_AJAX,
            data: data
        });

        ajax.done(function(r){

            if( r.hasOwnProperty('status') && r.status === 'success' ) {

                // change image state
                $img.removeClass('upload');
                $img.attr('src', src);
                
                // change form state
                app.VIEW[hash].DOM.form_webapp.removeClass('upload');

                // save item
                var row = {
                    [$img.attr('data')] : r.data.web_pho
                };

                // trigger camera save
                app.FORM.save_offline(tbl, 'edit', row, true, settings.identifier, false, function(){
                    if( app.VIEW[hash].hasOwnProperty('onCameraSave') ){
                        app.VIEW[hash].onCameraSave(false, $img, settings);
                    }
                });

            } else {
                app.show_alert('There was a problem uploading your picture please try again.', 'Could not upload');
            }
        });
    },

    _setup_img_delete_webapp: function(settings, data)
    {
        // change form state
        app.VIEW[app.HASH].DOM.form_webapp.addClass('delete');

        // change image state
        settings.$img.addClass('delete');

        var ajax = $.ajax({
            url: app.get_api_url('web_pho_delete'),
            type: 'POST',
            timeout: app.TIMEOUT_AJAX,
            data: app.get_api_data(data)
        });

        ajax.done(function(r){

            // change form state
            app.VIEW[app.HASH].DOM.form_webapp.removeClass('delete');

            // change img state
            settings.$img.removeClass('delete');

            if( r && r.status === 'success' ) {

                // update record
                var row = {
                    [data.field]: ''
                };

                app.FORM.save_offline(settings.tbl, 'edit', row, true, settings.identifier, false);

                // change picture state
                settings.$img.attr('src', 'img/unknown_photo.png').addClass('img-blank');

                // trigger required check
                if( app.VIEW[app.HASH].onCameraDelete ) {
                    app.VIEW[app.HASH].onCameraDelete(settings.$img);
                }

            } else {

                app.show_alert('There was a problem deleting this image', 'Error');
            }
        });
    },

    save: (uri, settings, recoveryMode) => {
        console.warn('app.CAMERA.save', settings);

        if(!settings) {
            console.error('No Settings passed to save method.')
            return;
        }

        var imgElementExists = settings.$img ? $(settings.$img).is('img') : false;

        if( app.PHONEGAP === false ) {
            uri = 'img/'+app.CACHE.URL_FILES[settings.tbl]+settings.filename;
        } else {
            uri = Capacitor.convertFileSrc(uri);
        }

        // only apply dom elements
        if( imgElementExists ) {
            // remove blank image status
            // show new picture
            settings.$img.removeClass('img-blank').attr('src', uri);

            // update hidden field
            settings.$img.next().val(settings.filename);

            // check if any remaining photos left
            if( settings.$parent.find('.img-blank').length === 0 ) {
                settings.$parent.find('a.button').addClass('button-grey').removeClass('button-black');
            }
        }

        // save to cache
        if( settings.tbl && settings.identifier) {

            // get field name from image
            var field = ( settings.field ) ? settings.field : settings.$img.attr('data'),
                // setup data object
                data = {};
            data[field + '_local'] = settings.filename;
            data['capacitor'] = true;
            data[field + '_needs_uploading'] = true;

            // save
            app.FORM.save_offline(settings.tbl, 'edit', data, true, settings.identifier, false, function(){
                if(recoveryMode) {
                    return;
                }
                
                // callback function
                if( app.VIEW[app.HASH].onCameraSave) {
                    app.VIEW[app.HASH].onCameraSave(data, settings.$img, settings);
                } else if( !imgElementExists && app.CACHE.DEVICE.platform === 'android' && settings.page ) {

                    app.redirect(settings.page);
                }
            }, true);
        }

        app.onDeviceResume(false).catch((err) => console.log(`Error trying to resume app: ${err.message}`));
    },

    /**
     * SUCCESS: picture has been taken succesfully move file to perma storage
     */
    win: async (file, settings) => {
        console.log('SUCCESS: Picture taken -> ', file, settings);

        if( app.PHONEGAP ) {
            await app.onDeviceResume(false);
        }

        await app.FILE.move_picture(file, settings);
    },

    /**
     * There was an error loading camera plugin
     */
    fail: function(error, opts)
    {
        console.log('ERROR: picture wasnt taken');
        console.log('MESSAGE: ', error);
        console.log('OPTIONS: ', opts);

        // ios fix
        app.onDeviceResume(false).catch((err) => console.warn(`Error attempting device resume: ${err.message}`));
    },
};