import VMasker from 'vanilla-masker';

export let fue_exp = {
    
    DOM: {},

    // is fields have associated containers depending on there value being === '1'
    // if value is = 0 associated container doesnt display
    yes_no_fields: ['is_fuel','is_adblue','is_cost'],

    init: function ()
    {
        if( !app.DATA.RESULT ) {
            app.redirect('home');
            return;
        }        

        var form = (app.URI[2] === 'success') ? false : true;

        if (form) {
            // check form exists
            app.FORM.start({ hideConfirmation: true, ignore: ':hidden', data: app.DATA.RESULT});
        } else if( app.DATA.RESULT.id && app.URI[2] === 'success' ) {
            let hasPhotos = false;
            Object.keys(app.DATA.RESULT).forEach((key) => {
                if(key.startsWith('file') && key.endsWith('_needs_uploading') && app.DATA.RESULT[key] !== false) {
                    hasPhotos = true;
                }
            });

            if(!hasPhotos) {
                // delete current record
                app.FORM.save_offline('fue_exp', 'delete', {}, true, app.DATA.RESULT.id);
            }
        }

        // DOM
        this.setup_dom(form);

        // EVENTS
        this.setup_events(form);
        
        if(form) {
            fue_exp.checkFiles(app.DATA.RESULT);
        }
    },

    setup_dom: function (form) {

        if( !form ) {
            return;
        }

        app._('is_fuel_container', app._('form').find('#is_fuel_container'));
        app._('is_adblue_container', app._('form').find('#is_adblue_container'));

        if (form) {
            app._('btn_delete', app.DOM.content_load.find('#btn-delete'));
            app._('form_inputs', app._('form').find(':input'));
            app._('cost', app._('form_inputs').filter('#cost'));
            app._('is_fuel_container_inputs', app._('is_fuel_container').find(':input'));
            app._('is_adblue_container_inputs', app._('is_adblue_container').find(':input'));
            fue_exp.DOM.force_photo_message = $('.msg-photo');
        }
    },

    setup_events: function (form)
    {
        if (app.URI[2] === 'success') {

            // UPLOAD
            this.setup_photos_upload();

        } else if (form) {

            // FORM CHANGES
            this.setup_form_events();

            // CAMERA
            app.CAMERA.setup(app.DATA.RESULT, 'date_end', 'fue_exp');
        } else {

            // force items to be uneditable
            app.DOM.content_load.find('label').removeAttr('for');
            app.DOM.content_load.find('#btn-photo').hide();
        }

        // SETUP FOOTER
        let opts = [{
            btn: 'left', txt: 'Back', href: 'fue_exp_setup'
        }];

        if (app.URI[2] !== 'success' && form) {
            opts.push({ btn: 'right', txt: 'Submit', click: 'form_btn_submit' });
        }

        app.setup_footer(opts);
    },

    setup_photos_upload: function()
    {
        // no phonegap OR offline
        if( app.PHONEGAP === false || !app.DATA.RESULT.id ) {
            return;
        }

        // get photos to upload
        var data_photos = app.SYNC.send_data_photos_tbl('fue_exp', 'id', app.URI[1]);

        console.log('photos to upload', data_photos);

        // upload if photos exist
        if( data_photos.length > 0 ) {
            app.SYNC.send_photos(data_photos);
        }
    },

    setup_form_events: function ()
    {
        const $yes_no = {};
        this.yes_no_fields.forEach(val => {
            $yes_no[val] = app._('form_inputs').filter(`[name="${val}"]`);
        });   

        app._('form_inputs').not('[type="checkbox"]').on('change', function(){
            // data container
            const id = $(this).attr('id');
            let data = {};
            data[id] = $(this).val();
            app.FORM.save_offline('fue_exp', 'edit', data, true, app.URI[1], false, false, true);
            
            const row = app.TPL.get_row('fue_exp', app.URI[1]);
            if(!row) {
                return;
            }
            
            fue_exp.checkFiles(row);
		});

        this.setup_form_yes_no_fields($yes_no);

        this.setup_form_fuel_type($yes_no.is_fuel);

        VMasker(app._('cost')).maskMoney({
            precision: 2,
            separator: '.',
            delimiter: ',',
            unit: '£',
        });

        app._('cost').on('blur', () => {
            const costVal = app._('cost').val();
            if(costVal && (
                parseFloat(costVal.replace('£', '').trim()) === 0)) {
                app._('cost').val('');
            }
        });
        
        app._('form_inputs').filter(`[type="number"]`).on('blur', (e) => {
            if(e.currentTarget.value) {
                let number = parseFloat(e.currentTarget.value);
                
                if(isNaN(number)) {
                    $(e.currentTarget).val('');
                }
                
                const numberSplit = e.currentTarget.value.split('.');
                if(numberSplit.length < 2) {
                    return;
                }
                
                if(number % 1 === 0 || parseInt(numberSplit[1]) === 0) {
                    $(e.currentTarget).val(numberSplit[0]);
                } else {
                    $(e.currentTarget).val([numberSplit[0], numberSplit[1].slice(0, 2)].join('.'));
                }
                
                const id = $(e.currentTarget).attr('id');
                let data = {};
                data[id] = $(e.currentTarget).val();
                app.FORM.save_offline('fue_exp', 'edit', data, true, app.URI[1], false, false, true);
            }
        });

        app._('btn_delete').on('click', app.FUEL_EXPENSES.delete);
	},

    setup_form_yes_no_fields: function($yes_no)
    {
        for(const key in $yes_no){

            const $yes_no_current = $yes_no[key];
            const $container = $yes_no_current.eq(0).closest('.radios').next();
            const key_value = key.replace('is_', '');
            
            $yes_no[key].on('change init', function(e){

                const $inputs = $container.find(':input');
                let data = {};
                data[key] = $yes_no_current.eq(1).is(':checked') ? '1' : '0';

                // check container
                if (data[key] === '1') {

                    // show
                    $container.show();

                    // require
                    $inputs.addClass('required');

                } else {

                    // hide
                    $container.hide().find(`#${key_value}`).val('').trigger('change');

                    // unrequire
                    $inputs.removeClass('required error').parent().removeClass('.error');
                }

                // save
                if( e.type === 'change' ) {
                    app.FORM.save_offline('fue_exp', 'edit', data, true, app.URI[1], false, false, true);
                }

                // if changing value calculate if form can be submitted
                // otherwise on init only check on last item
                if( e.type === 'change' || key === app.VIEW.fue_exp.yes_no_fields[app.VIEW.fue_exp.yes_no_fields.length - 1] ){
                    setTimeout(function(){
                        app.VIEW.fue_exp.check_form_can_submit();
                    }, 300)
                }

            }).eq(0).trigger('init')
        }
    },

    setup_form_fuel_type: function()
    {
        const $unit = app._('form').find('#unit');
        const $fuel = app._('form_inputs').filter('#fuel');

        app._('form_inputs').filter('#fuel_type').on('change init', function(){
            const type_selected = this.value;
            const type_match = app.CACHE.FUE_EXP_TYPES.find( r => {
                if( r.id === type_selected ) {
                    return r;
                }
            });
            
            if( type_match ){
                $unit.text(`(${type_match.unit})`);
                $fuel.attr('placeholder', type_match.unit);
            } else {
                $unit.text('');
                $fuel.attr('placeholder', '');
            } 
        }).trigger('init');
    },

    check_form_can_submit: function()
    {
        const row = app.TPL.get_row('fue_exp', app.URI[1]);
        let formSubmission = false;

        app.VIEW.fue_exp.yes_no_fields.forEach(val => {
            if( row[val] === '1' ) {
                formSubmission = true;
            }
        });

        if( formSubmission ) {
            app.DOM.footer_btn_right.removeClass('button-grey');
        } else {
            app.DOM.footer_btn_right.addClass('button-grey');
        }
        
        fue_exp.checkFiles(row);
    },

    /**
     * @param string fuel_source 
     * @return bool
     */
    check_fuel_source_has_cost: function(fuel_source)
    {
        let cost = false;

        app.CACHE.FUE_EXP_SOURCES.forEach(source => {
            if( source.cost && source.id === fuel_source ) {
                cost = true;
            }
        });

        return cost;
    },

    form_online: function(json, post)
	{
        if( json.data && json.data.fue_exp ) {

            json.data.fue_exp = Object.assign(post, json.data.fue_exp);
            json.data.fue_exp.saved = true;

            // add on locked date
            // APP only
            json.data.fue_exp.date_end = app.DATE.format('datetime');

            // save
            app.FORM.save('fue_exp', 'edit', json.data.fue_exp, post, false, app.URI[1]);
        }
	},

    form_offline: function()
	{
		console.warn('form_offline');

        if( app.DATA.RESULT.id ) {
            app.show_alert('Could not update this Fuel / Expense right now please try again');    
        } else {
            // redirect on save callback
            app.redirect(`fue_exp/${app.URI[1]}/success/offline`);
        }
	},
    
    onCameraSave: (data, $img, settings) => {
        if(settings.identifier !== app.URI[1]) {
            return;
        }
        
        const fue = app.TPL.get_row('fue_exp', settings.identifier, 'ts');
        if(!fue) {
            return;
        }
        
        fue_exp.checkFiles(fue);
    },
    
    onCameraDelete: (img, settings) => {
        if(settings.identifier !== app.URI[1]) {
            return;
        }
        
        const fue = app.TPL.get_row('fue_exp', settings.identifier, 'ts');
        if(!fue) {
            return;
        }
        
        fue_exp.checkFiles(fue);
    },
    
    checkFiles: (data) => {
        if(app.CACHE.USR.opr_priv_cost_force_photo !== '1') {
            return;
        }
        
        let hasImage = false;
        Object.keys(data).forEach((key) => {
            let shouldAdd;
            if (app.WEBAPP) {
                shouldAdd = fue_exp.checkWebappFiles(key, data);
            } else {
                shouldAdd = fue_exp.checkAppFiles(key, data);
            }
            
            if (!shouldAdd) {
                return;
            }
            
            hasImage = true;
        });
        
        const submitBtn = $('#button-right');
        if(!hasImage) {
            fue_exp.DOM.force_photo_message.hide().css('display', 'block');
            submitBtn.addClass('button-grey').removeClass('button-primary');
            return;
        }
        
        fue_exp.DOM.force_photo_message.hide().css('display', 'none');
        submitBtn.addClass('button-primary').removeClass('button-grey');
    },
    
    checkWebappFiles: (key, ans) => {
        if (
            !key.startsWith("file") ||
            (key.startsWith("file") && key.endsWith("_needs_uploading"))
        ) {
            return false;
        }
        
        return !(!ans[key] || ans[key].length === 0);
    },
    
    checkAppFiles: (key, acc) => {
        if (!key.startsWith("file") || !key.endsWith("_local")) {
            return false;
        }
        
        return !(!acc[key] || acc[key].length === 0);
    },
};