export let rep_success = {

	init: function()
	{
		if( app.PHONEGAP === false ) {
			return;
		}

		// get photos to upload
		var data_photos = app.SYNC.send_data_photos_tbl('rep_ans', 'report_id', app.URI[1]);

		console.log('photos to upload', data_photos);	 

		// upload if photos exist
		if( data_photos.length > 0 ) {
			app.SYNC.send_photos(data_photos);
		}
	}
};