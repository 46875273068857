import 'rangeslider.js';

export let rep_ans = {

	REP: {},
	REP_ANS: {},

	OPTIONS: {},

	DOM: {},

	redirect: function()
	{
		// invalid answer
		if( !app.URI[1] || !app.DATA.REP ) {
			return true;
		}
		
		if(
			(app.URI[2] === 'shared' && app.DATA.REP.hasOwnProperty('shared')) ||
			(app.URI[2] === 'fuel' && app.DATA.REP.hasOwnProperty('fuel')) ||
			(app.URI[2] === 'mileage' && app.DATA.REP.hasOwnProperty('mileage')) ||
			(app.URI[2] === 'hours' && app.DATA.REP.hasOwnProperty('hours')) ||
			(app.URI[2] === 'adblue' && app.DATA.REP.hasOwnProperty('adblue')) ||
			(app.URI[2] === 'height' && app.DATA.REP.hasOwnProperty('height'))
		) {
			return false;

		} else if(
			(app.URI[2] === 'shared' && app.DATA.REP.hasOwnProperty('shared') === false) ||
			(app.URI[2] === 'fuel' && app.DATA.REP.hasOwnProperty('fuel') === false) ||
			(app.URI[2] === 'mileage' && app.DATA.REP.hasOwnProperty('mileage') === false) ||
			(app.URI[2] === 'hours' && app.DATA.REP.hasOwnProperty('hours') === false) ||
			(app.URI[2] === 'adblue' && app.DATA.REP.hasOwnProperty('adblue') === false) ||
			(app.URI[2] === 'height' && app.DATA.REP.hasOwnProperty('height') === false)
		) {
			return true;
		}

		if( !app.DATA.REP_ANS ) {
			return true;
		}

		return false;
	},

	init: function()
	{
		// check to see URL is correct
		if( this.redirect() ) {
			app.redirect('home');
			return;
		}

		// DOM
		this.setup_dom();

		// EVENTS
		this.setup_events();
	},

	setup_dom: function()
	{	
		// overview
		this.DOM.report_overview = app.DOM.content_load.find('.report-overview');

		// nav
		this.DOM.btn_next = app.DOM.content_load.find('#btn-next');

		// msgs
		this.DOM.msg_required = app.DOM.content_load.find('.msg-required');
		this.DOM.msg_photo = this.DOM.msg_required.filter('#msg-photo');
		this.DOM.msg_photo_x = this.DOM.msg_required.filter('#msg-photo-count');
		this.DOM.msg_notes = this.DOM.msg_required.filter('#msg-notes');
		this.DOM.msg_answer = this.DOM.msg_required.filter('#msg-answer');

		// form
		this.DOM.form = app.DOM.content_load.find('#form');
		this.DOM.form_number = app.DOM.content_load.find(':input.number').not('.height').not('.decimal');
		this.DOM.form_decimal = app.DOM.content_load.find(':input.decimal');
		this.DOM.radios = this.DOM.form.find('#is_completed_no, #is_completed_yes, #is_fail_no, #is_fail_yes, #id_similar_no, #id_similar_yes');
		// completed
		this.DOM.is_completed_no = this.DOM.radios.filter('#is_completed_no');
		this.DOM.is_completed_yes = this.DOM.radios.filter('#is_completed_yes');
		// fail
		this.DOM.is_fail_no = this.DOM.radios.filter('#is_fail_no');
		this.DOM.is_fail_yes = this.DOM.radios.filter('#is_fail_yes');

		// ajax
		this.DOM.is_fail_no_loading = this.DOM.form.find('#is_fail_no_loading');// ajax loading
		this.DOM.is_fail_no_similar = this.DOM.form.find('#is_fail_no_similar');// ajax content
		this.DOM.id_similar_yes = this.DOM.radios.filter('#id_similar_yes');
		this.DOM.id_similar_no = this.DOM.radios.filter('#id_similar_no');

		this.DOM.answer_container = this.DOM.form.find('#answer-container');
		this.DOM.range = this.DOM.form.find('input.range');
		this.DOM.height = this.DOM.form.find('input.height');
		this.DOM.height_visibility_container = this.DOM.form.find('#height-visibility-container');
		this.DOM.height_visibility = this.DOM.height_visibility_container.find('[name="height_visibility"]');
		this.DOM.fields = this.DOM.form.find('textarea#notes, input#answer, input.answer');
		this.DOM.notes = this.DOM.fields.filter('#notes');
	},

	/**
	 * Get answers that are monitored from same question source
	 */
	ajax: function()
	{
		// toggle loading
		this.DOM.is_fail_no_loading.show();

		var hash = app.HASH,
			aid = app.URI[2];

        var ajax = $.ajax({
            url: app.get_api_url('rep_ans_monitored/get'),
            data: app.get_api_data({
            	question_id: app.DATA.REP_QUE.id,
            	vehicle_id: app.DATA.REP.vehicle_id
            }),
            method: 'POST'
        });

        ajax.done(function(r){

			// do nothing
        	if( aid !== app.URI[2] || hash !== app.HASH ) {
        		return;
        	}

            // success
            if( r && r.status === 'success') {
                app.VIEW[hash].ajax_success(r.data);
            }
        });

        ajax.fail(function(a,b){
        	if( aid === app.URI[2] && hash === app.HASH ) {
	        	app.VIEW[hash].ajax_error(a,b);
        	}
        });

        ajax.always(function(){
        	app.VIEW.rep_ans.DOM.is_fail_no_loading.hide();
        })
	},

	ajax_success: function(r)
	{
		// do nothing
		if( !r || !r.id ) {
			return;
		}

		// update radio
		app.VIEW[app.HASH].DOM.id_similar_yes.val(r.id);

		// prevent future ajax lookups
		app.VIEW[app.HASH].DOM.is_fail_no.addClass('ajax');

		// build html
		var html = this.get_similar_title(r)+this.get_similar_table(r);

	   // update dom
	   this.DOM.is_fail_no_similar.prepend(html).show();
	},

	get_similar_title: function(r)
	{
		return '<div id="ajax-veh">'+
			'<h3 id="ajax-veh-toggle">Known Defect</h3>'+
		   '<h5>Is this defect the same as the description below:</h5>'+
		'</div>';
	},

	get_similar_table: function(v)
	{
		var html = '',
			notes_monitored = ( v.notes_monitored ) ? v.notes_monitored : 'No current Monitored notes set';

		html += '<p class="similar-defect" data="'+v.id+'">'+
			'<b>Defect</b>: ' + notes_monitored+
		'</p>';

		return html;
	},

	ajax_error: function(r)
	{
		console.warn('ajax_error', r);
	},

	/**
	 * Open images that browser or webapp in new window for completed reports
	 */
	img_new_window: function()
	{
		app.CAMERA._setup_dom();
		app._('img_photo').not('.img-blank').each((_k, el) => {

			// get url
			var href = $(el).attr('src');

			// wrap image in <a>
			$(el).wrap('<a href="'+href+'" target="_blank"></a>');
		});
	},
	
	img_view_device: () => {
		app.CAMERA._setup_dom();
		app._('img_photo').not('.img-blank').each((_k, el) => {
			$(el).on('click', (_e) => {
				var href = `photo/${$(el).parent().data('tbl')}/${$(el).parent().attr('data')}/${$(el).attr('data')}`;
				app.redirect(href);
			});
		});
	},

	setup_events: function()
	{
		// page events for active reports only
		// colour report overview when is_fail = 1
		var failColour = ( app.DATA.REP_QUE.is_safety === '1' ) ? 'red' : false;

		var changeRadio = function(init)
		{
			// name of field to save
			var name = ( init !== true && $(this).attr('name') ) ? $(this).attr('name') : false;	
			
			// toggle answer container
			if( init === true || name === 'is_completed' ) {

				if( app.VIEW[app.HASH].DOM.is_completed_no.is(':checked') ) {

					if( init === true ) {
						app.VIEW[app.HASH].DOM.answer_container.hide();
					} else {
						app.VIEW[app.HASH].DOM.answer_container.fadeOut();
					}

				} else {

					if( init === true ) {
						app.VIEW[app.HASH].DOM.answer_container.show();
					} else {
						app.VIEW[app.HASH].DOM.answer_container.fadeIn();
					}
				}
			}

			if( (init === true || name === 'is_fail' ) && app.VIEW[app.HASH].DOM.is_fail_no.is(':checked') ){

				// check similar fails
				if( app.VIEW[app.HASH].DOM.is_fail_no.hasClass('ajax') === false ) {
					app.VIEW[app.HASH].ajax();
				} else {
					app.VIEW[app.HASH].DOM.is_fail_no_similar.show();
				}

			} else if( init !== true && name === 'is_fail' && app.VIEW[app.HASH].DOM.is_fail_yes.is(':checked') ) {

				// hide similar
				app.VIEW[app.HASH].DOM.is_fail_no_similar.hide();
			}

			// IS FAIL(safety)
			if( failColour && init !== true && name === 'is_fail' ) {
				if( app.VIEW[app.HASH].DOM.is_fail_no.is(':checked') ) {
					app.VIEW[app.HASH].DOM.report_overview.addClass('report-overview-'+failColour);
				} else {
					app.VIEW[app.HASH].DOM.report_overview.removeClass('report-overview-'+failColour);
				}
			}

			// record the is_completed if real question
			if( name && !app.CACHE.DEFAULT_QUESTIONS[app.URI[2]] ) {
				var val = ( app.VIEW[app.HASH].DOM[name+'_no'].is(':checked') ) ? app.VIEW[app.HASH].DOM[name+'_no'].val() : app.VIEW[app.HASH].DOM[name+'_yes'].val();
				app.VIEW[app.HASH].onChange(name, val, false, this);
			}
		};

		// check is completed
		this.DOM.radios.on('change', changeRadio);
		changeRadio(true);

		// FORM SETUP
		if( app.DATA.REP.hasOwnProperty('date_end') ) {

			// new window photos
			if( !app.PHONEGAP || app.WEBAPP ) {
				this.img_new_window();
			} else {
				this.img_view_device();
			}

		} else {

			this.setup_events_form();

			// change fields
			this.DOM.fields.on('change', function(){
				var field = ( app.URI[2] && app.CACHE.DEFAULT_QUESTIONS[app.URI[2]] ) ? app.URI[2] : $(this).attr('name');
				app.VIEW.rep_ans.onChange(field, this.value, false, this);
			});

			// setup next button
			this.check_btn_next();
		}

		// PHOTOS
		if( app.DATA.REP_QUE.hasOwnProperty('is_required_photo') && app.DATA.REP.hasOwnProperty('date_end') === false ) {			 
			app.CAMERA.setup(app.DATA.REP, 'date_end', 'rep_ans');
		}

		// CLICK PHOTO MSG
		this.DOM.msg_required.on('click', function(){

			var id = $(this).attr('id');

			if( id === 'msg-photo' ) {
				app.DOM.content.animate({ scrollTop: app.VIEW[app.HASH].DOM.photos_container.offset().top }, 2000);
			} else if( id === 'msg-notes' ) {
				app.VIEW.rep_ans.DOM.notes.focus().addClass('error');
			}
		});

		app.UPCOMING.view_cached();
	},

	check_btn_next: function()
	{
		// default answer
		var ans = false,
			que_typ = ( app.URI[2] && app.CACHE.DEFAULT_QUESTIONS[app.URI[2]] ) ? 'rep' : 'rep_ans',
			on  = 'button-green',
			off = 'button-border-grey',
			href = ( this.DOM.btn_next.attr('data-href') ) ? this.DOM.btn_next.attr('data-href') : this.DOM.btn_next.attr('href'),
			needs_answer,
			needs_photo,
			needs_notes,
			has_answer,
			has_notes,
			has_photo;

		// hide need photo by default
		this.DOM.msg_required.hide();

		let photoCount = 0;

		// setup answer
		if( que_typ === 'rep' ) {
			ans = app.TPL.get_row(que_typ, app.URI[1]);

		} else {
			ans = app.TPL.get_row(que_typ, app.URI[2]);

			// answer
			needs_answer = app.TPL.check_ans_needs('answer', app.DATA.REP_QUE, ans);
			has_answer = app.TPL.check_ans_has_answer(ans);

			// photo
			needs_photo = app.TPL.check_ans_needs('photo', app.DATA.REP_QUE, ans);
			has_photo = rep_ans.checkFiles(needs_photo);

			// notes
			needs_notes = app.TPL.check_ans_needs('notes', app.DATA.REP_QUE, ans);
			has_notes = ( this.DOM.notes.val() ) ? true : false;
		}

		if(
			( que_typ === 'rep' && ( ans.hasOwnProperty(app.URI[2]) === false || ans[app.URI[2]] === '' ) ) ||
			( que_typ === 'rep_ans' && ( ans.is_completed === '0' || ans.hasOwnProperty('is_fail') === false || ( needs_photo && has_photo === false ) || ( needs_notes && has_notes === false ) || ( needs_answer && has_answer === false ) ) )
		) {

			// msgs
			if( que_typ === 'rep_ans' ) {

				// notes
				if( needs_notes && has_notes === false ) {
					this.DOM.msg_notes.show();
				}

				// answer
				if( needs_answer && has_answer === false ) {
					this.DOM.msg_answer.show();
				}
			}

			// disable next
			this.DOM.btn_next.removeClass(on).addClass(off).attr('data-href', href).removeAttr('href');
			if(que_typ === 'rep_ans') {
				app.FORM.save_offline('rep_ans', 'edit', {is_valid: false}, true, app.URI[2], false);
			}
		} else {

			// enable next
			this.DOM.btn_next.removeClass(off).addClass(on).attr('href', href).removeAttr('data-href');
			if(que_typ === 'rep_ans') {
				app.FORM.save_offline('rep_ans', 'edit', {is_valid: true}, true, app.URI[2], false);
			}
		}
	},

	setup_events_form: function()
	{
		this.DOM.form.on('submit', function(e,d){
			e.preventDefault();
			$('input').blur();
		});

		this.DOM.form.validate({
			'ignore': [],
			'submitHandler': function(){},
			'errorPlacement': function(){},
			'highlight': function(element) {
			    $(element).addClass('error');
			    $(element).parent().addClass('error');
			},
			'unhighlight': function(element) {
			    $(element).removeClass('error');
			    $(element).parent().removeClass('error');
			}
		});

		// RANGE
		if( this.DOM.range.length > 0 ) {
			app.FORM.setup_range();
		}

		if( this.DOM.height.length > 0 ){
			this.setup_height();
		}

		// hack fix: android only
		this.hack_fix_textarea();

		// autoformat to locale
		this.DOM.form_number.on('keyup blur', function(e){

			if( this.value ) {
				var n = parseInt(
					$(e.currentTarget).val()
						.replace(/e/g, '')
						.replace(/[^0-9]/g,'')
						.replace(/(\.)/, '')
					,10),
					val = n.toString();

				if( val === 'NaN' ) {
					val = '';
				}

				if($(this).val() !== val || e.keyCode === 190 || e.keyCode === 110) {
					$(this).val(val);

					if(e.keyCode === 190 || e.keyCode === 110) {
						this.type = "text";
						this.setSelectionRange(val.length, val.length, "forward");
						this.type = "number";
					}
				}
			}
		});
		
		this.DOM.form_decimal.on('keyup blur', (e) => {
			
			if(e.currentTarget.value) {
				let number = parseFloat(e.currentTarget.value);
				
				if(isNaN(number)) {
					$(e.currentTarget).val('');
				}
				
				const numberSplit = e.currentTarget.value.split('.');
				if(numberSplit.length < 2) {
					return;
				}
				
				if(number % 1 === 0 || parseInt(numberSplit[1]) === 0) {
					$(e.currentTarget).val(numberSplit[0]);
					return;
				}
				
				$(e.currentTarget).val([numberSplit[0], numberSplit[1].slice(0, 1)].join('.'));
			}
		});
	},

	setup_height: function()
	{
		var $feet = this.DOM.height.filter('#height_feet'),
			$inches = this.DOM.height.filter('#height_inches'),
			$metres = this.DOM.height.filter('#height_metres');

		this.DOM.height.on('keyup', function(){

			var metres = '',
				feet = '',
				inches = '',
				val = ( $.isNumeric(this.value) ) ? this.value : false;

			if( this.id === 'height_metres' ) {

				if( $.isNumeric(val) ) {

					var QTYMetres = Qty(val+'m'),
						inchesTotal = Math.round((parseFloat(QTYMetres.format('in').replace(' in',''))));

					feet = parseInt(QTYMetres.format('ft').replace(' ft',''));
					inches = (inchesTotal - (feet * 12)).toFixed(1).replace('.0','');

					// recalculate if inches >= foot
					if( inches >= 12 ) {
						feet++;
						inches = 0;
					}
				}

				// update values
				$feet.val(feet);
				$inches.val(inches);
				metres = parseFloat(this.value);

			} else if( this.id === 'height_feet' || this.id === 'height_inches' ) {

				if( $.isNumeric($feet.val()) || $.isNumeric($inches.val()) ) {

					var feetVal = ( $.isNumeric($feet.val()) ) ? parseFloat($feet.val()) : 0,
						inchVal = ( $.isNumeric($inches.val()) ) ? parseFloat($inches.val()) : 0;

					inches = ( $.isNumeric(inchVal) ) ? (parseInt(feetVal) * 12) : 0;
					inches = inches + inchVal
					metres = Qty(inches+' in').toPrec('0.01 m').format('m').replace(' m','');

					if( metres ) {
						metres = parseFloat(metres).toFixed(2);
					}
				}

				// update value
				$metres.val(metres);
				app.VIEW.rep_ans.onChange('height', metres, false, $metres[0]);
			}

			if( metres >= 3 ) {
				app._('height_visibility_container').show();
			} else {
				app._('height_visibility_container').hide();
			}

		}).eq(0).trigger('keyup');

		// prevent keys being pressed (e, . - =)
		$feet.on('keydown', function(e){
			if( e.keyCode === 69 || e.keyCode === 110 || e.keyCode === 187 || e.keyCode === 189 || e.keyCode === 190 ){
				return false;
			}
		});

		// save visibility value
		app._('height_visibility').on('change', function(){
			app.VIEW.rep_ans.onChange('height_visibility', this.value, false, this);
		});
	},

	/**
	 * Overwrite height if empty values from feet/inches conversion
	 */
	setup_height_metres_change: function(el)
	{
		if( el.value === '0.00' || el.value === '0' ) {
			$(el).val('').trigger('change');
		}
	},

	/**
	 * Fix for android some reason virtual keyboard hiding hides content of form
	 * 
	 * Attempt to increase form height by 1px everytime blur event triggered
	 */
	hack_fix_textarea: function()
	{
		// do nothing
		if( app.CACHE.DEVICE.platform !== 'android' ) {
			return;
		}
	},

	/**
	 * Get first unanswered answer
	 */
	_get_rep_ans_id: function()
	{
		var rep_ans_id = false;

		// get answers from report
		$.each(app.CACHE.REP_ANS, function(k,v){
			if( v.report_id === app.URI[1] && v.hasOwnProperty('is_completed') === false ) {
				rep_ans_id = v.ts;
				return false;
			}
		});

		return rep_ans_id;
	},

	/**
	 * Percentage range slider
	 */
	onSlideEnd: function(val)
	{
		this.onChange(app.URI[2], val, true);
	},

	getAnswerMulti: function()
	{
		var ans = [],
			hasValue = false;

		$.each(this.DOM.fields, function(){
			if( $(this).hasClass('answer') ) {

				if( this.value ) {
					hasValue = true;
				}

				// add answer
				ans.push(this.value);
			}
		});

		// join string together
		if( hasValue ) {
			return ans.join(',');
		}

		// empty value
		return '';
	},

	/**
	 * Save data for answer when something changes
	 */
	onChange: function(field, val, slider, el)
	{
		if( field === 'answer[]' ) {
			field = 'answer';
			val = this.getAnswerMulti();
		}

		var data = {};
		data[field] = val;
		// update report
		if( app.URI[2] && app.CACHE.DEFAULT_QUESTIONS[app.URI[2]] ) {

			app.FORM.save_offline('rep', 'edit', data, true, app.URI[1], false, function(){
				app.VIEW.rep_ans.check_btn_next();
			});

			if( field === 'height' ) {
				app.VIEW.rep_ans.setup_height_metres_change(el);
			}

		} else {

			// overwrite data value with slider val
			if( slider ) {
				data = {'answer': val};
			}

			// preclick no known defect
			if( field === 'is_fail' && val === '0' ){
				app.VIEW[app.HASH].DOM.id_similar_no.trigger('click');
			}

			app.FORM.save_offline('rep_ans', 'edit', data, true, app.URI[2], false, function(){
				app.VIEW.rep_ans.check_btn_next();
			});
		}
	},

	checkFiles: (needsFile) => {
		const ans = app.TPL.get_row('rep_ans', app.URI[2], 'ts');
		const que = app.TPL.get_row('rep_que', ans.report_question_id, 'id');
		if(!ans || que.is_required_photo === '0') {
			return;
		}

		let hasPhotos = false;
		let photoCount = 0;
		if(ans && que && que.is_required_photo) {
			Object.keys(ans).forEach((key) => {
				let shouldAdd;
				if(app.WEBAPP) {
					shouldAdd = rep_ans.checkWebappFiles(key, ans);
				} else {
					shouldAdd = rep_ans.checkAppFiles(key, ans);
				}

				if(!shouldAdd) {
					return;
				}

				photoCount++;
			});
			hasPhotos = photoCount >= parseInt(que.photo_quantity);
		}

		let isValid;
		if(!hasPhotos && needsFile) {
			isValid = false;
			if(ans && que && que.is_required_photo && !que.photo_quantity && photoCount > 0) {
				isValid = true;
				rep_ans.DOM.msg_photo.hide();
				rep_ans.DOM.msg_photo_x.hide();
			} else if(!isNaN(parseInt(app.DATA.REP_QUE.photo_quantity)) && photoCount !== app.DATA.REP_QUE.photo_quantity) {
				rep_ans.DOM.msg_photo_x.text(`${parseInt(app.DATA.REP_QUE.photo_quantity)} photo(s) are required before you can complete this check.`);
				rep_ans.DOM.msg_photo_x.show();
			} else {
				rep_ans.DOM.msg_photo.show();
			}
		} else {
			isValid = true;
			rep_ans.DOM.msg_photo.hide();
			rep_ans.DOM.msg_photo_x.hide();
		}

		return isValid;
	},

	checkWebappFiles: (key, ans) => {
		if(!key.startsWith('file') || (key.startsWith('file') && (key.endsWith('_needs_uploading') || key.endsWith('_local')))) {
			return false;
		}

		if(!ans[key] || ans[key].length === 0) {
			return false;
		}

		return true;
	},

	checkAppFiles: (key, ans) => {
		if(!key.startsWith('file') || !key.endsWith('_local')) {
			return false;
		}

		if(!ans[key] || ans[key].length === 0) {
			return false;
		}

		return true;
	},

	onCameraSave: function()
	{
		console.warn('onCameraSave');

		// check if next ready
		app.VIEW[app.HASH].check_btn_next();
	},

	onCameraDelete: () => {
		console.warn('onCameraDelete');

		// check if next ready
		app.VIEW[app.HASH].check_btn_next();
	}
};