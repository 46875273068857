import { PushNotifications } from "@capacitor/push-notifications";

export let PUSH = {

	checked: false,

	start: async () => {
		// Have if check to see if running on a device old projects have a variable called app.PHONEGAP example usage below
		if(!app.PHONEGAP) {
			console.log('push: not available');
			return;
		}

		if(app.PUSH.checked) {
			console.log('push: already checked');
			return;
		}

		/**
		 * We register the event listeners before registering the push notifications and showing the pop-up
		 */
		await PushNotifications.addListener('registration', (token) => {
			// This event handler is when the app subscribes to push notifications
			app.PUSH.ajax(token.value);

			// We should send this token to the API, so we can send push notifications from the server side but for this example it will just log the token
			console.log(`onTokenRefresh: ${token.value}`);
		});

		await PushNotifications.addListener('registrationError', (err) => {
			// This event handler is used to handle and catch errors when trying to subscribe the app to push notifications
			console.log(`ErrorRegisteringPushNotifs: ${err.error}`);
		});

		await PushNotifications.addListener('pushNotificationReceived', async (notification) => {
			// This event handler is used to handle when the app is open and it receives a push notification this is not fired if the app is closed or paused
			console.log(`Notification Received: ${JSON.stringify(notification, null, 4)}`);
		});

		await PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
			//This event handler is called when the push notification the devices notification tray is clicked
			if(!data) {
				return;
			}

			if( !data.additionalData.foreground && !data.additionalData.dismissed ) {
				app.redirect('#' + data.additionalData.page);
			}

			// Clear notifications if the device platform is not web
			if(platform !== 'web') {
				await PushNotifications.removeAllDeliveredNotifications();
			}
		});

		// This checks if we already have permission to send the device push notifications if we do we just register without bothering the user
		let alreadyAllowed = await PushNotifications.checkPermissions();
		if(alreadyAllowed.receive === "granted") {
			await PushNotifications.register();
			return;
		}

		// This will ask the user for consent and if they give the app consent it will register the app to receive push notifications
		let perms = await PushNotifications.requestPermissions();
		if(perms.receive === 'granted') {
			await PushNotifications.register();
		}
	},

	/**
	 * Save the recipient ID for either iOS or android
	 */
	ajax: function(token)
	{
		var data = {};
		data[app.CACHE.DEVICE.platform] = token;

		var ajax = $.ajax({
				data: app.get_api_data(data),
				dataType: 'json',
				type: 'POST',
				url: app.get_api_url('push/add')
			});

		// DONE
		ajax.done(function(json){

			console.log('AJAX: success');

			// DONE: WIN
			if( json.status === 'success' ) {

				// update user
				app.CACHE.USR[app.CACHE.DEVICE.platform] = token;
				app.cache_save('usr');

				// update checked status
				app.PUSH.checked = true;

			} else {
				console.log('ERROR PUSH', json);
			}
		});

		// FAIL
		ajax.fail(function(r){

			console.warn("push: ajax error", r);

			app.PUSH.checked = false;

			var save = false;

			if( app.CACHE.DEVICE.platform === 'android' && app.CACHE.USR.android ) {
				delete app.CACHE.USR.android;
				save = true;
			} else if( app.CACHE.DEVICE.platform === 'ios' && app.CACHE.USR.ios ) {
				delete app.CACHE.USR.ios;
				save = true;
			}

			// only save if we need to
			if( save ) {
				app.cache_save('usr');
			}
		});
	}
};