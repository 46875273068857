import {Geolocation} from "@capacitor/geolocation";
import {Capacitor} from "@capacitor/core";

export let GEO = {
    HAS_PERMISSION: false,

    /**
     * Checks status of geolocation to see if any is saved or feature is available
     */
    init: async function()
    {
        // check feature exists
        if( !Capacitor.isPluginAvailable('Geolocation') ) {
            console.log('GeoLocation plugin not found');
            return;
        }

        if(Capacitor.getPlatform() === 'web') {
            const perms = await navigator.permissions.query({ name: "geolocation" });
            GEO.HAS_PERMISSION = perms.state;
            perms.onchange = (e) => {
                GEO.HAS_PERMISSION = e.currentTarget.state;
            }
            return;
        }

        try {
            let hasPermission = await Geolocation.checkPermissions();
            if(hasPermission.location === 'granted' || hasPermission.coarseLocation === 'granted') {
                GEO.HAS_PERMISSION = true;
                return;
            }

            hasPermission = await Geolocation.requestPermissions({permissions: ['location', 'coarseLocation']});
            if(hasPermission.location === 'granted' || hasPermission.coarseLocation === 'granted') {
                GEO.HAS_PERMISSION = true;
            }

        } catch(err) {
            console.error(err);
        }
    },

    /**
     * Will fetch the devices geolocation data from the GPS
     * @return {Promise<any>}
     */
    getLocation: async () => {
        if(!GEO.HAS_PERMISSION) {
            throw new Error('GeoLocation permissions were rejected');
        }

        try {
            // GEO OPTIONS
            let options = {
                maximumAge: 3500,
                enableHighAccuracy: true
            };

            return await Geolocation.getCurrentPosition(options);
        } catch(err) {
            console.error(`Error when getting GeoLocation: ${err}`);
            throw err;
        }
    },

    start: async (page, opts) => {
        try {
            await GEO.init();
            // check last checkup within 15 minutes
            if( (!opts || !opts.force ) && app.GEO.check_lookup_ok() === false ) {
                console.warn('Lookup NOT OK');
                return;
            }

            console.log('Lookup OK');

            const pos = await GEO.getLocation();
            GEO.win(page, pos);
        } catch(err) {
            GEO.fail(page, err);
        }
    },

    /**
     * Can we do another lookup within x mins?
     */
    check_lookup_ok: function()
    {
        var mins = 3,
            recheck_seconds = mins*60,
            ts_valid_from = app.DATE.timestamp() - recheck_seconds;

        // if nothing exists its ok to try
        if( !app.CACHE.USR.settings.geo || app.CACHE.USR.settings.geo.enabled === false ) {
            return true;
        }

        // if geo expired ok to try
        var ts_geo = app.DATE.timestamp(app.CACHE.USR.settings.geo.date_added);

        console.log('geo', ts_geo);
        console.log('valid from', ts_valid_from);

        if( ts_geo <= ts_valid_from ) {
            return true;
        }

        return false;
    },

    has_expired: function(day)
    {
        // check any coords exist
        if( !app.CACHE.USR.settings.geo || app.CACHE.USR.settings.geo.enabled === false ) {
            console.log('GEO: none');
            return false;
        }

        // check expiry
        var day = 24 * 60 * 60,
            ts_valid_from = app.DATE.timestamp() - day,
            geo = app.DATE.timestamp(app.CACHE.USR.settings.geo.date_added)

        if( geo < ts_valid_from ) {
            console.log('GEO: Expired');
            return;
        }

        return app.CACHE.USR.settings.geo;
    },

    win: function(page, r)
    {
        console.log('# GEO SUCCESS #', r);

        var data = {
            enabled: true,
            date_added: app.DATE.format('datetime'),
            lat: r.coords.latitude.toFixed(8),
            lng: r.coords.longitude.toFixed(8),
        };

        app.CACHE.USR.settings.geo = data;
        app.cache_save('usr');

        // send data to callback function if exists
        if( app.VIEW[page].geo_win ) {
            app.VIEW[page].geo_win(data);
        }
    },

    fail: function(page, r)
    {
        console.warn('ERROR: GEO: for #' +page);
        console.log(r.code, r.message);

        app.CACHE.USR.settings.geo = {
            date_added: app.DATE.format('datetime'),
            enabled: false
        };
        app.cache_save('usr');
    },
}