import { Directory } from "@capacitor/filesystem";

export let FILE_TRANSFER = {

    getFileToUpload: async (path, fileType, filename) => {
        const data = await FILE_TRANSFER.readAsBase64(path, fileType);
        return new File([data], `${filename}`, { type: `image/${fileType}` });
    },

    /**
     *
     * @param path
     * @param fileType
     * @return {Promise<Blob>}
     */
    readAsBase64: async (path, fileType) => {
        try {
            const imageData = await app.FILE.readFile({
                path: path,
            });

            return FILE_TRANSFER.base64toBlob(imageData.data, `image/${fileType}`);
        } catch(err) {
            console.error(`Error when reading image as Base64: ${err}`);
            return null;
        }
    },

    base64toBlob: (base64Data, contentType) => {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            let bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    },

    /**
     * Used to convert Blob data to Base64 data encoding
     * @param {Blob} blob the blob object of the data to decode to Base64
     * @return {Promise<string>} will return the Base64 string of the blob data provided
     * @throws will throw if an error occurs with the file reader being used to convert the blob
     */
    blobToBase64: async (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = (err) => reject(err);
            reader.readAsDataURL(blob);
        });
    }
}