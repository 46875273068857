import * as Handlebars from 'handlebars';

export let rep_setup = {

	DOM: {},

	/**
	 * Check to see item exists
	 */
	get_redirect: function()
	{
		// check for redirect needed
		if( app.DATA.REP_VEHICLE === false || ( app.URI[2] && app.DATA.REP_TRAILER === false ) ) {
			return 'home';
		}

		return false;
	},

	init: function()
	{
		var redirect = this.get_redirect();

		if( redirect ) {
			app.redirect(redirect);
			return;
		}

		// DOM
		this.setup_dom();

		// EVENTS
		this.setup_events();
	},

	setup_dom: function()
	{
		// radios
		this.DOM.has_trailer_yes = app.DOM.content_load.find('#has_trailer_yes');

		// buttons
		this.DOM.btn_back = app.DOM.content_load.find('#btn-back');
		this.DOM.btn_next = app.DOM.content_load.find('.btn-next');
		this.DOM.ajax_msg = app.DOM.content_load.find('.msg');
		this.DOM.btn_ajax = app.DOM.content_load.find('.btn-ajax');
		this.DOM.btn_ajax_loading = app.DOM.content_load.find('img#loading');

		// warning
		this.DOM.report_warning = app.DOM.content_load.find('#report-warning');
		this.DOM.report_warning_trailer = app.DOM.content_load.find('#report-warning-trailer');
	},

	setup_events: function()
	{
		// allow next button
		if( app.CACHE.USR.operator_rep_after_fail !== '0' ) {
			app._('btn_next').addClass('button-green').removeClass('button-grey');
		}

		// check asset state
		app.check_veh_is_suspended('rep', app.HASH, app.DATA.REP_VEHICLE.vehicle_id, false, app.DATA.REP_VEHICLE.date_end);
		
		// check trailer state
		if( app.URI[2] ){
			app.check_veh_is_suspended('rep', app.HASH, app.DATA.REP_TRAILER.vehicle_id, true, app.DATA.REP_TRAILER.date_end);
		}

		// BACK
		this.DOM.btn_back.on('click', this.evt_btn_back);

		// NEXT
		this.DOM.btn_next.on('click', this.evt_btn_next);

		// AJAX
		this.DOM.btn_ajax.on('click', this.evt_btn_ajax);
	},

	evt_btn_ajax: function()
	{
		// do nothing
		if( $(this).hasClass('button-grey') ) {
			return;
		}

		// change button state
		$(this).addClass('button-grey').removeClass('button-green');

		// hide loading
		app._('btn_ajax_loading').show();
		app._('ajax_msg').hide();

		var trailer = ( $(this).attr('data') === 'trailer' ) ? true : false,
			vehicle = ( trailer ) ? 'REP_TRAILER' : 'REP_VEHICLE';

		app.check_veh_is_suspended('rep', app.HASH, app.DATA[vehicle].vehicle_id, trailer);
	},

	/**
	 * Also check if they can submit a new Daily Check
	 */
	veh_is_suspended_callback: function(json, trailer)
	{
		var $btnNext = app._('btn_next'),
			$loading = app._('btn_ajax_loading'),
			$msg = app._('ajax_msg'),
			asset = ( trailer ) ? 'Trailer' : 'Asset',
			// count safety fails short reference
			cSF = ( json && json.data ) ? json.data.safety_fails : '0',
			grammar = Handlebars.helpers.grammar(cSF);


		if( $btnNext.length === 2 ) {
			$btnNext = ( trailer ) ? $btnNext.last() : $btnNext.first();
			$loading = ( trailer ) ? $loading.last() : $loading.first();
			$msg = ( trailer ) ? $msg.last() : $msg.first();
		}

		// change btn state
		$btnNext.removeClass('button-grey').addClass('button-green');

		// do nothing
		if( app.CACHE.USR.operator_rep_after_fail !== '0' || !json || cSF === '0' ) {

			if( trailer ) {
				app._('report_warning_trailer').hide();
			} else {
				if(app._('report_warning')) {
					app._('report_warning').hide();
				}
			}

			$btnNext.addClass('button-green').removeClass('button-grey');
			return;
		}

		$btnNext.addClass('button-grey').removeClass('button-green');

		// hide loading
		$loading.hide();

		// update message
		$msg.show().html(`There ${grammar} ${cSF} outstanding safety fail ${Handlebars.helpers.plural(cSF,'defect')} on this ${asset}, please contact your supervisor.<br>Once these defects are cleared you start your ${asset} report.`).addClass('red');
	},

	evt_btn_back:function()
	{
		app.show_confirm("Are you sure you don't want to start report on "+$(this).attr('data')+"?", "Cancel Report", function(r){
			if( r === true ) {
				// delete report
				app.FORM.save_offline('rep','delete', { 'ts': app.URI[1]}, true);

				// redirect user
				app.redirect('rep_start');
			}
		});
	},

	evt_btn_next: function()
	{
		// do nothing
		if( $(this).hasClass('button-grey') ) {
			return;
		}

		// vehicle or trailer?
		var type = $(this).attr('data-type'),
			type_uri = ( type === 'vehicle' || type === undefined ) ? app.URI[1] : app.URI[2];


		// default redirect
		var redirect = 'rep_redirect/'+type_uri,
			start = true;

		// report started?
		if( app.DATA.REP_VEHICLE && app.DATA.REP_VEHICLE.hasOwnProperty('date_start') && app.DATA.REP_TRAILER && app.DATA.REP_TRAILER.hasOwnProperty('date_start')  ) {

			// do nothing

		} else if( app.DATA.REP_TRAILER && app.DATA.REP_TRAILER.hasOwnProperty('date_start') === false ) {

			// check if already been started for trailer?
			start = app.REPORT.start($(this).attr('data-trailer'), app.URI[2], app.URI[1]);

		} else if( app.DATA.REP_VEHICLE.hasOwnProperty('date_start') === false ) {

			// check if already been started?
			start = app.REPORT.start($(this).attr('data'), app.URI[1]);
		}

		// REDIRECT
		// if trailer not attached and selected
		if( app.URI.length === 2 && app.VIEW.rep_setup.DOM.has_trailer_yes.is(':checked') && (app.DATA.REP_VEHICLE.hasOwnProperty('date_start') === false || app.DATA.REP_VEHICLE.hasOwnProperty('report_id_related') === false) ) {
			redirect ='rep_start/trailer/'+app.URI[1];
		}

		// DELETE REPORT
		if( start === false ){

			// delete report
			app.REPORT.delete(app.URI[1]);

			redirect = 'rep_start';
		}
		 
		// redirect user
		app.redirect(redirect);
	}
};