export let mai_start = {

	TYPES: ['reg', 'serial'],

	DOM: {},

	init: function()
	{

		app.GEO.start(app.HASH);

		// reset reference
		for(let i = 0; i < this.TYPES.length; i++){
			this['awesomplete_'+this.TYPES[i]] = false;
		}

		// DOM
		this.setup_dom();

		// EVENTS
		this.setup_events();
	},

	setup_dom: function()
	{
		this.DOM.form  = app.DOM.content_load.find('#form');
		this.DOM.bottom_container = app.DOM.content_load.find('#bottom-container');

		// autocomplete
		this.DOM.autocomplete_input = app.DOM.content_load.find('.autocomplete-input');
		this.DOM.autocomplete = app.DOM.content_load.find('.autocomplete-list');

		// buttons
		this.DOM.btn_next = app.DOM.content_load.find('#btn-next');
	},

	setup_events: function()
	{
		// SETUP FOOTER
		app.setup_footer([
			{btn:'left', txt: 'Back', href: 'home'}
		]);

		app.setup_autocomplete();

		// prevent form submission
		this.DOM.form.on('submit', function(e){
			e.preventDefault();
		});

		// clear focus
		this.DOM.autocomplete_input.on('blur', function(){
			$(this).val('');
		});

		this.setup_focus();
	},

	setup_focus: function()
	{
		this.DOM.autocomplete_input.on('focus', function(){
			app.VIEW[app.HASH].DOM.bottom_container.hide();
		});

		this.DOM.autocomplete_input.on('blur', function(){
			app.VIEW[app.HASH].DOM.bottom_container.show();
		});
	},

	selectAutocomplete: function(e)
	{
		if( app.HASH !== 'mai_start' ) {
			return;
		}

		var ts = false,
			vehicle_id = ( $.isNumeric(e) ) ? e : $(e.srcElement).val();

		// reset input
		if( $.isNumeric(e) === false ) {
			$(e.srcElement).val('');
		}

		// get vehicle record to check for suspension
		var veh = app.TPL.get_row('veh', vehicle_id);

		// add maintenance
		var ts = app.MAINTENANCE.add(vehicle_id);
		
		// only redirect if vehicle status OK
		if( ts ) {
			
			// redirect
			app.redirect('mai_setup/'+ts);
		}
	}
};