export let tco_drv_let_list = {

	DOM: {},

	SCROLL: false,

	init: function()
	{
		if( app.CACHE.USR.opr_priv_tacho !== '1'  ) {
			app.redirect('home');
			return;
		}

		// DOM
		this.setup_dom();
		
		// SETUP FOOTER
		app.setup_footer([
			{btn:'left', txt: 'Back', href: 'home'}
		]);

		this.ajax();
	},

	setup_dom: function()
	{
		this.DOM.table = app.DOM.content.find('table').addClass('paging');
	},

	/**
	 * Get daily checks from api
	 */
	ajax: function(pageSelected)
	{
		let pageIsSet = ( pageSelected ) ? true : false;
		
		// set defaults
		pageSelected = pageSelected || 1;

        var ajax = $.ajax({
            url: app.get_api_url('tco_drv_let'),
            data: app.get_api_data({page: pageSelected}),
            method: 'POST'
        });

		// change loading state
		_('table').addClass('loading');

        ajax.done(function(r){

            // success
            if( r && r.status === 'success' && app.HASH === 'tco_drv_let_list' && r.data.letters ) {

				app.AJAX.paging('tco_drv_let_list', r.data.paging, pageSelected, pageIsSet);
				
                app.VIEW.tco_drv_let_list.ajax_success(r.data.letters);
            } else {
            	app.AJAX.paging('tco_drv_let_list', false, 1);
            }
        });

        ajax.fail(function(){
        	app.AJAX.paging('tco_drv_let_list', false, 1);
        });
	},
	
	ajax_success: function(letters)
	{
		let html = '';
		
		$.each(letters, (k,v) => {
			html += `<tr data="${k}">
			<td ><a href="#tco_drv_let/${v.id}" class="${v.status_colour}">${v.status}</a></td>
			<td>${v.infringements}</td>
			<td>${v.date_range_formatted.replace(/ *\([^)]*\) */g, "").replace('- ',' - ')}</td>
			</tr>`;
		});

		_('table').find('tr.paging').last().after(html);
	}
};