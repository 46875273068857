export let ROUTES = {
    // logged out
    login: { view: true, secure: false, header: false},
    logout: { view: true, header: true},
    timeout: { view: true, header: true, secure: true},

    pin_forgot: { view: true, secure: false, header: false},
    pin_reset: { view: true, secure: false, header: false},
    // logged in
    pin_first: { view: true, secure: true, header: false},
    pin_change: { view: true, secure: true, header: false},
    home: { view: true, secure: true, header: true},
    home_loading: { view: true, secure: true, header: true},
    archive: { view: false, secure: true, header: true},
    success: { view: false, secure: true, header: true},
    photo: { view: true, secure: true, header: true},
    // logged in: vehicles
    veh_def_form:  { view: true, secure: true, header: true},
    veh_def_list:  { view: true, secure: true, header: true},
    veh_def_add:  { view: true, secure: true, header: true},
    // accidents
    acc_start:  { view: true, secure: true, header: true},
    acc_list:  { view: true, secure: true, header: true},
    acc_form:  { view: true, secure: true, header: true},
    veh_form: { view: true, secure: true, header: true},
    // daily checks
    rep_start: { view: true, secure: true, header: true},
    rep_setup: { view: true, secure: true, header: true},
    rep_redirect: { view: true, secure: true, header: true},
    rep_ans: { view: true, secure: true, header: true},
    rep: { view: true, secure: true, header: true},
    rep_success: { view: true, secure: true, header: true},
    rep_list: { view: true, secure: true, header: true},
    rep_list_other: { view: true, secure: true, header: true},
    // inspections
    mai_start: { view: true, secure: true, header: true},
    mai_setup: { view: true, secure: true, header: true},
    mai: { view: true, secure: true, header: true},
    mai_success: { view: true, secure: true, header: true},
    mai_list: { view: true, secure: true, header: true},
    mai_list_other: { view: true, secure: true, header: true},

    // fuel / expenses
    fue_exp_start: { view: true, secure: true, header: true},
    fue_exp: { view: true, secure: true, header: true},
    fue_exp_list: { view: true, secure: true, header: true},

    // logged in: messages
    msg_list: {view: true, secure: true, header:true},
    msg: {view: true, secure: true, header:true},

    // misc
    faq: { view: true, header: true},
    admin: { view: true, header: true},
    errors: { view: false },
    error: { view: false, template: 'error'},
    // tacho
    tco_drv_let_list: {view: true, secure: true, header:true},
    tco_drv_let: {view: true, secure: true, header:true},
    
    // vehicle defect list
    defect_view: { view: true, secure: true, header: true },
    defect_edit: { view: true, secure: true, header: true },
    live_defects: { view: true, secure: true, header: true },
    live_defects_list: { view: true, secure: true, header: true },
};