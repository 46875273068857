export let VEHICLES = {

    getAxleCount: function(veh)
    {
        // no axles
        if( !veh || !veh.axle ) {
            return 0;
        }

        // fix for when axles used to be arrays
        if( veh && $.isArray(veh.axle) ) {
            return veh.axle.length;
        }

        // axles should be integer
        if( veh && $.isNumeric(veh.axle) ) {
            return veh.axle;
        }

        // fuk knows
        return 0;
    },
	
};