/**
 * Vehicles upcoming dates and services for Daily Checks and Inspections
 * 
 * Display on searching for Asset (#rep_setup or #mai_setup)
 * OR 
 * Display in #rep_ans or #mai
 */
export let UPCOMING = {

    /**
    * Paint upcoming service & dates tables
    */
    paint: function (vehicle_id, $el, json)
    {
        // do nothing no data or app version too old
        if ( json !== false && (json.hasOwnProperty('dates') === false || (!json.dates.length && !json.services.length) ) ) {
            return;
        }

        if( json === false ) {

            json = app.CACHE.VEH_UPCOMING[vehicle_id];

        } else {
            // save data for later user
            app.CACHE.VEH_UPCOMING[vehicle_id] = {
                dates: json.dates,
                services: json.services,
            };
        }

        let services = '',
            servicesHTML = '',
            dates = '',
            datesHTML = '';

        // DATES
        if ( json && json.dates.length) {
            $.each(json.dates, function (k, v) {
                var name = v.name.replace('Expiry', '').replace('Due', '');
                dates += `<tr>
                   <td width="40%" class="al"><i class="fa fa-circle ${v.colour}"></i> ${name}</td>
                   <td width="60%" class="ar ${v.colour}">(${v.formatted}) ${v.value}</td>
               </tr>
               `;
            });

            datesHTML = `<table>
               <thead>
                   <tr>
                       <th colspan="3">Important dates</th>
                   </tr>
               </thead>
               <tbody>
                   ${dates}
               </tbody>
           </table>`;
        }

        // SERVICES
        if ( json && json.services.length) {
            $.each(json.services, function (k, v) {
                services += `<tr>
                   <td width="40%" class="al"><i class="fa fa-circle ${v.colour}"></i> ${v.name}</td>
                   <td width="60%" class="ar ${v.colour}">${v.formatted}</td>
               </tr>`;
            });

            servicesHTML = `<table>
               <thead>
                   <tr>
                       <th colspan="3">Service info</th>
                   </tr>
               </thead>
               <tbody>
                   ${services}
               </tbody>
           </table>`;
        }

        // update dom
        $el.html(datesHTML + servicesHTML).show();
    },

    view_cached: function () {
        app.DOM.content_load.find('#btn-upcoming').on('click', function () {

            var $el = app.DOM.content_load.find('.upcoming-container');

            if( !$el.hasClass('painted') ) {
                // paint dom table
                app.UPCOMING.paint($(this).attr('data'), $el, false);

                // change state
                $el.addClass('painted');
            } else {
                $el.toggle();
            }
        });
    }
};