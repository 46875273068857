export let veh_def_list = {

	DOM: {},

	init: function()
	{
		// DOM
		this.setup_dom();

		// EVENTS
		this.setup_events();

		// DELETE UNWANTED VEH_DEF
		this.check_unwanted_veh_def();
	},

	setup_dom: function()
	{
		this.DOM.table = app.DOM.content.find('table');
		this.DOM.table_thead = this.DOM.table.find('thead');
		this.DOM.table_tr = this.DOM.table.find('tbody tr');
		this.DOM.table_tr_loading = this.DOM.table_tr.filter('#loading');
	},

	setup_events: function()
	{
		this.ajax();
	},

	/**
	 * Get standalone/inservice defects from online instead of offline
	 */
	ajax: function()
	{
        var hash = app.HASH;

        var ajax = $.ajax({
            url: app.get_api_url('veh_def/get'),
            data: app.get_api_data({
            	is_resolved: ( app.URI[1] === 'resolved') ? '1' : '0'
            }),
            method: 'POST'
        });

        ajax.done(function(r){

            // success
            if( r && r.status === 'success' && app.HASH === hash ) {
                app.VIEW[hash].ajax_success(r.data);
            } else {
            	app.VIEW[hash].ajax_error();
            }
        });

        ajax.fail(function(){
        	app.VIEW[hash].ajax_error();
        });
	},

	get_unsynced: function(r)
	{
		var rUnsynced = app.TPL._get('veh_def', 'date_added', 'desc').filter(function(r){

			// only return unsyced items that are correct resolved state
            if(
        		r.ts &
        		r.hasOwnProperty('saved') && (
            		(app.URI[1] === 'resolved' && r.is_resolved === '1') ||
            		(!app.URI[1] && (!r.is_resolved || r.is_resolved === '0') )
            	)
        	 ){

                // setup vehicle-report
                var veh = app.TPL.get_row('veh', r.vehicle_id);

                // count recent vehicles
                if( veh ) {

                    // get vehicle type
                    var veh_typ = app.TPL.get_row('veh_typ', veh.vehicle_type_id);

                    if( veh_typ ) {
                        r.id_name = ( veh.reg ) ? veh.reg : veh.serial;
                        r.id_name_type = ( veh.reg ) ? 'id_reg' : 'id_serial';
                    }
                 }

                return r;
            }
        });

		// do nothing further
        if( rUnsynced.length === 0 ) {
        	return r;
        }

        return rUnsynced.concat(r);
	},

	ajax_success: function(r)
	{ 
		// do nothing
		if( $.isArray(r) === false ){
			return;
		}

		// merge unsynced
		r = this.get_unsynced(r);

		if( r.length === 0 ) {

			var txt = ( app.URI[1] === 'resolved' ) ? 'Resolved' : 'Unresolved';

			this.DOM.table_tr_loading.find('td').html('There are currently no '+txt+' Defects');
			return;
		}

		// hide loading
		this.DOM.table_tr_loading.hide();

		var html = '';

		$.each(r, function(k,v){

			var synced   = ( v.id ) ? 'fa-check green' : 'fa-times red',
				resolved = ( v.is_resolved === '1' ) ? 'fa-check green' : 'fa-wrench red';

			html += '<tr class="needsclick" data-href="'+Handlebars.helpers.getID(v)+'">'+
	                '<td><span class="'+v.id_name_type+' '+v.id_name_type+'_small">'+v.id_name+'</span></td>'+
	                '<td class="al"><b>'+v.notes+'</b></td>'+
	                '<td>'+Handlebars.helpers.date(v.date_added, 'date_short')+'</td>'+
	                '<td><i class="fa '+resolved+'"></i></td>'+
	                '<td><i class="fa '+synced+'"></i></td>'+
				'</tr>';
		});

		this.DOM.table_thead.show();
		this.DOM.table_tr.after(html);

		// update dom
		this.evt_click_row();
	},

	ajax_error: function()
	{
		this.DOM.table_tr_loading.find('td').html('There was a problem loading defects, please try again shortly.');
	},

	evt_click_row: function()
	{
		// View existing defect
		this.DOM.table.find('tr').on('click', function(){
			app.redirect('veh_def_form/'+$(this).attr('data-href'));
		});
	},

	/**
	 * Remove unwanted in-service defects
	 */
	check_unwanted_veh_def: function()
	{
		$.each(app.CACHE.VEH_DEF, function(k,v){
			if( typeof v === "object" && v.ts && v.saved === false ) {
				app.FORM.save('veh_def', 'delete', {}, {}, true, v.ts);
			} else if( v.id ) {
				app.FORM.save('veh_def', 'delete', {}, {}, true, v.id);
			}
 		});
	}
};