export let tco_drv_let = {

	DOM: {},

	SCROLL: false,

	init: function()
	{
		if( app.CACHE.USR.opr_priv_tacho !== '1' || $.isNumeric(app.URI[1]) === false ) {
			app.redirect('home');
			return;
		}
		
		// SETUP FOOTER
		app.setup_footer([
			{btn:'left', txt: 'Back', href: 'tco_drv_let_list'}
		]);

		this.setup_dom();

		// start lookup for letter
		this.ajax();

		// relookup button
		app._('btn_refresh').on('click', () => {
			app.VIEW.tco_drv_let.ajax(true);
		});
	},

	ajax: function(refreshed)
	{
		if( refreshed ) {
			app.VIEW.tco_drv_let.show_loading();
		}

		var ajax = $.ajax({
            url: app.get_api_url('tco_drv_let/'+app.URI[1]),
            data: app.get_api_data(),
            method: 'POST'
        });

		ajax.done( r => {
			if( r.status === 'success' ) {
				app.VIEW.tco_drv_let.show_letter(r.data.letter_html, r.data.letter);
			} else {
				app.VIEW.tco_drv_let.show_refresh();
			}
		});
		
		ajax.fail(err => {
			app.VIEW.tco_drv_let.show_refresh();
		});
	},

	show_loading: function()
	{
		app._('refresh').hide();
		app._('loading').show();
	},
	
	show_refresh: function()
	{
		app._('refresh').show();
		app._('loading').hide();
	},

	show_letter: function(html, letter)
	{
		app._('loading').hide();
		app._('refresh').hide();
		app._('letter').html(html);

		if( letter.sent === 'app' && !letter.driver_acknowledged ) {

			app.DOM.content_load.find('.report-overview').addClass('report-overview-orange').append('<p>You have to acknowledge this Tachograph Infringement Letter, please read all the infringements and acknowledge the letter at the bottom of the page.</p');

			app.FORM.start({
				formId: 'letter',
				hideConfirmation: true,
				ignore: ':hidden',
				msg_insert: false
			});
		} else if( letter.driver_acknowledged || letter.supervisor_acknowledged ) {
			app.DOM.content_load.find('.report-overview').addClass('report-overview-green').find('p').text('Infringement Letter Acknowledged');
		}		
	},

	setup_dom: function()
	{
		app._('loading', app.DOM.content_load.find('#loading'));
		app._('letter', app.DOM.content_load.find('#letter'));
		app._('refresh', app.DOM.content_load.find('#refresh'));
		app._('btn_refresh', app._('refresh').find('.button'));
	},

	form_online: function(json, post)
	{
		if( json.status === 'success' ) {

			app.show_alert(json.msg, 'Success');

			app.FORM.save_offline('tco_drv_let', 'delete', {}, true, app.URI[1], false, function(){
				
				// redirect
				app.redirect();

			}, true);
			
		}
	},

	form_online_error: function(json, post)
	{
		console.log('form_online_error', json, post);
		

	}
};