export let admin = {

	DOM: {},
	SETTINGS: {},

	init: function()
	{
		// start the form
		app.FORM.start({
            pin: true,
            save_show: true
        });

        // enable DOM
		this.setup_dom();

        // setup PIN  form
        this.setup_events();
    },

	/**
     * Setup pin DOM elements
     */
    setup_dom: function()
    {
        // options
        this.DOM.options = this.DOM.form.find('#options');
        this.DOM.msg_export = this.DOM.options.find('#msg-export');
        this.DOM.btn_export = this.DOM.options.find('#btn-export');
        this.DOM.btn_export_loading = this.DOM.options.find('#btn-export-loading');
         
        // inputs
        this.DOM.phrase_container = this.DOM.form.find('#phrase-container');
        this.DOM.phrase = this.DOM.phrase_container.find('#phrase');
        this.DOM.pin_container = this.DOM.form.find('#pin-container');
        this.DOM.pins = this.DOM.pin_container.find('input.pin');
        this.DOM.pin_all = this.DOM.pin_container.find('input#pin-all');
        this.DOM.show_pin = this.DOM.form.find('#show-pin');
    },

    setup_events: function()
    {
        this.setup_export();

        // show features by default
        /*if( app.DEBUG || app.CACHE.USR.settings.admin ) {
            this.show_admin_features();
        }*/
        
    },

    setup_export: function()
    {
        this.DOM.btn_export.on('click', function(){

            app.show_confirm("Are you sure you want to export your data?", 'Confirm Action', function(r){

                // do nothing
                if( r !== true ) {
                    return;
                }

                app.VIEW[app.HASH].DOM.btn_export.hide();
                app.VIEW[app.HASH].DOM.btn_export_loading.show();

                let data = app.get_api_data();

                data.data = JSON.stringify(app.CACHE);

                var ajax = $.ajax({
                    url: app.get_api_url('export'),
                    type: 'POST',
                    timeout: app.TIMEOUT_AJAX,
                    data: data
                });

                ajax.done(function(r){

                    if( r ) {

                        if( r.status === 'success' ) {
                            app.show_alert('Sucessfully exported data', 'Done');

                            app.VIEW[app.HASH].DOM.btn_export.hide();
                            app.VIEW[app.HASH].DOM.btn_export_loading.hide();
                        } else {

                            var msg = 'There were error(s)\n';

                            $.each(r.errors,function(k,v){
                                msg += "\n"+v.msg;
                            });

                            app.show_alert(msg, 'Attention');

                            app.VIEW[app.HASH].DOM.btn_export.show();
                            app.VIEW[app.HASH].DOM.btn_export_loading.hide();
                        }

                    } else {
                        app.VIEW[app.HASH].export_error();
                    }
                });

                ajax.fail(function(){
                    app.VIEW[app.HASH].export_error();
                });
            });
        });
    },

    export_error: function()
    {
        app.show_alert('There was a problem exporting your data, please try again', 'Attention');
        app.VIEW[app.HASH].DOM.btn_export.show();
        app.VIEW[app.HASH].DOM.btn_export_loading.hide();
    },

    form_offline: function(json, post)
    {
        // check pin is correct
        if( post.pin === '1867' && post.phrase === 'piper' ) {

            // set admin status
            app.CACHE.USR.settings.admin = true;

            // show features
            this.show_admin_features();

        } else {

            // error pin
            if( post.pin !== '1867' ) {
                app.VIEW.admin.DOM.pins.parent().addClass('error');
                app.VIEW.admin.DOM.pins.val('');
            }

            // error phrase
            if( phrase !== 'piper' ) {
                this.DOM.phrase_container.addClass('error');
            }
        }

        app.FORM.loading();
    },

    show_admin_features: function()
    {
        this.DOM.form_btn_submit.hide();
        this.DOM.form.find('fieldset').hide();
        this.DOM.options.show();

        this.DOM.show_pin.on('click', function(){

            if( $(this).hasClass('exposed') ) {
                return;
            }

            $(this).addClass('exposed');

            const pin = $(this).attr('data');

            $(this).text(pin);

        });
    },

    export_win: function()
    {
        this.DOM.msg_export.show();
    },

    form_on_error: function(errors)
    {
        // remove existing pin
        app.VIEW.admin.DOM.pins.val('').eq(0).focus();
    }
};