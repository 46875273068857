export let FUEL_EXPENSES = {

    add: vehicle_id =>
	{
		// clear search
		const data = {
			'user_id': app.CACHE.USR.id,
			'date_start': app.DATE.format('datetime'),
			'is_webapp': app.WEBAPP ? '1' : '0',
		};

        if( vehicle_id ) {
            data.vehicle_id = vehicle_id;

            // veh_typ.*records*
            const veh_typ = app.TPL.get_vehicle_type_from_vehicle_id(data.vehicle_id);
    
            if( veh_typ ) {
    
                // loop through fake questions and add to report if needed
                $.each(app.CACHE.DEFAULT_QUESTIONS, function(k,v){

                    if( k === 'height' || k === 'fuel' ) {
                        return true;
                    }
    
                    var key = 'is_'+k;
    
                    if( veh_typ[key] === '1' ) {
                        data[k] = '';
                    }
                });
            }
        }


		// check for any coordinates
		var geo = app.GEO.has_expired();
		if( geo ) {
			data.lat = geo.lat;
			data.lng = geo.lng;
            data.date_geo = geo.date_added;
		}

		// add new record
		return app.FORM.save_offline('fue_exp', 'add', data, true);
	},

    delete: function()
    {
        // SAVE: date_end + refresh
        app.show_confirm("Do you want to delete this entry?", "Confirm deletion", function(response){
            
            if( response !== true ) {
                return;
            }

            app._('btn_delete').hide().after('<img class="loading" src="img/loading-black.svg" width="20">');

            // delete
            app.FORM.save_offline('fue_exp', 'delete', {}, true, app.URI[1], false, () => {
                app.redirect('home');
            }, true);
           
        });
    },

    /**
     * @returns bool
     */
    check_ready: function(row)
    {
        const checkFuel = ( row.is_fuel !== '1' || (row.fuel && row.fuel_type && row.fuel_source)) ? true : false;
        const checkAdblue = ( row.is_adblue !== '1' || row.adblue) ? true : false;
        const checkCost = ( row.is_cost !== '1' || (row.cost && row.cost_type_id && row.cost_title)) ? true : false;

        // console.log(row);
        // console.log('###########');
        // console.log('checkFuel', checkFuel);
        // console.log('checkAdblue', checkAdblue);
        // console.log('checkCost', checkCost);

        return checkFuel && checkAdblue && checkCost;
    },

    /**
     * @returns bool
     */
    check_priv: function()
    {
        return (app.CACHE.USR.opr_priv_fuel_expenses === '1' && app.CACHE.USR.priv_fuel_expenses === '1');
    }
};