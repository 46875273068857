export let rep_list_other = {

	DOM: {},

	SCROLL: false,

	init: function()
	{
		if( app.CACHE.USR.priv_other_reports === '0' ) {
			app.redirect('home');
			return;
		}

		// DOM
		this.setup_dom();
		
		// SETUP FOOTER
		app.setup_footer([
			{btn:'left', txt: 'Back', href: 'home'},
			{btn:'right', txt: 'Add', href: 'rep_start'}
		]);

		this.ajax();
	},

	setup_dom: function()
	{
		this.DOM.table = app.DOM.content.find('table').addClass('paging');
	},

	/**
	 * Get daily checks from api
	 */
	ajax: function(pageSelected)
	{
		let pageIsSet = ( pageSelected ) ? true : false;
		
		// set defaults
		pageSelected = pageSelected || 1;

        var ajax = $.ajax({
            url: app.get_api_url('rep_other'),
            data: app.get_api_data({page: pageSelected}),
            method: 'POST'
        });

		// change loading state
		_('table').addClass('loading');

        ajax.done(function(r){

            // success
            if( r && r.status === 'success' && app.HASH === 'rep_list_other' && r.data.rep ) {

				app.AJAX.paging('rep_list_other', r.data.paging, pageSelected, pageIsSet);
				
                app.VIEW.rep_list_other.ajax_success(r.data.rep);
            } else {
            	app.AJAX.paging('rep_list_other', false, 1);
            }
        });

        ajax.fail(function(){
        	app.AJAX.paging('rep_list_other', false, 1);
        });
	},
	
	ajax_success: function(reports)
	{
		let html = '';
		
		$.each(reports, (k,v) => {
			html += `<tr data="${k}">
			<td>${Handlebars.helpers.regSerial(v, true, 'rep/'+v.id)}</td>
			<td>${v.vehicle_type_name}</td>
			<td>${Handlebars.helpers.date(v.date_end, 'datetime')}</td>
			</tr>`;
		});

		_('table').find('tr.paging').last().after(html);

		_('table').find('[href]').removeAttr('href').on('click', function(){
			var index = $(this).closest('tr').attr('data'),
			r = reports[index];
			app.CACHE.REP.push(r);
			app.cache_save('rep', false, false, function(){
				app.redirect('rep/'+r.id);
			});
		});
	}
};