export let home = {

	TOUCH: 'click',

	// calendar dates
	CAL_DATE_URI: false,
	CAL_START: '',
	CAL_START_TS: '',
	CAL_END: '',
	CAL_END_TS: '',

	DOM: {},

	init: function()
	{
		home.TOUCH = ( app.PHONEGAP && navigator.userAgent.match(/(iPod|iPad)/) ) ? 'touchstart' : 'click';
		// dom
		this.setup_dom();

		// events
		this.setup_events();

		// download files
		this.download();
	},

	setup_dom: function()
	{
		// logout
		this.DOM.btn_logout = app.DOM.content_load.find('a#btn-logout');
		this.DOM.percentage = app.DOM.content_load.find('#percentage');

		// sync
		this.DOM.box_sync = app.DOM.content_load.find('#box-sync');		 
		this.DOM.btn_sync = this.DOM.box_sync.find('a#btn-sync');
		this.DOM.msg_unsynced = this.DOM.box_sync.find('.msg-unsynced');
		this.DOM.msg_sync = this.DOM.box_sync.find('.msg');
		// msg_hom
		this.DOM.msg_hom = app.DOM.content_load.find('#msg_hom');
	},

	setup_events: function()
	{
		// check for PUSH notifications
		app.PUSH?.start().catch(() => console.warn('Something went wrong when trying to initialize Push Notifications'));

		// sync form
		app.FORM.start({save_show: true, 'prepend': '<p class="title">Confirm Your Account</p>'});

		var title1 = 'ATTENTION! Are you sure you want to logout?',
			msg1 = "If you logout, all local files will be removed!\n\n If you have used the app without an internet connection then your data has been stored locally on this device and will be cleared. Please ensure you have synced your data before continuing.",
			title2 = 'Are you absolutely sure you want to logout and clear local data?',
			msg2 = 'Tap OK to continue or cancel to abort.';

		// CLICK
		_('btn_logout').on('click', function(){

			app.show_confirm(msg1, title1, function(btn){

				// YES
				if( btn === true ) {
					app.show_confirm(msg2, title2, function(btn_confirm){
						if( btn_confirm === true ) {
							app.redirect('logout');
						}
					});
				}
			});
		});

		// reports
		// inspections
		this.clense_cache();

		// check percentage
		this.show_storage_percentage();

		// setup sync
		this.evt_setup_sync();

		// check if we need to sync
		this.evt_attempt_sync();

		// browser open
		if( _('msg_hom').length > 0 ){
			this.setup_msg_hom();
		}
		
		home.checkMessages();
	},
	
	checkMessages: () => {
		const outstandingMessages = home.checkOutstandingForceMessages();
		
		// check tacho letters
		if( !outstandingMessages && app.CACHE.USR.opr_priv_tacho === '1' ) {
			home.checkOutstandingLettersToAcknowledge();
		}
	},
	
	checkOutstandingLettersToAcknowledge: function()
	{
		const letterCount = app.TPL.get_tacho_letters();

		if( !letterCount ) {
			return;
		}

		const letters = app.CACHE.TCO_DRV_LET.filter(letter => {
			if( letter.sent === 'app' && !letter.driver_acknowledged && letter.is_force === '1' ) {
				return letter;
			}
		});

		const sentence = ( letters.length === 1 ) ? 'You have a Tacho Infringement Letter that you must acknowledge before you can continue to use the app' : 'You have Tacho Infringement Letters that you must acknowledge before you can continue to use the app';
        const grammar  = ( letters.length === 1 ) ? 'letter' : 'letters';
        const url = ( letters.length === 1 ) ? `tco_drv_let/${letters[0].id}` : 'tco_drv_let_list';
		const html =  `<p>
            <i class="fa fa-exclamation-triangle orange"></i>
            ${sentence}
        </p>
        <a href="#${url}" class="button button-orange button-small">View ${grammar}</a>`;
		
		app.DOM.content_load.find('#outstanding-force-messages').html(html).show();
		app.DOM.content_load.find('#unread-or-unaccept').hide();
		app.DOM.content_load.find('#btns-container a').not('#btn-logout').hide();
	},

	checkOutstandingForceMessages: function()
	{
		const msgs = app.CACHE.MSG_USR.filter( r => {
			if( r.is_force === '1' && r.hasOwnProperty('date_accept') === false ) {
				return r;
			}
		});

		if( !msgs.length ) {
			return false;
		}

    var sentence = ( msgs.length === 1 ) ? 'You have a message that you must acknowledge before you can continue to use the app' : 'You have messages that you must acknowledge before you can continue to use the app',
        grammar  = ( msgs.length === 1 ) ? 'message' : 'messages',
        url      = ( msgs.length === 1 ) ? `msg/${msgs[0].id}` : 'msg_list/unacknowledge',
		html =  `<p>
            <i class="fa fa-exclamation-triangle orange"></i>
            ${sentence}
        </p>
        <a href="#${url}" class="button button-primary button-small">View ${grammar}</a>`;
		
		app.DOM.content_load.find('#outstanding-force-messages').html(html).show();
		app.DOM.content_load.find('#unread-or-unaccept').hide();
		app.DOM.content_load.find('#btns-container a').not('#btn-logout').hide();

		return true;
	},

	setup_msg_hom: function()
	{
		// allow browser links
		app.browser_open();

		// close box
		_('msg_hom').find('.fa-times-circle').on('click', function(){
			// close box
			app._('msg_hom').hide();
			// mark box as closed for future
			app.MSG_HOM = true;
		});
	},

	form_online: function(r)
	{
		if( r.status === 'success' ) {

			if( r.data && r.data.usr ) {
				app.CACHE.USR.email = r.data.usr.email;
				app.CACHE.USR.pin_token = r.data.usr.pin_token;
				delete app.CACHE.USR.pin_reset_force;
				delete app.CACHE.USR.settings.login_failed;
			}

			// delay execution
			setTimeout(function(){

				// update user
				app.cache_save('usr');

				if( app.HASH === 'home' ) {
					// update dom
					app.VIEW.home.DOM.bottom_container.hide();

					// hide form
					app.VIEW.home.DOM.form.hide();
					// re-sync
					app.VIEW.home.DOM.btn_sync.show().parent().show();
					app.VIEW.home.DOM.btn_sync.click();
				}
			}, 850);
		}
	},

	clense_cache: function()
	{
		// clense reports
		if( app.CACHE.USR.priv_default !== '0' ) {
			app.TPL._get('REP').filter(function(r){
				// delete reports that havent been started
				if( r.hasOwnProperty('ts') && r.hasOwnProperty('date_start') === false ) {
					app.FORM.save_offline('rep', 'delete', {}, true, r.ts);
				}
			});
		}

		// clense inspections
		if( app.CACHE.USR.priv_mai === '1' ) {
			app.TPL._get('MAI').filter(function(r){
				// delete reports that havent been started
				if( r.hasOwnProperty('ts') && r.hasOwnProperty('date_start') === false ) {
					app.FORM.save_offline('mai', 'delete', {}, true, r.ts);
				}
			});
		}

		// clense inspections answers
		var cleanAnswerSignatures = false;
		$.each(app.CACHE.MAI_ANS, (k,r) => {
			if( r.id && r.answer && r.type === app.CACHE.MAI_QUE_TYP.signature ) {
				cleanAnswerSignatures = true;
				delete r.answer;
			}
		});

		// delete fuel entries that have been saved
		if( app.FUEL_EXPENSES.check_priv() ){
			app.TPL._get('FUE_EXP').filter(function(r){
				if( r.hasOwnProperty('id') || r.hasOwnProperty('date_start') === false ) {
					const id = ( r.id ) ? r.id : r.ts;
					app.FORM.save_offline('fue_exp', 'delete', {}, true, id);
				}
			});
		}

		if( cleanAnswerSignatures ) {
			app.cache_save('mai_ans');
		}
		
	},

	download: function()
	{
		// check for initial opening of app on home page
		// check for login last page
		// dont start until 2 seconds because maybe directories havent been created yet
		setTimeout(async () => {
			var files = app.FILE.download_prepare(['res']);
			
			// start
			await app.FILE.download(files);
		}, 2000 );
	},

	show_storage_percentage: function()
	{
		var t = 0,
			max = 5000, // size in kb
			colour = 'green',
			exclude = ['setItem', 'removeItem', 'getItem', 'clear', 'length', 'key'];

		for (var x in localStorage) {
			if( exclude.indexOf(x) === -1 ){
				t += (x.length + localStorage[x].length) * 2;
			}
		}

		// work out values
		var size = parseFloat((t / 1024).toFixed(2), 10),
			perc = parseFloat(((size / max) * 100).toFixed(1), 10);

		if( perc >= 81 ) {
			colour = 'red';
		} else if( perc >= 61 ) {
			colour = 'orange';
		} else if( perc >= 41 ) {
			colour = 'yellow';
		}
		
		this.DOM.percentage.find('span.val').html(perc + '%');
		this.DOM.percentage.find('span.bg').css('width', perc + '%').addClass('perc-' + colour);
	},

	/**
	 * Check to see if we are currently syncing if not show sync button
	 */
	evt_setup_sync: function()
	{
		if( !app.CACHE.USR || !app.CACHE.USR.email || !app.CACHE.USR.pin_token ) {
			app._('form').show();
			app.CACHE.USR.settings.login_failed = true;
			app._('btn_sync').hide();
		}


		// show sync button if not in progress
		if( app.check_ajax() ) {
			this.DOM.btn_sync.hide();
		} else {
			this.DOM.box_sync.show();
		}

		// CLICK
		this.DOM.btn_sync.on('click', function(){

			// hide button
			app._('btn_sync').hide();

			app.SYNC.start(true);
		});
	},

	evt_attempt_sync: function()
	{
		// have we ever synced?
		if( app.CACHE.USR.settings && (app.CACHE.USR.settings.login_failed || app.CACHE.USR.settings.date_synced === false) ) {
			return;
		}

		if( app.SYNC.check_time('date_synced') ) {

			// attempt automatic sync
			setTimeout(function(){ app.VIEW.home.DOM.btn_sync.trigger('click'); }, 100);

		} else if( app.SYNC.check_time('date_synced_get_msg', true) ) {

			// attempt msg sync
			app.SYNC.start('get_msg', true);
		}
	}
};