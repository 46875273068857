import {ActionSheet} from '@capacitor/action-sheet';
import {Capacitor} from "@capacitor/core";

export class ActionSheetCore {
    actionSheetOptionEvent = new Event('action-sheet-option', {
        cancelable: true,
        composed: false,
        bubbles: true
    });
    actionSheetCloseEvent = new Event('action-sheet-closed', {
        cancelable: false,
        composed: false,
        bubbles: true
    });
    open = true;

    async create(title, message, options, parentElement)
    {
        if(Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'web') {
            return new Promise((resolve, reject) => {
                if(!parentElement) {
                    reject('Parent element is required to display an ActionSheet on Web and Android.');
                    return;
                }

                const parentEl = $(parentElement).get(0);

                if(!parentEl) {
                    reject('Failed to find parent element to use for action sheet.');
                }

                parentEl.addEventListener('action-sheet-option', (e) => {
                    this.close(parentElement, true);
                    resolve(e.data);
                });

                parentEl.addEventListener('action-sheet-closed', (e) => {
                    if(e.data) {
                        reject(e.data);
                    } else {
                        reject('Something went wrong and the action sheet was closed.');
                    }
                });

                this.insertDOM(title, message, options, parentElement);
                this.setupEvents(parentElement, parentEl);
            });
        }

        const result = await ActionSheet.showActions({
            title: title ?? '',
            message: message ?? '',
            options: options,
        });

        return result.index;
    }

    insertDOM(header, message, options, parentElement) {
        let html = `
        <div class="outer-wrapper">
            <div class="wrapper${this.open ? ' open' : ''}">
                <div class="action-sheet-content">
        `;

        if(header) {
            html += `<div class="title">${header}</div>`;
        }
        
        if(message) {
            html += `<div class="message">${message}</div>`;
        }
        
        options.forEach((option, i) => {
            html += `
                <div class="action-sheet-option ${option.style ? option.style.toLowerCase() : 'default'}" data-index=${i}>
                    <div class="action-sheet-button">${option.title}</div>
                </div>
            `;
        });

        html += '</div></div></div>';

        $(parentElement).html(html);
    }

    setupEvents(parentElement, parentEl) {
        $(`${parentElement} .action-sheet-option`).on('click', (e) => {
            let target = $(e.currentTarget);
            let index = target.attr('data-index');
            if(parentEl && !isNaN(index)) {
                let toEmit = this.actionSheetOptionEvent;
                toEmit.data = parseInt(index);
                parentEl.dispatchEvent(toEmit);
            } else {
                this.close(parentElement);
            }
        });

        $(`${parentElement} .outer-wrapper`).on('click', (e) => {
            if($(e.target).hasClass('outer-wrapper') || $(e.target).hasClass('wrapper')) {
                this.close(parentElement, true);
            }
        });
    }

    close(parentElement, success) {
        console.log('Closing ActionSheet');
        $(`${parentElement} .action-sheet-option`).off('click');
        $(parentElement).off('action-sheet-option');
        $(parentElement).html('');
        if(!success) {
            $(parentElement).dispatchEvent(this.actionSheetCloseEvent);
        }
    }
}
