export let veh_def_form = {

	DOM: {},

	init: function()
	{
		if( !app.DATA.RESULT ) {
			this.ajax(app.URI[1]);
			return;
		}

		// form
		app.FORM.start({hideConfirmation: true, changes: false});
		
		// DOM
		this.setup_dom();
		
		// EVENTS
		this.setup_events();
	},

	
	setup_dom: function()
	{
		// update from geo lookup 
		this.DOM.lat = this.DOM.form.find('#lat');
		this.DOM.lng = this.DOM.form.find('#lng');
		// form dom elements
		this.DOM.notes = this.DOM.form.find('#notes');
		this.DOM.is_resolved = this.DOM.form.find('#is_resolved_no, #is_resolved_yes');
		this.DOM.date_resolved = this.DOM.form.find('#date_resolved');
		this.DOM.notes_resolved_container = this.DOM.form.find('#notes-resolved-container');
		this.DOM.notes_resolved = this.DOM.form.find('#notes_resolved');
		this.DOM.msg_photo = this.DOM.form.find('#msg-photo');
	},
	
	geo_win: function(data)
	{
		console.log('geo_win');
		this.DOM.lat.val(data.lat);
		this.DOM.lng.val(data.lng);
	},

	/**
	 * Get data from ONLINE for missing vehicle defect
	 */
	ajax: function(id)
	{
		app.DOM.content_load.html('<div id="loading" class="outer outer-header"><p class="ac"><img src="img/loading-black.svg" width="30"></p></div>');

		// cache dom for loading
		this.DOM.loading = app.DOM.content_load.find('#loading');

		var hash = app.HASH;

		var ajax = $.ajax({
		    url: app.get_api_url('veh_def/get/'+id),
		    data: app.get_api_data(),
		    method: 'POST'
		});

		ajax.done(function(r){

		    // success
		    if( r && r.status === 'success' && app.HASH === hash ) {
		        app.VIEW[hash].ajax_success(r.data);
		    } else {
		    	app.VIEW[hash].ajax_error();
		    }
		});

		ajax.fail(function(){
			app.VIEW[hash].ajax_error();	
		});
	},

	ajax_success: function(r)
	{
		// save temporarily
		app.CACHE.VEH_DEF.push(r);
		app.cache_save('veh_def');
		// reload
		app.redirect();
	},

	ajax_error: function()
	{
		this.DOM.loading.html('<p class="ac">There was a problem loading the In-Service defect, please try again shortly.</p>');
	},

	setup_events: function()
	{
		if( app.URI[2] === 'success' ) {

			// UPLOAD
			this.setup_photos_upload();

		} else {

			this.setup_form_changes();
		}

		// SETUP FOOTER
		var opts = [{
			btn: 'left', txt: 'Back', href: 'home'
		}];

		if( app.URI[2] !== 'success' ) {
			opts.push({btn: 'right', txt: 'Submit', click: 'form_btn_submit'});
		}

		app.setup_footer(opts);

		// keep order for footer_btn_right hide/show
		// PHOTO REQUIRED MSG
		if( app.URI[2] !== 'success' ) {
			this.check_photo_required();
		}
	},

	setup_form_changes: function()
	{
		app.DOM.content_load.find('#btn-delete').on('click', app.VIEW.veh_def_form.delete);

		// geo lookup
		app.GEO.start(app.HASH, {force: true});

		// RESOLVED
		this.setup_evt_resolved_change();

		// NOTES
		this.setup_notes_changes();

		// setup camera
		if(app.DATA.RESULT.is_resolved === '0') {
			app.CAMERA.setup(app.DATA.RESULT, 'date_end', 'veh_def');
		}
	},

	/**
	 * Check to see if required photo message is needed
	 * Check to see if we can display form submit button
	 */
	check_photo_required: function()
	{
		var r = app.TPL.get_row('veh_def', app.URI[1]),
			pics = ( r ) ? app.SYNC.send_data_photos_tbl('veh_def', 'ts', app.URI[1]) : [];


		// do nothing cant find row
		if( !r ) {
			return;
		}

		// work out if we need required photo message
		if(
			pics.length === 0 && (
				(!r.report_id && app.CACHE.USR.standalone_is_required_photo === '1'  ) ||
				(r.report_id && r.report_id !== '0' && app.CACHE.USR.veh_def_is_required_photo === '1' )
			)
		) {
			app._('msg_photo').show();
		} else {
			app._('msg_photo').hide();
		}

		// work out if we can show submit button
		if(
			(pics.length === 0 && (
				(!r.report_id && app.CACHE.USR.standalone_is_required_photo === '1' ) ||
				(r.report_id && r.report_id !== '0' && app.CACHE.USR.veh_def_is_required_photo === '1' )
			)) || app.DATA.RESULT.is_resolved === '1'
		) {
			app.DOM.footer_btn_right.hide();
		} else {
			app.DOM.footer_btn_right.show();
		
		}
		
		let hasPhotos = false;
		let photoCount = 0;
		if(r && (
			(!r.report_id && app.CACHE.USR.standalone_is_required_photo === '1') ||
			(r.report_id && app.CACHE.USR.veh_def_is_required_photo === '1')
		)) {
			Object.keys(r).forEach((key) => {
				let shouldAdd;
				if(app.WEBAPP) {
					shouldAdd = veh_def_form.checkWebappFiles(key, r);
				} else {
					shouldAdd = veh_def_form.checkAppFiles(key, r);
				}
				
				if(!shouldAdd) {
					return;
				}
				
				photoCount++;
			});
			hasPhotos = photoCount >= 1;
		}
		
		if(hasPhotos) {
			app._('msg_photo').hide();
		}
	},
	
	checkWebappFiles: (key, ans) => {
		if(!key.startsWith('file') || (key.startsWith('file') && (key.endsWith('_needs_uploading') || key.endsWith('_local')))) {
			return false;
		}
		
		if(!ans[key] || ans[key].length === 0) {
			return false;
		}
		
		return true;
	},
	
	checkAppFiles: (key, ans) => {
		if(!key.startsWith('file') || !key.endsWith('_local')) {
			return false;
		}
		
		if(!ans[key] || ans[key].length === 0) {
			return false;
		}
		
		return true;
	},

	/**
	 * Upload photos after succesful form submission
	 */
	setup_photos_upload: function()
	{
		// no phonegap OR offline
		if( app.PHONEGAP === false || app.DATA.RESULT.hasOwnProperty('id') === false ) {
			return;
		}

		// get photos to upload
		var data_photos = app.SYNC.send_data_photos_tbl('veh_def', 'id', app.URI[1]);

		// upload if photos exist
		if( data_photos.length > 0 ) {
			app.SYNC.send_photos(data_photos);
		}
	},

	setup_evt_resolved_change: function()
	{
		this.DOM.is_resolved.change(function(){

			var data = {
				'is_resolved': this.value,
				'date_resolved': ( this.value === '1' ) ? app.DATE.format('datetime') :  ''
			};

			app.VIEW[app.HASH].DOM.date_resolved.val(data.date_resolved);
			 

			app.FORM.save_offline('veh_def', 'edit', data, true, app.URI[1], false, false, true);

			// resolved: yes
			if( this.value === '1' ) {
				
				// alert user
				if( app.DATA.RESULT.is_resolved !== '1' ) {
					app.show_alert('Please describe how this defect was resolved.');
				}

			} else {

				// alert user
				if( app.DATA.RESULT.id && app.DATA.RESULT.is_resolved !== '0' ) {
					app.show_alert('Please describe how this defect was Unresolved.');
				}
			}

			// show notes_resolved
			if(
				(this.value === '1' && app.DATA.RESULT.is_resolved === '1') ||
				(this.value === '0' && app.DATA.RESULT.is_resolved === '0')
			) {
				app.VIEW[app.HASH].DOM.notes_resolved_container.hide();
				app.VIEW[app.HASH].DOM.notes_resolved.removeClass('required');
			} else {
				app.VIEW[app.HASH].DOM.notes_resolved_container.show();
				app.VIEW[app.HASH].DOM.notes_resolved.addClass('required');
			}
		});

		// inital state
		app.VIEW[app.HASH].DOM.notes_resolved.removeClass('required');
	},

	setup_notes_changes: function()
	{
		
		// default notes
		app.VIEW[app.HASH].DOM.notes.change(function(){
			
			var data = {'notes': this.value};

			// check for any coordinates
			var geo = app.GEO.has_expired();
			if( geo ) {
				data.lat = geo.lat;
				data.lng = geo.lng;
				data.date_geo = geo.date_added;
			}

			app.FORM.save_offline('veh_def', 'edit', data, true, app.URI[1], false, false, true);
		});

		// resolved notes
		app.VIEW[app.HASH].DOM.notes_resolved.change(function(){
			app.FORM.save_offline('veh_def', 'edit', {'notes_resolved': this.value}, true, app.URI[1], false, false, true);
		});
	},

	/**
	 * Allow user to select recent vehicle
	 */
	setup_evt_click_recent: function()
	{
		this.DOM.table_tr.on('click', function(){

			// get data for add
			var data = {
				'vehicle_id': $(this).attr('data-vehicle-id'),
				'user_id': app.CACHE.USR.id
			};

			// add veh_def
			var ts = app.FORM.save_offline('veh_def', 'add', data, true, false, false, function(){

				// redirect user to new defect
				app.redirect('veh_def_form/'+ts);
			});
		});
	},

	form_online: function(json, post)
	{
		if( json.data && json.data.veh_def ) {

			json.data.veh_def.saved = true;

			// add on locked date
			// APP only
			json.data.veh_def.date_end = app.DATE.format('datetime');

			// save
			app.FORM.save('veh_def', 'edit', json.data.veh_def, post, false, app.URI[1]);
		}
	},

	form_offline: function(json, post, tbl)
	{
		if( $.isNumeric(post.id) ) {
			app.show_alert('Could not save changes, please try again shortly.', 'Attention');
			app.FORM.loading();
			return;
		}

		var data = {
			saved: true,
			date_end: app.DATE.format('datetime')
		};

		// redirect as no data needs to be saved
		app.FORM.save_offline('veh_def', 'edit', data, false, app.URI[1], false, function(){
			app.redirect(app.HASH+'/'+app.URI[1]+'/success');
		});
	},

	/**
	 * Callback function for when picture is saved from camera.js
	 */
	onCameraSave: function(data, $img, settings)
	{
		app.VIEW[app.HASH].check_photo_required();
	},
	
	onCameraDelete: () => {
		app.VIEW[app.HASH].check_photo_required();
	},

	delete: function()
    {
		const $el = $(this);

        // SAVE: date_end + refresh
        app.show_confirm("Do you want to delete this entry?", "Confirm deletion", function(response){
            
            if( response !== true ) {
                return;
            }

            $el.hide().after('<img class="loading" src="img/loading-black.svg" width="20">');

            // delete
            app.FORM.save_offline('veh_def', 'delete', {}, true, app.URI[1], false, () => {
                app.redirect('home');
            }, true);
           
        });
    },
};