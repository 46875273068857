export let login = {

	DOM: {},
	SETTINGS: {},

    TBLS: [
        'acc',
        'res',
        'veh','veh_typ',
        'rep_che','rep_que','rep_ans','rep','que_typ',
        'mai_che','mai_que','mai_ans','mai','mai_que_typ','mai_ans_health',
        'msg_usr',
        'msg_hom',
        'tco_drv_let',
        'url_files','photo_req',
        'usr','usr_typ',
        'fue_exp','fue_exp_types','fue_exp_sources', 'cos_typ'
    ],

	init: function()
	{
        // start the form
        app.FORM.start({
            pin: true,
            save_show: true,
            data: {'device': app.CACHE.DEVICE},
            timeout: 10000
        });

        this.setup_dom();

        //this.setup_demo();
    },

    setup_dom: function()
    {
        this.DOM.logo = app.DOM.content_load.find('#logo-login');
        $('#content').before('<div id="login-header"></div>')
    },

	form_online: async (json, post) => {
        app.VIEW[app.HASH].DOM.form.find('fieldset').hide();
        app.VIEW[app.HASH].DOM.bottom_container.hide();
        
        // demo mode
        var demo = ( app.CACHE.USR.settings.hasOwnProperty('demo') ) ? true : false;

        // save items
        $.each(login.TBLS, function(k, tbl){
            if(tbl === 'que_typ') {
                app.CACHE.DEFAULT_QUESTIONS = app.getDefaultQuestions(app.CACHE.QUE_TYP);
                app.cache_save('default_questions');
            }
            
            app.cache_save(tbl, json.data[tbl]);
        });

        // create file folders
        await app.FILE.create();

        // set secure status
        delete app.CACHE.USR.logged_in;

        app.CACHE.USR.settings = {
            logged_in: true,
            secure: true,
            date_login: app.DATE.format('datetime'),
            sitename: app.CACHE.SITENAME
        };

        // remember demo mode
        if( demo ) {
            app.CACHE.USR.settings.demo = true;
        }

        /// save cache
        app.cache_save('usr');

        // see if theres any branding to fix
        app.setup_branding();

        // redirect page
        var redirect = ( json.data.usr.logins_app === '0') ? 'pin_first' : 'home';

        setTimeout(function(){
            app.redirect(redirect);
        }, 1200);
    },

    form_on_error: function(errors)
    {
        // reset password if credential errors
        $.each(errors, function(k,v){

            // remove existing pin
            if( this.key === 'credentials' ) {
                app.VIEW.login.DOM.pins.val('').focus();
            }
        });
    }
};