import * as Handlebars from 'handlebars';

export let photo = {

	DOM: {},

	init: function()
	{
		// DOM
		this.setup_dom();

		// EVENTS
		this.setup_events();
	},

	setup_dom: function()
	{
		this.DOM.nav = app.DOM.content_load.find('#nav-sub');
		this.DOM.nav_btn = this.DOM.nav.find('a');
		this.DOM.nav_btn_next = this.DOM.nav_btn.filter('#btn-next');
		this.DOM.nav_btn_prev = this.DOM.nav_btn.filter('#btn-prev');
		this.DOM.img = app.DOM.content_load.find('img#gallery');
	},

	setup_events: function()
	{
		this.DOM.nav_btn.on('click', function(){

			var tmp = $(this).attr('data').split('/'),
				data = app.TPL.photo({}, tmp[0], tmp[1], tmp[2]);
				
			// update image
			app.VIEW[app.HASH].DOM.img.attr('src', Handlebars.helpers.getFileSlug(tmp[0], data.PHOTO, tmp[2], false, data.PHOTO.is_webapp));

			// next
			if( data.PROGRESS.next ) {
				// show next
				app.VIEW[app.HASH].DOM.nav_btn_next.attr('data', data.PROGRESS.next).show();
			} else {
				// hide next
				app.VIEW[app.HASH].DOM.nav_btn_next.hide().removeAttr('data');
			}

			// prev
			if( data.PROGRESS.prev ) {
				// show prev
				app.VIEW[app.HASH].DOM.nav_btn_prev.attr('data', data.PROGRESS.prev).show();
			} else {
				// hide prev
				app.VIEW[app.HASH].DOM.nav_btn_prev.hide().removeAttr('data');
			}

		});
	}
};