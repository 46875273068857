import moment from "moment";
import {isEqual} from "lodash";

export let SYNC = {

	// time ms/seconds
	INTERVALS: {
		delay: 1000, // ms
		date_synced: 300,// seconds
		date_synced_get_msg: 45,// seconds
	},

	// Order sync process (some wont be run without phonegap)
	ORDER: ['send', 'send_related', 'get', 'get_msg', 'get_questions', 'get_reports', 'get_maintenance_questions', 'get_maintenance', 'send_photos'],

	// default ajax status
	AJAX: false,

	// SEND: tables
	TBLS_SEND: ['rep', 'mai', 'veh_def', 'acc', 'fue_exp'],
	TBLS_SEND_RELATED: ['rep_ans', 'mai_ans'],

	// GET: tables
	TBLS_GET: ['veh','veh_typ','rep_che','mai_che', 'url_files'],
	// GET: message
	TBLS_GET_MSG: ['msg_usr', 'msg_hom', 'tco_drv_let'],
	// get: reports
	TBLS_GET_QUESTIONS: ['rep_que', 'rep_che'], // send checklists as reference
	TBLS_GET_REPORTS_OTHER: ['rep'], // removed rep_ans
	TBLS_GET_REPORTS: ['rep', 'rep_ans'],
	// get: maintenance
	TBLS_GET_MAINTENANCE_QUESTIONS: ['mai_que', 'mai_che'],
	TBLS_GET_MAINTENANCE_OTHER: ['mai', 'mai_ans'], 
	TBLS_GET_MAINTENANCE: ['mai', 'mai_ans'],
	// SEND_PHOTOS
	TBLS_SEND_PHOTOS: ['acc', 'rep_ans', 'mai_ans', 'veh_def', 'fue_exp'],

	/**
	 * Different types of AJAX syncs
	 *
	 * app.FILE.AJAX
	 * app.SYNC.AJAX
	 */
	check_connection: function(action)
	{
		// CHECK NET CONNECTION#
		if( app.check_connection() === false ) {

			// update message
			app.SYNC.msg('error', 'Problem syncing ('+action+'), no internet connection detected.');

			// change status
			app.SYNC.change_status(false, action);

			return false;
		}

		return true;
	},

	check_time: function(prop, force)
	{
		var ts = moment().unix(),
			tsDate = ( app.CACHE.USR.settings && app.CACHE.USR.settings[prop] ) ? (ts - moment(app.CACHE.USR.settings[prop]).unix()) : false;

		if( (tsDate === false && force ) || tsDate > app.SYNC.INTERVALS[prop] ) {
			return true;
		}

		if( tsDate ) {
			console.log('sync '+prop+' in: ', tsDate - app.SYNC.INTERVALS[prop]);
		}

		return false;
	},

	/**
	 * Start sync process
	 *
	 * @param string action [send, get, send_photos]
	 * @param boolean|undefined single [if true only attempt to sync one section]
	 */
	start: function(action, single, singleData)
	{
		// do nothing if not on homepage for default sync
		if( app.HASH !== 'home' && !single ){
			console.warn('ENDING sync not on #home');
			return;
		}

		// if initial start without action use app.SYNC.ORDER
		if( action === true ) {
			if( app.check_ajax() ) {
				console.info('Already started ajax');
				return;
			}

			action = this.ORDER[0];
		}

		// hide sync box so user cant press button again
		if( ( action === true || (single && !singleData) ) && app.HASH === 'home' ) {
			app.VIEW[app.HASH].DOM.btn_sync.hide();
		}

		// if only syncing one section use temporary order
		var order = ( single && action ) ? [action] : this.ORDER;

		// action details
		var	actionIndex = order.indexOf(action),
			tblsMai = ['get_maintenance_questions', 'get_maintenance_other', 'get_maintenance'],
			tblsDefault = ['get_questions', 'get_reports_other', 'get_reports'];

		// CHECK: get_reports_other access
		if(
			( app.CACHE.USR.priv_default === '0' && tblsDefault.indexOf(action) >= 0 ) ||
			( app.CACHE.USR.priv_mai !== '1' && tblsMai.indexOf(action) >= 0 ) ||
			( action === 'get_reports_other' && app.CACHE.USR.priv_other_reports === '0') ||
			( action === 'get_maintenance_other' && app.CACHE.USR.priv_mai_other === '0')
		) {
			console.warn('SKIPPING...', action);

			// STOP: get_reports_other
			// msg
			this.msg_progress(false, action);

			// start next action if no data for send(s)
			app.SYNC.start(order[actionIndex+1]);

			return;

		// CHECK STAGE
		} else if( actionIndex < 0 ) {

			console.log('END OF SYNC...', actionIndex, single);

			// call funtion 
			if( single && app.VIEW[app.HASH] && app.VIEW[app.HASH].hasOwnProperty('sync_win') ){
				app.VIEW[app.HASH].sync_win(singleData);
			}
		
			// STOP: end of stages
			return;
		}

		// DATA FOR STAGE
		var	data = SYNC[action + '_data'](action);

		console.warn('app->SYNC->start -> ' + action, data);

		// CHECK: CONNECTION
		if( this.check_connection(action) === false ) {

			// STOP: CONNECTION
			return;

		// CHECK: photos
		} else if ( action === 'send_photos' ) {

			// STOP: START PHOTOS
			this.send_photos(data, actionIndex);
			return;

		// CHECK: no data
		} else if( ( action === order[0] || action === order[1] ) && app.obj_length(data) === 0 && !single ){

			// STOP: no data
			// msg
			this.msg_progress(false, action);

			// start next action if no data for send(s)
			// start next proccess
			app.SYNC.start(order[actionIndex+1]);

			// continue no further
			return;

		// CHECK: STAGE ALREADY STARTED
		} else if( app.SYNC.change_status(true, action) === false ) {

			// STOP: stage already started
			return;
		}

		// AJAX
		this.start_ajax(action, actionIndex, data, single);
	},

	start_ajax: function(action, actionIndex, data, single)
	{
		var ajax = $.ajax({
            'url': app.get_api_url('sync/'+action),
            'type': 'POST',
            'timeout': app.TIMEOUT_AJAX,
            'data': app.get_api_data(data)
		});

		// GO
		ajax.done(function(json){

			// change status
			app.SYNC.change_status(false, action);

            // ACOUNT
            if( json.account ) {
                app.check_user_account(json.account);
            }

			// do something
			if( json.status === 'success' && json.data ) {

				// message progress
				app.SYNC.msg_progress(false, action, single);

				// win callback
				app.SYNC[action +'_win'](json.data, actionIndex, single);

			} else {

				// dev log error
				console.log('No '+action+' data to sync', json);

				var msg = '';

				if( json.status === 'error' ) {
					$.each(json.errors, function(k,v){
						msg += "<br>- "+v.msg;

						// login_failed
						if( v.key === 'user_id' ) {
							app.VIEW.home.DOM.form.show();
							app.CACHE.USR.settings.login_failed = true;
							app.cache_save('usr');
						}
					});
				}

				// update message
				app.SYNC.msg('error', 'There was a problem syncing ('+action+':101)'+msg);
			}
			
			app.check_header_msg_count();
		});

		// FAIL
		ajax.fail(function(e){
			// log error
			console.log('ERROR', e);

			// change status
			app.SYNC.change_status(false, action);

			// update message
			app.SYNC.msg('error', 'Problem syncing, failed to find an internet connection ('+action+':102)');
		});
	},

	/**
	 * Get data for /api/sync/send
	 * Get data for /api/sync/send_related
	 */
	send_data: function(action)
	{
		const c = $.extend({}, app.CACHE);
		let data = {};

		// LOOP TABLES
		$.each(app.SYNC['TBLS_'+action.toUpperCase()], function(k, tbl){


			// LOOP RECORDS
			$.each(c[tbl.toUpperCase()], function(key, r){

				// clean row
				const row = $.extend({}, r);

				// does row need sync
				if( row.hasOwnProperty('sync') && row.sync === false && app.SYNC.checkSyncSend(tbl, row)) {
					
					// check container created
					if( data.hasOwnProperty(tbl) === false ) {
						data[tbl] = [];
					}

					// add row
					data[tbl].push(row);
				}
			});
		});

		return data;
	},

	/**
	 * Check specific table rows are ready to send
	 * @return bool
	 */
	checkSyncSend: function(tbl, r)
	{
		// rep/veh_def
		if( tbl === 'rep'  && r.hasOwnProperty('date_end') === false ) {
			return false;
		} else if( tbl === 'veh_def' && !r.notes ) {
			return false;
		} else if( tbl === 'rep_ans' && app.cache_get_prop('rep', r.report_id, 'id') === undefined ) {
			return false;
		} else if( tbl === 'acc' && !r.notes ) {
			return false;
		} else if( tbl === 'mai' && r.hasOwnProperty('date_end') === false ) {
			return false;
		} else if( tbl === 'mai_ans' && app.cache_get_prop('mai', r.maintenance_id, 'id') === undefined ) {
			return false;
		} else if( tbl === 'fue_exp' && !app.FUEL_EXPENSES.check_ready(r) ) {
			return false;
		}

		return true;
	},

	/**
	 * Count number of unsynced items in app.CACHE[tbl]
	 * 
	 * @return int
	 */
	count_unsynced: function(tbl, check_property)
	{
		let total = 0;

		if( !check_property ) {
			check_property = 'date_end';
		}

		$.each(app.CACHE[tbl.toUpperCase()], function(k,v){
			if(
				v.hasOwnProperty('id') === false &&
				v.hasOwnProperty(check_property) &&
				(tbl !== 'fue_exp' || app.FUEL_EXPENSES.check_ready(v))
			) {
				total++;
			}
		});

		return total;
	},

	/**
	 * Loop through each related row and check if sync needed
	 * 
	 */
	send_related_data: function(action, actionIndex)
	{
		return this.send_data(action);
	},

	/**
	 * Use this.get_win logic
	 */
	send_related_win: function(data, actionIndex)
	{
		this.send_win(data, actionIndex);
	},

	/**
	 * Success: AJAX callback update offline data
	 */
	send_win: function(data, actionIndex, single)
	{
		let conflicts = {};

		// LOOP TABLES TO SYNC
		$.each(data, function(tbl, rows){

			console.log('SYNC.send() tbl ->', tbl);

			// LOOP THROUGH ROWS TO SYNC
			$.each(rows, function(k,row){

				// save conflicts to display later
				if( row.hasOwnProperty('is_conflict') ) {

					if( conflicts.hasOwnProperty(tbl) === false ){
						conflicts[tbl] = 0;
					}

					// increment count
					conflicts[tbl]++;
				}

				// add/edit are both edit as they already exist in cache
				var action = ( row.action === 'delete' ) ? 'delete' : 'edit';
				app.FORM.save(tbl, action, row, row, true, false, false, true);
			});

			// save data
			app.cache_save(tbl, false, true);
		});

		// conflicts
		if( app.obj_length(conflicts) > 0 ) {
			console.log('conflicts', conflicts);
		}
		
		// START NEXT PROCESS
		// DELAYED so all data has been written
		setTimeout(function(){

			if( single ) {
				app.SYNC.start(undefined, single, data);
			} else {
				app.SYNC.start( app.SYNC.ORDER[actionIndex+1]);
			}


		}, this.INTERVALS.delay);
	},

	msg: function(type, msg)
	{
		if( app.HASH !== 'home' ) {
			return;
		}

		var add = ( type === 'success' ) ? 'green' : 'red',
			remove = ( type === 'success' ) ? 'red' : 'green',
			fa = ( type === 'success' ) ? 'check-circle' : 'exclamation-triangle';
		
		app.VIEW[app.HASH].DOM.box_sync.show();

		// default loading
		var icon = '<img src="img/loading-black.svg">';

		// change icon depending on error message
		if( msg.indexOf('100') >= 0 ) {
			icon = '<i class="fa fa-'+fa+'"></i>';
		} if( msg.toLowerCase().indexOf('error') >= 0 || msg.toLowerCase().indexOf('problem') >= 0 ) {
			icon = '<i class="fa fa-'+fa+'"></i>';
		}

		// update message
		app.VIEW[app.HASH].DOM.msg_sync.removeClass(remove).addClass(add).html(icon+' ' + msg).show();

		if( type === 'error' ) {
			app.VIEW[app.HASH].DOM.btn_sync.show();
		}
	},

	msg_progress: function(actionIndex, action, single)
	{
		// msg
		if( app.HASH !== 'home' && !single ) {
			return;
		}

		// time of progress
		var time = moment().format('HH:mm:ss'),
		// percent of progress
			actionPerc = 0;

		// force action to finish
		if( (actionIndex === false && action === false) || single ) {

			actionPerc = 100;

		} else if( actionIndex === false || actionIndex === undefined ) {

			// get new index
			actionIndex = this.ORDER.indexOf(action);

			// percentage of progress
			actionPerc = Math.round(( actionIndex + 1 ) / this.ORDER.length * 100);
		}

		// dont update message if single sync
		if( !single && app.HASH === 'home' ) {
			// london for sync status
			var sync = ( actionPerc === 100 ) ? 'Synced' : 'Syncing';

			// update dom
			app.SYNC.msg('success', sync+' data: '+actionPerc + '% complete ('+time+').');
		}

		// SYNC: COMPLETE
		if( actionPerc === 100 ) {

			// show sync button
			if( app.HASH === 'home') {
				app.VIEW[app.HASH].DOM.msg_unsynced.hide();
				app.VIEW[app.HASH].DOM.btn_sync.show();
			}

			// save date last synced
			if( single ) {
				app.CACHE.USR.settings['date_synced_'+action] = app.DATE.format('datetime');
			} else {
				app.CACHE.USR.settings.date_synced = app.DATE.format('datetime');
			}

			app.cache_save('usr');
		}
	},

	/**
	 * Check to see if requests can be sent and change ajax status
	 */
	change_status: function(start, action)
	{
		var msg = ( start ) ? 'Start' : 'END';

		console.log(msg, 'app.SYNC.'+action+'()');

		if( start ) {

			// AJAX CALL IN PROGRESS
			if( app.check_ajax() ) {
				console.log('Ajax call already in process...');
				return false;
			}

			// change status
			app.SYNC.AJAX = true;

			// change sync icon
			app.DOM.header_sync.show();

			// tell app data is OK to send
			return true;

		} else {
			
			// change status
			app.SYNC.AJAX = false;

			// change sync icon
			app.DOM.header_sync.hide();

			// update dom on menupage
			if( action !== 'send_photos' ) {
				this.status_ajax_off_menu();
			}
		}
	},

	status_ajax_off_menu: function()
	{
		if( app.HASH !== 'home' ) {
			return;
		}

		// update memory reading
		app.VIEW[app.HASH].show_storage_percentage();

		// show msg
		app.VIEW[app.HASH].DOM.msg_sync.show();

		// show sync box
		app.VIEW[app.HASH].DOM.box_sync.show();
	},

	/**
	 * Get success
	 * 
	 * app.SYNC.start('get') callback
	 */
	get_win: function(data, actionIndex, single)
	{
		// LOOP TABLES
		$.each(data, function(tbl, rows){
			 
			if( tbl === 'usr' ) {

				app.SYNC.get_win_usr(rows);

			} else if(tbl === 'url_files') {
				app.SYNC.get_win_url_files(rows);
			} else if( tbl === 'url' ) {
				
				app.SYNC.get_win_url(rows);

			} else if( tbl === 'fue_exp_types' && !isEqual(app.CACHE.FUE_EXP_TYPES, rows) ) {
				app.CACHE.FUE_EXP_TYPES = rows;
				app.cache_save('fue_exp_types');
			} else if( tbl === 'fue_exp_sources' && !isEqual(app.CACHE.FUE_EXP_SOURCES,rows) ) {
				app.CACHE.FUE_EXP_SOURCES = rows;
				app.cache_save('fue_exp_sources');
			} else if(tbl === 'que_typ' && !isEqual(app.CACHE.QUE_TYP, rows)) {
				app.CACHE.QUE_TYP = rows;
				app.CACHE.DEFAULT_QUESTIONS = app.getDefaultQuestions(app.CACHE.QUE_TYP);
				app.cache_save('default_questions');
			} else {

				// LOOP ROWS
				$.each(rows, function(k, row){
					app.FORM.save(tbl, row.action, row, row, true, false, false, true);
				});

				// save
				if( rows.length > 0 ) {
					app.cache_save(tbl, false, true);
				}
			}
		});

		// START NEXT PROCCESS
		// DELAYED so all data written
		setTimeout(function(){

			if( single ) {
				app.SYNC.start(undefined, single, data);
			} else {
				app.SYNC.start(app.SYNC.ORDER[actionIndex+1]);
			}

		}, this.INTERVALS.delay);
	},

	get_win_usr: function(usr)
	{
		console.warn('user settings updated');

		var newUsr = $.extend(app.CACHE.USR, usr, app.CACHE.USR);
		
		app.cache_save('usr', newUsr);
		
		if(app.HASH !== 'home') {
			return;
		}
		
		if(newUsr.priv_app_vehicle_defects !== '0' && newUsr.opr_priv_app_vehicle_defects === '1') {
			app.DOM.content_load.find('#defects-list').show();
			return;
		}
		
		app.DOM.content_load.find('#defects-list').hide();
	},

	get_win_url_files: (url_files) => {
		if(!isEqual(app.CACHE.URL_FILES, url_files)) {
			console.warn('saving url files');
			app.cache_save('url_files', url_files);
		}
	},

	// has URL changed?
	get_win_url: function(domain)
	{
		if( app.CACHE.URL.domain !== domain ) {
			
			var old = app.CACHE.URL.domain;

			// update old urls
			$.each(app.CACHE.URL, function(k,v){
				app.CACHE.URL[k] = v.replace(old, domain);
			});

			// url
			app.cache_save('url');
		}
	},

	/**
	 * Use this.get_win logic
	 */
	get_msg_win: function(data, actionIndex, single)
	{
		this.get_win(data, actionIndex, single);
		
		if(app.URI[0] === 'home') {
			app.VIEW.home.checkMessages();
		}
	},

	/**
	 * Use this.get_win logic
	 */
	get_questions_win: function(data, actionIndex, single)
	{
		this.get_win(data, actionIndex, single);
	},

	/**
	 * Use this.get_win logic
	 */
	get_reports_other_win: function(data, actionIndex, single)
	{
		this.get_win(data, actionIndex, single);
	},

	/**
	 * Use this.get_win logic
	 */
	get_reports_win: function(data, actionIndex, single)
	{
		this.get_win(data, actionIndex, single);
	},

	/**
	 * Use this.get_win logic
	 */
	get_maintenance_questions_win: function(data, actionIndex, single)
	{
		this.get_win(data, actionIndex, single);
	},

	/**
	 * Use this.get_win logic
	 */
	get_maintenance_other_win: function(data, actionIndex, single)
	{
		this.get_win(data, actionIndex, single);
	},

	/**
	 * Use this.get_win logic
	 */
	get_maintenance_win: function(data, actionIndex, single)
	{
		// save maintenance question types
		if( data.mai_que_typ ) {
			app.cache_save('mai_que_typ', data.mai_que_typ);
		}

		this.get_win(data, actionIndex, single);
	},

	/**
	 * Check row is ready to be used for GET
	 */
	_check_get_row: function(action, tbl, r)
	{
		// REPORTS
		if( tbl === 'rep' && (
				( action === 'get_reports_other' && r.user_id === app.CACHE.USR.id ) ||
				( action === 'get_reports' && r.user_id !== app.CACHE.USR.id )
			)
		) {
			return false;
		}

		// MAINTENANCE
		if( tbl === 'mai' && (
				( action === 'get_maintenance_other' && r.user_id === app.CACHE.USR.id ) ||
				( action === 'get_maintenance' && r.user_id !== app.CACHE.USR.id )
			)
		) {
			return false;
		}

		// REPORT_ANSWERS
		if( tbl === 'rep_ans' && ( action === 'get_reports_other' || action === 'get_reports') ) {

			var user_id = app.cache_get_prop('rep', r.report_id, 'user_id');

			if(
				( action === 'get_reports_other' && user_id === app.CACHE.USR.id ) ||
				( action === 'get_reports' && user_id && user_id !== app.CACHE.USR.id )
			) {
				return false;
			}
		}

		// REPORT_ANSWERS
		if( tbl === 'mai_ans' && ( action === 'get_maintenance_other' || action === 'get_maintenance') ) {

			var user_id = app.cache_get_prop('mai', r.maintenance_id, 'user_id');

			if(
				( action === 'get_maintenance_other' && user_id === app.CACHE.USR.id ) ||
				( action === 'get_maintenance' && user_id && user_id !== app.CACHE.USR.id )
			) {
				return false;
			}
		}

		// check all rows have id or cannot compare in DB
		if( r.hasOwnProperty('id') ) {
			return true;
		}

		return false;
	},

	/**
	 * Get ids and date_updated for each row
	 */
	get_data: function(action)
	{
		var data = {},
			tbls = SYNC['TBLS_'+action.toUpperCase()];

		// LOOP TABLES
		$.each(tbls, function(keyTbl, tbl){

			// LOOP ROWS
			$.each( app.CACHE[ tbl.toUpperCase() ], function(keyRow, row){

				// check to see if rows need to be added to GET rows
				if( app.SYNC._check_get_row(action, tbl, row) ) {

					// create containers
					if( data.hasOwnProperty(tbl) === false ) {
						data[tbl] = [];
					}

					// add main data
					var r = { 'id': row.id };

					// has item been updated?
					if( row.hasOwnProperty('date_updated') ) {

						if( row.date_updated === app.BLANK_DATETIME ) {
							r.date_updated = '';
						 } else {
							r.date_updated = row.date_updated;
						}
					}

					// has item been deleted?
					if( row.hasOwnProperty('is_deleted') && row.is_deleted === '1' ) {
						r.is_deleted = '1';
					}

					data[tbl].push(r);
				}
			});
		});

		console.log(tbls, data);

		return data;
	},

	get_msg_data: function(action)
	{
		return this.get_data(action);
	},

	get_questions_data: function(action)
	{
		return this.get_data(action);
	},

	get_reports_other_data: function(action)
	{
		return this.get_data(action);
	},

	get_reports_data: function(action)
	{
		return this.get_data(action);
	},

	get_maintenance_questions_data: function(action)
	{
		return this.get_data(action);
	},

	get_maintenance_other_data: function(action)
	{
		return this.get_data(action);
	},

	get_maintenance_data: function(action)
	{
		return this.get_data(action);
	},

	/**
	 * Check to see if there are any photos that need uploading
	 */
	send_photos: function(data, actionIndex)
	{
		console.log('send_photos()', data);

		// do nothing if no photos
		if( app.PHONEGAP === false || data.length === 0 ) {
			this.msg_progress(false, false);
			return;
		}

		// globally accesible
		app.SYNC.PHOTOS = data;

		// select last photo
		var photo = app.SYNC.PHOTOS.pop();

		// start upload
		app.FILE.upload_pic(photo.tbl, 'edit', photo, true)
			.catch((err) => console.log(`Error trying to sync Images: ${err.message}`));
	},

	/**
	 * Get array of photos which need to be uploaded
	 */
	send_photos_data: function()
	{
		var photos = [];

		// LOOP THROUGH PROJECTS
		$.each(this.TBLS_SEND_PHOTOS, function(k, tbl){
			photos = photos.concat(app.SYNC.send_data_photos_tbl(tbl));
		});

		return photos;
	},

	/**
	 * PHOTOS:
	 *
	 * rep_ans + rep_def
	 */
	send_data_photos_tbl: function(tbl, id, value)
	{
		var result = [],
			fields = app.TPL._get_photo_fields(8),
			sync = ( id === undefined && value === undefined ) ? true : false;

		// LOOP THROUGH ROWS
		$.each(app.CACHE[tbl.toUpperCase()], function(k, row){

			if(
				( sync === false && row[id] === value) ||
				( sync && tbl === 'rep_ans' && app.cache_get_prop('rep', row.report_id, 'id') !== undefined) ||
				( sync && row.hasOwnProperty('id') )
			) {

				// LOOP THROUGH EACH POTENTIAL FILE FIELD
				$.each(fields, function(key, f){

					// does photo exist and needs uploading?
					if( row[f+'_local'] && row[f+'_needs_uploading'] ){

						// merge data
						var item = $.extend({}, row, { 'tbl': tbl, 'field': f} );

						// add to array
						result.push(item);
					}
				});
			}

		});

		return result;
	},

	/**
	 * Called from app.FILE.upload_pic_win()
	 */
	send_photos_win: function()
	{
		// paint sync complete status for sending photos
		app.SYNC.msg_progress(false, 'send_photos');
	},
	
	send_photos_fail: (err) => {
		// change status
		app.SYNC.change_status(false, 'send_photos');
		
		// update message
		app.SYNC.msg('error', 'Problem syncing, failed to upload photos (103)');
	}
};