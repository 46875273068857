export let mai_setup = {

	DOM: {},

	/**
	 * Check to see item exists
	 */
	get_redirect: function()
	{
		// check for redirect needed
		if( app.DATA.MAI_VEHICLE === false ) {
			return 'home';
		} else if( app.DATA.MAI_VEHICLE.hasOwnProperty('date_start') ) {
			return 'mai/'+app.URI[1];
		}

		return false;
	},

	init: function()
	{
		var redirect = this.get_redirect();

		if( redirect ) {
			app.redirect(redirect);
			return;
		}

		// DOM
		this.setup_dom();

		// EVENTS
		this.setup_events();
	},

	setup_dom: function()
	{
		// radios
		this.DOM.has_trailer_yes = app.DOM.content_load.find('#has_trailer_yes');

		// buttons
		this.DOM.btn_back = app.DOM.content_load.find('#btn-back');
		this.DOM.btn_next = app.DOM.content_load.find('.btn-next');
		this.DOM.btn_checklist = app.DOM.content_load.find('#checklist p');
		this.DOM.ajax_msg = app.DOM.content_load.find('.msg');

		// warning
		this.DOM.report_warning = app.DOM.content_load.find('#report-warning');
	},

	setup_events: function()
	{
		// check current vehicle state
		app.check_veh_is_suspended('mai', app.HASH, app.DATA.MAI_VEHICLE.vehicle_id);

		// BACK
		this.DOM.btn_back.on('click', this.evt_btn_back);

		// NEXT
		this.DOM.btn_next.on('click', this.evt_btn_next);

		// AJAX
		this.DOM.btn_checklist.on('click', this.evt_btn_checklist);
	},

	evt_btn_checklist:function()
	{
		// do nothing
		if( $(this).hasClass('empty') ) {
			return;
		}

		if( $(this).hasClass('bg-primary') ) {
			// unselect current checklist
			$(this).removeClass('bg-primary');
			// disable next
			app.VIEW[app.HASH].DOM.btn_next.removeClass('button-primary').addClass('button-grey');
			return;
		}

		app.VIEW[app.HASH].DOM.btn_checklist.removeClass('bg-primary');

		// select checklist
		$(this).addClass('bg-primary');

		// enable next
		app.VIEW[app.HASH].DOM.btn_next.addClass('button-primary').removeClass('button-grey');
	},

	evt_btn_back:function()
	{
		app.show_confirm("Are you sure you don't want to start Inspection on "+$(this).attr('data')+"?", "Cancel Inspection", function(r){
			if( r === true ) {

				// delete inspection
				app.FORM.save_offline('mai','delete', {'ts': app.URI[1]}, true);

				// redirect user
				app.redirect('mai_start');
			}
		});
	},

	evt_btn_next: function()
	{
		// do nothing
		if( $(this).hasClass('button-grey') ) {
			return;
		}

		// default redirect
		var redirect = 'mai/'+app.URI[1],
			start = true,
			vehicle_id = $(this).attr('data'),
			maintenance_checklist_id = app.VIEW[app.HASH].DOM.btn_checklist.filter('.bg-primary').attr('data');

		// inspection started?
		if( app.DATA.MAI_VEHICLE.hasOwnProperty('date_start') === false ) {

			// check if already been started?
			start = app.MAINTENANCE.start(vehicle_id, maintenance_checklist_id, app.URI[1]);
		}

		// DELETE MAINTENANCE
		if( start === false ){
			// delete report
			app.MAINTENANCE.delete(app.URI[1]);

			// update redirect
			redirect = 'mai_start';
		}

		// redirect user
		app.redirect(redirect);
	}
};