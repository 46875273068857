export let pin_reset = {

	DOM: {},

	init: function()
	{
		// start the form
		app.FORM.start({
			save_show: true,
			rules: {
				pin: { required: true, notEqualTo: app.CACHE.USR.pin },
			    pin_confirm: { equalTo: '#pin' }
			}
		});

		// setup dom
		this.setup_dom();
	},

	setup_dom: function()
	{
		this.DOM.pin_reset = this.DOM.form.find('#pin_reset');
	},

	form_online: function(json, post)
	{
		console.log('form_online');

		if( json.status === 'success' ) {
			this.DOM.form.find('fieldset').hide();
			this.DOM.form.find('#success').show();
			this.DOM.bottom_container.hide();
		}
	},

	form_online_error: function(json, post)
	{
		this.DOM.pin_reset.val('');
	}
};