export let timeout = {

	DOM: {},
	SETTINGS: {},

	init: function()
	{
        // start the form
        app.FORM.start({
            pin: true,
            save_show: true
        });

        this.setup_dom();
    },

    setup_dom: function()
    {
    },

    form_on_error: function(errors)
    {
        // reset password if credential errors
        $.each(errors, function(k,v){

            // remove existing pin
            if( this.key === 'credentials' ) {
                app.VIEW.login.DOM.pins.val('').focus();
            }
        });
    },

    form_offline: function(response, post)
    {
        if( post.pin === app.CACHE.USR.pin ) {
            
            app.TIMEOUT.reset();
            
        } else {

            // error message
            app.FORM.success_error({
                errors: [{
                    'key': 'pin',
                    'msg': 'The PIN you entered was incorrect'
                }]
            });

            // reset value
            app._('pins').val('');
        }
    }
};