import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { Capacitor } from "@capacitor/core";

export let QR = {
    HAS_PERMISSION: false,
    IS_SCANNING: false,

    /**
     * The function that should be run before attempting to run QR.startScan()
     * @returns {Promise<boolean>}
     */
    start: async () => {
        if(Capacitor.isPluginAvailable('BarcodeScanner')) {
            QR.HAS_PERMISSION = await QR.checkPermissions();
        }

        return QR.HAS_PERMISSION;
    },

    /**
     * Used to check the permissions of the Device to see if we can start to scan for a QR
     * @returns {Promise<boolean>}
     */
    checkPermissions: async () => {
        // check if user already granted permission
        const status = await BarcodeScanner.checkPermission({ force: false });

        if (status.granted) {
            // user granted permission
            return true;
        }

        if (status.denied) {
            await app.show_confirm(
                'If you want to grant permission for using your camera, enable it in the app settings. You then will be able to scan barcodes.',
                'Enable this app to use your Camera',
                async (response) => {
                    if(response === true) {
                        await BarcodeScanner.openAppSettings();
                    }
                },
                undefined,
                ['Goto Settings', 'Cancel']
            );

            // user denied permission
            return false;
        }

        if (status.restricted || status.unknown) {
            // ios only
            // probably means the permission has been denied
            return false;
        }

        // the force flag will request permission since the user has not provided permission yet
        const statusRequest = await BarcodeScanner.checkPermission({ force: true });

        if (statusRequest.asked) {
            const lastCheck = await BarcodeScanner.checkPermission({ force: false });

            if(lastCheck.granted) {
                return true;
            }
        }

        if (statusRequest.granted) {
            // the user did grant the permission now
            return true;
        }

        // user did not grant the permission, so he must have declined the request
        return false;
    },

    /**
     * Used to scan for the actual QR code resolves back with the data from the QR
     * @param {ScanOptions} opts the options to use when setting up the Barcode scanner plugin
     * @returns {Promise<string>} the Barcode/QR code data
     * @throws Will throw an Error if we don't get result data back from the scan or permissions for the camera were denied
     */
    startScan: async (opts) => {
        if(!QR.HAS_PERMISSION) {
            throw new Error(`This app requires permission to access the camera to do this action.`);
        }

        opts = opts ?? {};
        console.log(opts);
        await BarcodeScanner.prepare(opts);
        await BarcodeScanner.hideBackground();
        const qrBackBtn = $('#qr-back-btn');
        qrBackBtn.off('click');
        qrBackBtn.on('click', async () => {
            await QR.stopScan();
        });
        document.querySelector('body').classList.add('scanner-active');
        //$('#footer').hide();
        QR.IS_SCANNING = true;
        const result = await BarcodeScanner.startScan(opts);

        // Used to ensure the scan is stopped fully
        await QR.stopScan();

        if(result.hasContent) {
            return result.content;
        }

        throw new Error('We received no data back from the scanned QR Code/Barcode.');
    },

    /**
     * Used to stop scanning even if it isn't finished
     * @returns {Promise<void>}
     */
    stopScan: async () => {
        if(!QR.IS_SCANNING) {
            return;
        }

        await BarcodeScanner.showBackground();
        await BarcodeScanner.stopScan();
        document.querySelector('body').classList.remove('scanner-active');
        //$('#footer').show();
        QR.IS_SCANNING = false;
    }
}