export let acc_start = {

	TYPES: ['reg', 'serial'],

	DOM: {},

	init: function()
	{

		app.GEO.start(app.HASH);

		// reset reference
		for(let i = 0; i < this.TYPES.length; i++){
			this['awesomplete_'+this.TYPES[i]] = false;
		}

		// DOM
		this.setup_dom();

		// EVENTS
		this.setup_events();
	},

	setup_dom: function()
	{
		this.DOM.form  = app.DOM.content_load.find('#form');
		this.DOM.bottom_container = app.DOM.content_load.find('#bottom-container');

		// autocomplete
		this.DOM.autocomplete_input = app.DOM.content_load.find('.autocomplete-input');
		this.DOM.autocomplete = app.DOM.content_load.find('.autocomplete-list');

		// buttons
		this.DOM.btn_next = app.DOM.content_load.find('#btn-next');
	},

	setup_events: function()
	{
		// SETUP FOOTER
		app.setup_footer([
			{btn:'left', txt: 'Back', href: 'acc_list'}
		]);

		app.setup_autocomplete();

		// prevent form submission
		this.DOM.form.on('submit', function(e){
			e.preventDefault();
		});

		// clear focus
		this.DOM.autocomplete_input.on('blur', function(){
			$(this).val('');
		});

		this.setup_focus();
	},

	setup_focus: function()
	{
		this.DOM.autocomplete_input.on('focus', function(){
			app.VIEW[app.HASH].DOM.bottom_container.hide();
		});

		this.DOM.autocomplete_input.on('blur', function(){
			app.VIEW[app.HASH].DOM.bottom_container.show();
		});
	},

	selectAutocomplete: function(e)
	{
		if( app.HASH !== 'acc_start' ) {
			return;
		}
		
		let ts = false;
		var vehicle_id = ($.isNumeric(e)) ? e : $(e.srcElement).val();
		
		
		// reset input
		if( $.isNumeric(e) === false ) {
			$(e.srcElement).val('');
		}

		// get data for add
		var data = {
			'is_completed': '0',
			'app_version': app.VERSION,
			'is_resolved': '0',
			'vehicle_id': vehicle_id,
			'user_id': app.CACHE.USR.id,
			'is_webapp': ( app.WEBAPP ) ? '1' : '0',
			'photo_types': {
				'veh_pos': '0',
				'third_veh_reg': '0',
				'damage_to_veh': '0',
				'third_veh_load_dmg': '0',
				'damage_to_load': '0',
				'surrounding_area': '0',
				'damage_to_third_veh': '0',
				'other': '0',
			}
		};

		// check for any coordinates
		var geo = app.GEO.has_expired();
		if( geo ) {
			data.lat = geo.lat;
			data.lng = geo.lng;
			data.date_geo = geo.date_added;
		}

		// add acc
		ts = app.FORM.save_offline('acc', 'add', data, true, false, false, function () {
			
			// redirect
			if (ts) {
				app.FORM.save_offline('acc', 'edit', {parent_ts: ts}, true, ts, false);
				app.redirect('acc_form/' + ts);
			}
		});
	}
};