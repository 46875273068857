export let rep = {

	DOM: {},

	TOUCH: 'click',
	
	init: function()
	{
		rep.TOUCH = ( app.PHONEGAP && navigator.userAgent.match(/(iPod|iPad)/) ) ? 'touchstart' : 'click';
		// DOM
		this.setup_dom();

		// EVENTS
		this.setup_events();
	},

	setup_dom: function()
	{
		this.DOM.bottom_container = app.DOM.content_load.find('#bottom-container');
		this.DOM.btn_complete = app.DOM.content_load.find('#btn-complete');
		this.DOM.btn_submit = app.DOM.content_load.find('#submit');
		this.DOM.btn_delete = app.DOM.content_load.find('#btn-delete');
	},

	setup_events: function()
	{
		// COMPLETED
		if( this.DOM.btn_complete.length > 0 ) {
			this.evt_btn_complete();
		}

		if( this.DOM.btn_submit.length > 0 ) {

			// SUBMIT
			this.evt_submit_setup();
		}

		if( this.DOM.btn_delete.length > 0 ) {

			// lookup defects
			if( this.DOM.btn_submit.length === 0 ) {
				app.AJAX.veh_defects(app.DATA.REP.vehicle_id);
			}

			// DELETE
			this.evt_btn_delete();

		} else {
			this.check_rep_ans();
		}

		// left button
		var opts = [
			{ btn: 'left', 'href':  ( app.DATA.REP.user_id === app.CACHE.USR.id ) ? 'rep_list' : 'rep_list/other', txt: 'Back' }
		];

		// right button
		if( app.DATA.PROGRESS.completed || app.DATA.PROGRESS.submit ) {
			opts.push({
				'btn': 'right',
				'txt': ( app.DATA.PROGRESS.completed ) ? 'Complete' : 'Submit',
				'click': ( app.DATA.PROGRESS.completed ) ? 'btn_complete' : 'btn_submit'
			});
		}

		app.setup_footer(opts);

		app.UPCOMING.view_cached();
	},

	/**
	 * Do we need to download report answers?
	 */
	check_rep_ans: function()
	{
		// do nothing, dont look up own answers
		if( !app.DATA.REP_ANS_AJAX ) {
			return;
		}

		var ajax = $.ajax({
		    url: app.get_api_url('rep_ans_other/'+app.URI[1]),
		    data: app.get_api_data(),
		    method: 'POST'
		});

		ajax.done(function(r){
			if( r && r.status === 'success' && r.data.rep_ans && r.data.rep_ans.length > 0 ) {
				app.VIEW.rep.check_rep_ans_win(r.data.rep_ans);
			}
		});
	},

	check_rep_ans_win: function(json)
	{
		$.each(json, function(k,v){
			app.CACHE.REP_ANS.push(v);
		});

		app.cache_save('rep_ans');

		// reload page
		if( app.HASH === 'rep' ) {
			app.redirect();
		}
	},

	evt_btn_complete: function()
	{
		this.DOM.btn_complete.on('click', function(){

			// SAVE: date_end + refresh
			app.show_confirm("You will not be able to change any answers for this report after marking as completed", "Please remember", function(response){

				if( response === true ) {
					var data = {'date_end': app.DATE.format('datetime')};

					app.FORM.save_offline('rep', 'edit', data, true, app.URI[1], false, function(){
						// refresh page on callback
						app.redirect();
					});
				}
			});
		});
	},

	evt_btn_delete: function()
	{
		this.DOM.btn_delete.on('click', function(){

			// SAVE: date_end + refresh
			app.show_confirm("Do you want to delete this report?", "Delete Report", function(response){
				
				if( response === true ) {
					app.show_confirm("Please confirm you wish to delete this report as all data relating to this report will be lost?", "Confirm Deletion", function(r){
						if( r === true ) {

							app.VIEW[app.HASH].DOM.btn_delete.hide().after('<img class="loading" src="img/loading.svg">');

							// delete current report
							app.REPORT.delete(app.URI[1], app.DATA.REP);
							
							// redirect to report list
							setTimeout(function(){
								app.redirect('rep_list');
							}, 600 ); 
						}
					}, {}, ['Confirm', 'Cancel']);
				}
			}, {}, ['Confirm', 'Cancel']);
		});
	},

	evt_submit_setup: function()
	{
		var data = $.extend({'rep_ans': []}, app.DATA.REP);

		app.CACHE.REP_ANS.filter(function(r){
			if( r.report_id === app.URI[1] ) {
				data.rep_ans.push($.extend({}, r));
		    }
		});

		app.FORM.start({'changes': false, 'data': data});
	},

	form_online: function(json, post)
	{
		if( json.data && json.data.rep ){

			var data = {'id': json.data.rep.id};

			// attach related id
			if( post.report_id_related ) {
				data.report_id_related = post.report_id_related;
			}			 

			// update report with id
			app.FORM.save('rep', 'edit', data, post, true, app.URI[1], false, false, false, false, function(){


				// LOOP through each answer and add id
				$.each(json.data.rep_ans, function(k,v){

					if( v.ts && v.id ) {	
						var i = app.cache_get_index('rep_ans', v.ts);

						if( i >= 0 && app.CACHE.REP_ANS[i] ) {
							delete app.CACHE.REP_ANS[i].sync;
							delete app.CACHE.REP_ANS[i].ts;
							app.CACHE.REP_ANS[i].id = v.id.toString();
						}
					}
				});

				// redirect on save callback
				app.cache_save('rep_ans', false, false, function(){
					app.redirect('rep_success/'+json.data.rep.id);
				});
			});
		}
	},

	form_offline: function()
	{
		// redirect on save callback
		app.redirect('rep_success/'+app.URI[1]);
	}
};