import { Network } from "@capacitor/network";

export class NetworkCore {
    /**
     * A true or false variable for checking if we have any form of internet connection
     */
    STATUS = false;

    /**
     * This variable is to be used when checking what type of connection the device has this can be any of the following values: wifi, cellular, none, unknown
     */
    CONNECTION_TYPE = 'unknown';

    /**
     * This should be called at the initialization phase of the app as it is what starts this files network information
     * @return {Promise<void>}
     */
    async start() {
        await this.refreshConnStats();
        await Network.removeAllListeners();
        Network.addListener('networkStatusChange', (status) => {
            this.STATUS = status.connected;
            this.CONNECTION_TYPE = status.connectionType;
        });
    }

    /**
     * Use this if you want to hard force a network connection state re-check
     * @return {Promise<void>}
     */
    async refreshConnStats() {
        const status = await Network.getStatus();
        this.STATUS = status.connected;
        this.CONNECTION_TYPE = status.connectionType;
    }

//     async makeRequest(route, method = 'POST', data, params, fullResponse = false) {
//         let opts = {
//             url: app.get_api_url(route),
//             headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//             method: method,
//             connectTimeout: app.TIMEOUT_AJAX,
//         };
//         if(data) opts.data = data;
//         if( params ) {
//             let pers = {};
//             for(const key in params) {
//                 pers[key] = params[key].toString();
//             }
//             opts.params = pers;
//         }
// console.log(opts)
//         try {
//             const res = await CapacitorHttp.request(opts);
//             console.log('\n %cNetwork Request:', 'color: #559F48;', `'${route}'\n`, res, '\n\n',);
//
//             return !fullResponse ? res.data : res;
//         } catch (err) {
//             console.log('\n %cNetwork Request:', 'color: #559F48;', `'${route}' (ERRORED)\n`, err, '\n\n',);
//             throw err;
//         }
//     }
}