export let TABLES = {
    // user
    'usr': { 'i': 'User', 'tblParentProp': false },
    'res': { 'i': 'Reseller', 'tblParentProp': false},
    // vehicles
    'acc': { 'i': 'Accident / Incident' , 'ii': 'Accidents / Incidents',  'tblParentProp': 'vehicle_id', 'localforage': true },
    'veh': { 'i': 'Asset' , 'tblParentProp': false, 'localforage': true },
    'veh_typ': { 'i': 'Asset' , 'tblParentProp': false },
    'veh_def': { 'i': 'Asset Defect' , 'tblParentProp': 'vehicle_id', 'localforage': true },
    // messages
    'msg_usr': {'i': 'Message', 'ii': 'Messages', 'localforage': true},
    'msg_hom': {'i': 'Message Home', 'ii': 'Messages Home', 'localforage': true},
    // reports
    'rep': { 'i': 'Daily Check', 'tblParentProp': false, 'localforage': true, 'tblChild': 'rep_ans' },
    'rep_che': { 'i': 'Checklist', 'tblParentProp': 'report_id'},
    'rep_ans': { 'i': 'Answer', 'tblParentProp': 'report_id', 'localforage': true },
    'rep_que': { 'i': 'Question', 'tblParentProp': 'report_checklist_id', 'localforage': true, 'keep_deleted': true},
    // maintenance
    'mai': { 'i': 'Inspection', 'tblParentProp': false, 'localforage': true, 'tblChild': 'mai_ans' },
    'mai_che': { 'i': 'Checklist', 'tblParentProp': 'maintenance_id'},
    'mai_ans': { 'i': 'Answer', 'tblParentProp': 'maintenance_id', 'localforage': true },
    'mai_que': { 'i': 'Question', 'tblParentProp': 'maintenance_checklist_id', 'localforage': true, 'keep_deleted': true},
    // vehicle fuel
    'fue_exp': { 'i': 'Fuel / AdBlue', 'tblParentProp': false, 'localforage': true },
    'cos_typ': { 'i': 'Cost Type', 'tblParentProp': false, 'localforage': true },
    // tacho
    'tco_drv_let': { 'i': 'Infringement Letter', 'ii': 'Infringement Letters', 'tblParentProp': false, 'localforage': true },
};