export let veh_form = {

	DOM: {},

	init: function()
	{
		this.TBL = ( app.URI[1] === 'mai' ) ? 'mai' : 'rep';

		app.FORM.start();

		// dom
		this.setup_dom();

		// events
		this.setup_events();
	},

	setup_dom: function()
	{
		this.DOM.radios = this.DOM.form.find('p.radios input');
		this.DOM.radio_reg = this.DOM.radios.filter('#id_name_reg');
		this.DOM.reg_container = this.DOM.form.find('#reg-container');
		this.DOM.reg_container_input = this.DOM.reg_container.find('input');
		this.DOM.serial_container = this.DOM.form.find('#serial-container');
		this.DOM.serial_container_input = this.DOM.serial_container.find('input');
	},

	setup_events: function()
	{
		// radios
		this.DOM.radios.change(function(){
			if( app.VIEW[app.HASH].DOM.radio_reg.is(':checked') ){
				// reg: show
				app.VIEW[app.HASH].DOM.reg_container.show();
				app.VIEW[app.HASH].DOM.reg_container_input.addClass('required');
				// serial: hide
				app.VIEW[app.HASH].DOM.serial_container.hide();
				app.VIEW[app.HASH].DOM.serial_container_input.removeClass('required');
			} else {
				// reg: hide
				app.VIEW[app.HASH].DOM.reg_container.hide();
				app.VIEW[app.HASH].DOM.reg_container_input.removeClass('required');
				// serial: show
				app.VIEW[app.HASH].DOM.serial_container.show();
				app.VIEW[app.HASH].DOM.serial_container_input.addClass('required');
			}
		});

		this.DOM.radios.eq(0).trigger('change');

		// preselect trailer cat
		if( app.URI[1] === 'trailer' ) {
			app.VIEW[app.HASH].DOM.form.find('#vehicle_type_id option[value=4]').prop('selected', true);
		}

		// SETUP FOOTER
		var opts = [{ btn: 'left', txt: 'Back', href: app.VIEW.veh_form.TBL+'_start'}];

		if( app.URI[2] !== 'success' ) {
			opts.push({
				btn: 'right', txt: 'Submit', click: 'form_btn_submit'
			});
		}

		app.setup_footer(opts);
	},

	form_online: function(json, post)
	{
		// add item
		if( json.data && json.data.veh ) {
			app.FORM.save('veh', 'add', json.data.veh, post, true, false, false, false, false, false, function(){
				
				var ts = ( app.VIEW.veh_form.TBL === 'mai' ) ? app.MAINTENANCE.add(json.data.veh.id) : app.REPORT.add(json.data.veh.id);

				// redirect
				app.redirect(app.VIEW.veh_form.TBL+'_setup/' + ts);
			});
		}
	}
};