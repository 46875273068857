export let mai_list_other = {

	DOM: {},

	SCROLL: false,

	init: function()
	{
		if( !app.CACHE.USR.priv_mai_other || app.CACHE.USR.priv_mai_other === '0' ) {
			app.redirect('home');
			return;
		}

		// DOM
		this.setup_dom();
		
		// SETUP FOOTER
		app.setup_footer([
			{btn:'left', txt: 'Back', href: 'home'},
			{btn:'right', txt: 'Add', href: 'mai_start'}
		]);

		this.ajax();
	},

	setup_dom: function()
	{
		this.DOM.table = app.DOM.content.find('table').addClass('paging');
	},

	/**
	 * Get daily checks from api
	 */
	ajax: function(pageSelected)
	{
		let pageIsSet = ( pageSelected ) ? true : false;
		
		// set defaults
		pageSelected = pageSelected || 1;

        var ajax = $.ajax({
            url: app.get_api_url('mai_other'),
            data: app.get_api_data({page: pageSelected}),
            method: 'POST'
        });

		// change loading state
		_('table').addClass('loading');

        ajax.done(function(r){

            // success
            if( r && r.status === 'success' && app.HASH === 'mai_list_other' && r.data.mai ) {

				app.AJAX.paging('mai_list_other', r.data.paging, pageSelected, pageIsSet);
				
                app.VIEW.mai_list_other.ajax_success(r.data.mai);
            } else {
            	app.AJAX.paging('mai_list_other', false, 1);
            }
        });

        ajax.fail(function(){
        	app.AJAX.paging('mai_list_other', false, 1);
        });
	},
	
	ajax_success: function(inspections)
	{
		let html = '';
		
		$.each(inspections, (k,v) => {
			html += `<tr data="${k}">
			<td>${Handlebars.helpers.regSerial(v, true, 'mai/'+v.id)}</td>
			<td>${v?.maintenance_checklist_name}</td>
			<td>${Handlebars.helpers.date(v.date_end, 'datetime')}</td>
			</tr>`;
		});

		_('table').find('tr.paging').last().after(html);

		_('table').find('[href]').removeAttr('href').on('click', function(){
			var index = $(this).closest('tr').attr('data'),
			r = inspections[index];
			app.CACHE.MAI.push(r);
			app.cache_save('mai', false, false, function(){
				app.redirect('mai/'+r.id);
			});
		});
	}
};