export let rep_list = {

	DOM: {},

	SCROLL: false,

	init: function()
	{
		// DOM
		this.setup_dom();

		// FILTER CONTACTS
		app.FORM.start_filter();
		
		// SETUP FOOTER
		app.setup_footer([
			{btn:'left', txt: 'Back', href: 'home'},
			{btn:'right', txt: 'Add', href: 'rep_start'}
		]);
	},

	setup_dom: function()
	{
		this.DOM.bottom_container = app.DOM.content_load.find('#bottom-container');

		// text
		this.DOM.filter_table = app.DOM.content_load.find('#filter-table');
		this.DOM.filter_table_tr_all = this.DOM.filter_table.find('tbody tr');
		this.DOM.filter_table_th = this.DOM.filter_table_tr_all.filter('.th');
		this.DOM.filter_table_tr = this.DOM.filter_table_tr_all.not('.th');
	},

	/**
	 * Show/Hide day headers
	 */
	onKeyup: function()
	{
		$.each(this.DOM.filter_table_th, function(){
			var css = '.row-' + $(this).attr('id'),
				$visible = app.VIEW[app.HASH].DOM.filter_table_tr.filter(css+':visible');

			if( $visible.length === 0 ) {
				// hide day header
				$(this).hide();
			} else {
				// show day header
				$(this).show();
			}
		});
	}
};