export class VehicleDefectsList {
	DOM = {};
	DEFECTS = [];
	
	init() {
		if(app.CACHE.USR.opr_priv_app_vehicle_defects === '0' || app.CACHE.USR.priv_app_vehicle_defects === '0') {
			app.redirect('home');
			return;
		}
		
		this.setupDOM();
		
		app.setup_footer([{ btn:'left', txt: 'Back', href: 'home' }]);
		this.ajax();
	}
	
	setupDOM() {
		this.DOM.table = app.DOM.content.find('table').addClass('paging');
		this.DOM.report_type_filter = app.DOM.content.find('#filter-defect-type-wrapper');
		this.DOM.type_filter = this.DOM.report_type_filter.find('select');
		this.DOM.report_status_filter = app.DOM.content.find('#filter-defect-status-wrapper');
		this.DOM.status_filter = this.DOM.report_status_filter.find('select');
	}
	
	/**
	 * Get vehicle defects from api
	 */
	ajax(pageSelected) {
		let pageIsSet = !!(pageSelected);
		
		// set defaults
		pageSelected = pageSelected || 1;
		
		var ajax = $.ajax({
			url: app.get_api_url('defects_list'),
			data: app.get_api_data({page: pageSelected, vehicle_id: app.URI[1]}),
			method: 'POST'
		});
		
		// change loading state
		_('table').addClass('loading');
		
		ajax.done(function(r){
			
			// success
			if( r && r.status === 'success' && app.HASH === 'live_defects_list' && r.data.defects ) {
				
				app.AJAX.paging('live_defects_list', r.data.paging, pageSelected, pageIsSet);
				
				app.VIEW.live_defects_list.DEFECTS = r.data.defects;
				app.VIEW.live_defects_list.ajax_success(r.data.defects);
				
				// Show filter
				app.VIEW.live_defects_list.DOM.report_type_filter.show();
				app.VIEW.live_defects_list.DOM.type_filter.on('change', () => {
					app.VIEW.live_defects_list.filter_list();
				});
				
				app.VIEW.live_defects_list.DOM.report_status_filter.show();
				app.VIEW.live_defects_list.DOM.status_filter.on('change', () => {
					app.VIEW.live_defects_list.filter_list();
				});
				
				app.VIEW.live_defects_list.filter_list();
				return;
			}
			
			if(r.status === 'error' && r.errors && r.errors.length > 0) {
				r.errors.forEach((err) => {
					if(err.key !== 'priv_app_vehicle_defects') {
						return;
					}
					
					if(r.data && r.data.priv_app_vehicle_defects) {
						app.CACHE.USR.priv_app_vehicle_defects = r.data.priv_app_vehicle_defects;
					}
					
					if(r.data && r.data.opr_priv_app_vehicle_defects) {
						app.CACHE.USR.opr_priv_app_vehicle_defects = r.data.opr_priv_app_vehicle_defects;
					}
					
					app.cache_save('usr');
				});
			}
			app.AJAX.paging('live_defects_list', false, 1);
		});
		
		ajax.fail(function(){
			app.AJAX.paging('live_defects_list', false, 1);
		});
	}
	
	filter_list() {
		const filter = this.DOM.type_filter.val();
		let filtered = this.DEFECTS.filter((defect) => {
			if(filter === 'all') {
				return true;
			}
			
			if(filter === 'mai_ans' && defect.tbl === 'mai_ans') {
				return true;
			}
			
			if(filter === 'rep_ans'&& defect.tbl === 'rep_ans') {
				return true;
			}
			
			if(filter === 'standalone_defect' && defect.standalone_defect === true) {
				return true;
			}
			
			return filter === 'in_service_defect' && defect.standalone_defect === false;
		});
		const filterStatus = this.DOM.status_filter.val();
		filtered = filtered.filter((defect) => {
			if(filterStatus === 'all') {
				return true;
			}
			
			if(defect.status.toLowerCase() === filterStatus.toLowerCase()) {
				return true;
			}
			return false;
			
			if(filterStatus === 'unresolved' && defect.is_resolved === '0' && defect.is_pending === '0' && defect.is_monitoring === '0') {
				return true;
			}
			
			if(filterStatus === 'pending' && defect.is_resolved === '0' && defect.is_pending === '1' && defect.is_monitoring === '0') {
				return true;
			}
			
			if(filterStatus === 'monitoring' && defect.is_resolved === '0' && defect.is_pending === '0' && defect.is_monitoring === '1') {
				return true;
			}
			
			return defect.is_resolved === '1' && defect.is_pending === '0' && defect.is_monitoring === '0';
		});
		_('table').find('tbody').html(Handlebars.helpers.pagingTableRows(3, 'There hasn\'t been any defects for this vehicle yet'));
		this.ajax_success(filtered);
	}
	
	ajax_success(defects) {
		let html = '';
		
		$.each(defects, (k,v) => {
			const status = Handlebars.helpers.defectStatusIcon(v);
			html += `<tr class="row" data-defect-id="${v.id}">
			<td>${!status || status.length === 0 ? '-' : status}</td>
			<td>${v?.type ?? '-'}</td>
			<td>${v.date_added ? Handlebars.helpers.date(v.date_added, 'ddd Do, MMM YYYY') : '-'}</td>
			</tr>`;
		});
		
		_('table').find('tr.paging').last().after(html);
		
		this.DOM.table.find('.row').unbind('click').on('click', (e) => {
			const defect = app.VIEW.live_defects_list.DEFECTS.filter((d) => d.id === $(e.currentTarget).attr('data-defect-id'));
			if(!defect || defect.length === 0) {
				return;
			}
			app.CACHE.VEHICLE_DEFECT = defect[0];
			app.cache_save('vehicle_defect');
			
			if(app.CACHE.USR.priv_app_vehicle_defects === '2') {
				app.redirect(`defect_edit/${app.URI[1]}`);
			} else {
				app.redirect(`defect_view/${app.URI[1]}`);
			}
		});
	}
}