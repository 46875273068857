import * as localforage from 'localforage';

export let logout = {

	init: function()
	{
		// 1. RESET HISTORY
		app.HISTORY = [];
		app.STEPS = [];

		// CLEAR CACHE
		this.clearCache()
			.catch(() => console.warn('An unexpected error occurred when clearing cache.'));
	},

	clearCache: async () => {
		// remove folder + files
		await app.FILE.remove();

		// update dom
		$('body').removeClass('user-demo');
		app.DOM.branding.html('');
		
		setTimeout(async () => {

			// clear storages
			localStorage.clear();
			localforage.clear();

			// remember any changes in url
			app.cache_save('url');

			// reset
			await app.setup_cache_asynchronous();

			// reset
			delete app.CACHE.USR.settings.secure;

			// reset branding
			app.setup_branding();

		}, 500 );

		// REDIRECT
		setTimeout(function(){
			app.redirect('login');
		}, 1000 );
	}
};