import * as Handlebars from 'handlebars';

export let msg = {

	DOM: {},

	init: function()
	{
		// DOM
		this.setup_dom();

		// EVENTS
		this.setup_events();
	},

	setup_dom: function()
	{
		this.DOM.ajax_get = app.DOM.content_load.find('#ajax-get');
		this.DOM.msg_container = app.DOM.content_load.find('#msg-container');
		this.DOM.btn_accept = app.DOM.content_load.find('#btn-accept');

		// text to update when button pressed
		if( this.DOM.btn_accept.length > 0 ) {
			this.DOM.accept = app.DOM.content_load.find('#accept');
		}
	},

	setup_events: function()
	{
		// msg data
		var result = ( app.URI[2] === 'push' ) ? app.TPL.get_row('msg_usr', app.URI[1], 'message_id') : app.TPL.get_row('msg_usr', app.URI[1]);

		// redirect: do nothing
		if( !result ){
			this.ajax_get();
			return;
		} else if( app.URI[2] === 'push' ) {
			app.redirect('msg/'+result.id);
			return;
		}

		// BROWSER URLS
		app.browser_open();

		// mark as read
		if( !result.date_read ) {
			app.FORM.save_offline('msg_usr', 'edit', {'date_read': app.DATE.format('datetime') }, true, app.URI[1], false, function(){
				app.VIEW.msg.ajax_edit();
			});
		} else if( result.sync === false ) {

			// attempt sync anyhow
			app.VIEW.msg.ajax_edit();
		}

		this.DOM.btn_accept.on('click', (e) => this.btnAcceptClick(e));
	},

	btnAcceptClick: function(e)
	{
		// do nothing
		if( $(e.currentTarget).hasClass('button-green')){
			return;
		}

		var date = app.DATE.format('datetime');

		// change button state
		$(e.currentTarget).addClass('button-green').removeClass('button-orange').html('<i class="far fa-check-circle"></i> Acknowledged');

		// update date in dom
		app._('accept').text(Handlebars.helpers.date(date, 'datetime'));

		// save accept date
		app.FORM.save_offline('msg_usr', 'edit', {'date_accept': date }, true, app.URI[1], false, function(){
			app.VIEW.msg.ajax_edit();
		});
	},

	/**
	 * Ajax request to edit msg 
	 *
	 * Read or Accept
	 */
	ajax_edit: function()
	{
		var data = app.TPL.get_row('msg_usr', app.URI[1]);

        var ajax = $.ajax({
            url: app.get_api_url('msg_usr/edit/'+app.URI[1]),
            data: app.get_api_data(data),
            method: 'POST'
        });

        ajax.done(function(r){
			if( r && r.status && r.status === 'success' ) {

				// save: w/ sync to delete sync flag
				app.FORM.save('msg_usr', 'edit', r.data.msg_usr, data, true, app.URI[1], false, 'force', false, false, function(){

					// all done sir check messages
					app.check_header_msg_count();
				});
			}
        });
	},

	/**
	 * Download message
	 *
	 * Should only be called from push notification tap
	 */
	ajax_get: function()
	{
		// setup dom
		this.DOM.ajax_get.show();
		this.DOM.msg_container.hide();

		// load ajax
        var ajax = $.ajax({
            url: app.get_api_url('msg_usr/get/'+app.URI[1]),
            data: app.get_api_data(),
            method: 'POST'
        });

        ajax.done(function(r){

        	if( r.status === 'success' ) {
        		app.VIEW.msg.ajax_get_win(r.data.msg_usr);
        	} else if( r.status === 'error' ) {
        		app.VIEW.msg.ajax_get_fail(r.errors);
        	} else {
        		app.VIEW.msg.ajax_get_fail(['No internet connection']);
			}
        });

        ajax.fail(function(r){
			app.VIEW.msg.ajax_get_fail(['No internet connection']);
        });
	},

	ajax_get_fail: function(err)
	{
		var msg = '';

		$.each(err, function(k,v){
			msg += "\n"+v.msg;
		});

		app.show_alert(msg, 'There was a problem');
	},

	ajax_get_win: function(msg)
	{
		// save record
		app.FORM.save('msg_usr', 'add', msg, false, true);

		// reload page
		app.redirect('msg/'+msg.id);
	}
};